import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../contexts/AuthContext';
import { PrintButton } from '../../consultations/PatientOverview/Documents/components/PrintButton';
import { useFields } from '../../consultations/PatientOverview/Documents/fields';

/**
 * Renders the action icons for a patient.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.patient - The patient object.
 * @returns {JSX.Element} The JSX element representing the action icons.
 */
export const ActionsIcons = ({ patient }) => {
  const { user } = useAuthContext();
  const { printFields, documentType, documentWording } = useFields();

  return (
    <>
      {(user?.position === 'DOCTOR' || user?.position === 'NURSE') && (
        <Link to={`/consultations/show/${patient?._id}`}>
          <Button type="link" icon={<EyeOutlined style={{ fontSize: 20 }} />} />
        </Link>
      )}
      <Link to={`/patients/edit/${patient?._id}?createConsultation=false`}>
        <Button type="link" icon={<EditOutlined style={{ fontSize: 20 }} />} />
      </Link>
      <PrintButton
        fields={printFields}
        documentType={documentType}
        documentWording={documentWording}
        patientId={patient?._id}
      />
    </>
  );
};

ActionsIcons.propTypes = {
  patient: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired
};
