/* eslint-disable import/no-extraneous-dependencies */
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { usePrescriptionContext } from '../../contexts/PrescriptionContext/PrescriptionContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { getSynapseToken } from '../../utils/synapse';

/**
 * This component renders the Synapse Grounds widget.
 * @component SynapseGroundsWidget
 * @returns {JSX.Element} Synapse Grounds widget
 */
export const SynapseGroundsWidget = () => {
  const { patientInfos, entities } = usePrescriptionContext();
  const [JWT, setJWT] = useState(null);
  const [groundsWidget, setGroundsWidget] = useState(null);

  const { dispatchAPI } = useAuthContext();

  const fetchNewToken = async () => {
    const token = await getSynapseToken(dispatchAPI);
    setJWT(token);
  };

  const createDataGrounds = () => ({
    entities,
    profile: {
      ...patientInfos?.infos,
      age: dayjs().diff(patientInfos?.infos?.birth_date, 'year')
    },
    filter: { Procreation: true }
  });

  // eslint-disable-next-line no-unused-vars
  const onTokenExpiration = async (err, data) => {
    const newToken = await fetchNewToken();
    groundsWidget.updateToken(newToken);
  };

  useEffect(() => {
    if (!groundsWidget) return;

    const dataGrounds = createDataGrounds();
    groundsWidget.update(dataGrounds);
  }, [entities, patientInfos, groundsWidget]);

  useEffect(() => {
    if (!patientInfos) return;

    if (!JWT) {
      fetchNewToken();
      return;
    }

    const dataGrounds = createDataGrounds();
    const newGroundsWidget = new window.Synapse.GroundsWidget(JWT);

    newGroundsWidget.init(
      document.getElementById('grounds-container'),
      dataGrounds
    );

    newGroundsWidget.setCallback('onTokenExpiration', onTokenExpiration);

    setGroundsWidget(newGroundsWidget);
  }, [JWT]);

  return <Card id="grounds-container" />;
};
