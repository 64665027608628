import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Avatar, Button, message } from 'antd';
import {
  GlobalOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
  HeartOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import HeaderDropdown from '../../HeaderDropdown/HeaderDropdown';
import useLanguageContext from '../../../contexts/LanguageContext';

const StyledContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledDropdownContainer = styled.span`
  height: 100%;
  cursor: pointer;
  padding: 0 12px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  :hover {
    background: var(--itemHoverBackground);
  }
  .ant-btn-link {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  > .anticon {
    color: var(--primaryColor);
  }
`;

const StyledFullNameSpan = styled.span`
  color: var(--primaryColor);
  font-weight: 600;
`;

const StyledRoleP = styled.span`
  font-size: 10px;
  color: var(--primaryColor);
`;

const HeaderLayout = () => {
  const navigate = useNavigate();
  const { user, token, dispatchAPI } = useAuthContext();
  const { dispatch: dispatchLocale } = useLanguageContext();
  const { t, i18n } = useTranslation();

  const languages = {
    en_GB: {
      label: 'English',
      icon: '🇬🇧'
    },
    fr_FR: {
      label: 'Français',
      icon: '🇫🇷'
    },
    es_ES: {
      label: 'Español',
      icon: '🇪🇸'
    }
  };

  const onProfileMenuClick = ({ key }) => {
    if (key === 'logout') {
      dispatchAPI('LOGOUT');
      message.success(t('login.logoutSuccess'));
    }
  };

  const onLanguageMenuClick = ({ key }) => {
    dispatchLocale({ type: 'SET_LOCALE', locale: key });
  };

  const localizationMenu = {
    onClick: onLanguageMenuClick,
    items: Object.keys(languages).map((locale) =>
      i18n.language === locale
        ? {
            key: locale,
            label: (
              <>
                <span>{languages[locale].icon}</span>
                {` ${languages[locale].label}`}
              </>
            )
          }
        : {
            key: locale,
            label: (
              <>
                <span>{languages[locale].icon}</span>
                {` ${languages[locale].label}`}
              </>
            )
          }
    )
  };

  const profileMenu = {
    onClick: onProfileMenuClick,
    items: [
      ...(window.innerWidth <= 992 && user
        ? [
            {
              key: 'user',
              label: (
                <Button
                  style={{
                    width: '100%',
                    height: 50
                  }}
                  type="link"
                >
                  <StyledFullNameSpan>
                    {`${user.first_name} ${user.last_name}`}
                  </StyledFullNameSpan>
                  <StyledRoleP>
                    {t(`users.tags.${user.role.split(':')[1]}`)}
                  </StyledRoleP>
                </Button>
              )
            }
          ]
        : []),
      {
        key: 'profile',
        onClick: () => navigate('/profile'),
        label: (
          <>
            <UserOutlined
              style={{
                fontSize: 16
              }}
            />
            {` ${t('header.menu.user.profile')}`}
          </>
        )
      },
      ...(user?.position === 'DOCTOR'
        ? [
            {
              key: 'favorites',
              onClick: () => navigate('/favorites'),
              label: (
                <>
                  <HeartOutlined
                    style={{
                      fontSize: 16
                    }}
                  />
                  {` ${t('header.menu.user.favorites')}`}
                </>
              )
            }
          ]
        : []),
      {
        key: 'settings',
        onClick: () => navigate('/settings'),
        label: (
          <>
            <SettingOutlined
              style={{
                fontSize: 16
              }}
            />
            {` ${t('header.menu.user.settings')}`}
          </>
        )
      },
      {
        key: 'logout',
        label: (
          <>
            <LogoutOutlined
              style={{
                fontSize: 16
              }}
            />
            {` ${t('header.menu.user.logout')}`}
          </>
        )
      }
    ]
  };

  return (
    <StyledContainer>
      <HeaderDropdown overlayMenu={profileMenu}>
        {window.innerWidth <= 992 ? (
          <StyledDropdownContainer>
            <UserOutlined />
          </StyledDropdownContainer>
        ) : (
          <StyledDropdownContainer>
            <Avatar
              size="medium"
              icon={user?.photo ? '' : <UserOutlined />}
              src={
                user?.avatar
                  ? `${process.env.REACT_APP_API_URL}/users/get-url/${user?.avatar}/${token}`
                  : ''
              }
            >
              {`${user?.first_name[0]}${user?.last_name[0]}`}
            </Avatar>
            <Button type="link">
              <StyledFullNameSpan>
                {`${user?.first_name} ${user?.last_name}`}
              </StyledFullNameSpan>
              <StyledRoleP>
                {t(`users.tags.${user?.role.split(':')[1]}`)}
              </StyledRoleP>
            </Button>
          </StyledDropdownContainer>
        )}
      </HeaderDropdown>
      <HeaderDropdown overlayMenu={localizationMenu}>
        <StyledDropdownContainer>
          <GlobalOutlined
            style={{
              fontSize: 16
            }}
          />
        </StyledDropdownContainer>
      </HeaderDropdown>
    </StyledContainer>
  );
};

export default HeaderLayout;
