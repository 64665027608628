import PropTypes from 'prop-types';
import { useState } from 'react';
import { Upload, Form, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Item } = Form;

export const CardUpload = ({ dbKey, antdKey }) => {
  const itemName = antdKey !== '' ? [`${antdKey}`, `${dbKey}`] : `${dbKey}`;
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([]);

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  const UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },

    beforeUpload: async (file) => {
      const fileExtension = file.name.split('.').pop();
      if (fileExtension.toLowerCase() === 'png') {
        const base = await fileToBase64(file);

        setFileList([
          ...fileList,
          {
            type: dbKey.toUpperCase(),
            name: file.name,
            filename: file.name,
            base
          }
        ]);
        return false;
      }
      message.warning(t('not_a_png_file'));
      return true;
    },
    fileList
  };

  return (
    <Item name={itemName}>
      <Upload {...UploadProps}>
        <Button
          type="primary"
          style={{
            borderRadius: '15px'
          }}
          icon={<UploadOutlined />}
        >
          {t(`buttons.save_photo`)}
        </Button>
      </Upload>
    </Item>
  );
};
CardUpload.propTypes = {
  dbKey: PropTypes.string.isRequired,
  antdKey: PropTypes.string
};
CardUpload.defaultProps = {
  antdKey: ''
};
