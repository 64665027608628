import PropTypes from 'prop-types';
import { Table, Divider } from 'antd';

export const TableSurvey = ({ data, enums, chartType }) => {
  const formattedData = data.map((item) => {
    const date = new Date(item.created_at);
    const dateString = date.toLocaleDateString('fr-FR');
    const timeString = date.toLocaleTimeString('fr-FR', {
      hour: '2-digit',
      minute: '2-digit'
    });

    return {
      date: dateString,
      time: timeString,
      [chartType]: item[chartType]
    };
  });

  const columns = [
    {
      dataIndex: chartType,
      key: chartType,
      render: (text) => {
        const unitObj = enums?.enumsUnits?.find((unit) => unit[chartType]);
        const unit = unitObj ? unitObj[chartType] : '';
        return `${text} ${unit}`;
      }
    },
    {
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>{text}</div>
          <Divider type="vertical" />
          <div style={{ marginLeft: '8px' }}>{record.time}</div>
        </div>
      )
    }
  ];
  return (
    <Table
      dataSource={formattedData}
      columns={columns}
      showHeader={false}
      rowKey={(record) => `${record.date}-${record.time}}`}
    />
  );
};

TableSurvey.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  enums: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        enumsUnits: PropTypes.arrayOf(PropTypes.shape({}))
      })
    ),
    PropTypes.shape({
      enumsUnits: PropTypes.arrayOf(PropTypes.shape({}))
    })
  ]).isRequired,
  chartType: PropTypes.string.isRequired
};
