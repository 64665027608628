import { Route, Routes } from 'react-router-dom';
import { CreateUpdateStayPrescriptions } from './CreateUpdateStayPrescriptions';
import { CreateUpdateOptions } from './options/CreateUpdateOptions';

/**
 * Router component for Stay Prescriptions handling create and edit routes.
 *
 * @component
 * @returns {JSX.Element} JSX element representing the StayPrescriptionsRouter component.
 */
export const StayPrescriptionsRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdateStayPrescriptions purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdateStayPrescriptions purpose="edit" />}
    />
    <Route
      path="/prescription/:id/option/create"
      element={<CreateUpdateOptions purpose="createOption" />}
    />
    <Route
      path="/prescription/:id/option/edit/:optionId"
      element={<CreateUpdateOptions purpose="editOptions" />}
    />
  </Routes>
);
