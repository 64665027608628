import { Patient } from '../../../../../models/Patient';
import { usePatientAllergies } from '../../../../../utils/usePatientAllergies';
import { useConsultationsContext } from '../../../ConsultationsContext';
/**
 * Custom hook that provides actions for managing modal treatments.
 * @hook
 *
 * @param {Object} options - The options object.
 * @param {boolean} options.notCurrentMedications - Flag indicating if the medications are not current.
 * @param {function} options.setCurrentMedications - Function to set the current medications.
 * @param {Array} options.dataSource - The data source for the medications.
 * @param {Object} options.patient - The patient object.
 * @param {Array} options.entities - The entities array.
 * @param {function} options.setEntities - Function to set the entities array.
 * @param {function} options.setOpenModal - Function to set the open modal flag.
 * @returns {Object} - The object containing the patchCurrentMedication and handleSetCurrentMedications functions.
 */
export const useModalTreatmentsActions = ({
  notCurrentMedications,
  setCurrentMedications,
  dataSource,
  setDataSource,
  patient,
  entities,
  setEntities,
  setOpenModal
}) => {
  const { isAllergic } = usePatientAllergies(patient);
  const { getPatient, setIsLoading } = useConsultationsContext();

  /**
   * Function to patch the current medications.
   *
   * @returns {Promise<void>} - A promise that resolves when the current medications are patched.
   */
  const patchCurrentMedication = async () => {
    let newCurrentMedications;
    if (notCurrentMedications) {
      newCurrentMedications = [];
      setCurrentMedications([]);
      setDataSource([]);
    } else {
      newCurrentMedications = dataSource;
    }
    await Patient.patchCurrentMedications(patient?._id, {
      notCurrentMedications,
      currentMedications: newCurrentMedications
    });
    await getPatient(patient?._id);
    setIsLoading(false);
    setOpenModal(false);
  };

  /**
   * Function to set the current medications.
   *
   * @returns {void} - The function does not return anything.
   */
  const handleSetCurrentMedications = () => {
    const { brandname, ingredients } = entities[0];
    setCurrentMedications((current) => [
      ...current,
      {
        brandname,
        ingredients: ingredients?.flatMap(
          (ingredient) => ingredient.alt_labels
        ),
        dose: ingredients?.[0].dose,
        allergic_reaction: isAllergic(ingredients)
      }
    ]);
    setEntities([]);
  };

  /**
   * Adds a medication to the data source if it doesn't already exist.
   * @function
   *
   * @param {Object} medication - The medication object to be added.
   * @returns {void} - The function does not return anything.
   */
  const addMedication = (medication) => {
    const drugAlreadyExist = dataSource.some(
      (item) => item.brandname === medication.brandname
    );

    if (!drugAlreadyExist) {
      setDataSource((prevDataSource) => [...prevDataSource, medication]);
    }
  };

  return {
    patchCurrentMedication,
    handleSetCurrentMedications,
    addMedication
  };
};
