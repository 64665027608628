import { BaseModel } from './BaseModel';

/**
 * Represents a Prescription model.
 * @class
 * @extends BaseModel
 */
export class Prescription extends BaseModel {
  /**
   * Creates a new instance of the Prescription class.
   * @constructor
   * @param {Object} data - The data for the Prescription.
   */
  constructor(data) {
    super('stay-prescription', undefined, data);
  }

  /**
   * The name of the model.
   * @static
   * @type {string}
   */
  static modelName = 'stay-prescriptions';

  /**
   * Retrieves all prescriptions from the server.
   * @static
   * @function getAll
   *
   * @param {Object} options - The options for retrieving prescriptions.
   * @param {string} options.filter - The filter to apply when retrieving prescriptions.
   * @param {string} options.populate - The fields to populate when retrieving prescriptions.
   * @returns {Promise} A promise that resolves with the retrieved prescriptions.
   */
  static getAll({ filter = '', populate = '' }) {
    return BaseModel.dispatchAPI('GET', {
      url: `${Prescription.modelName}`,
      params: { populate, filter }
    });
  }

  /**
   * Retrieves a single prescription by its ID.
   * @static
   * @function getOneById
   *
   * @param {string} id - The ID of the prescription to retrieve.
   * @param {Object} options - Additional options for the retrieval.
   * @param {string} options.populate - The fields to populate in the retrieved prescription.
   * @returns {Promise<Object>} A promise that resolves to the retrieved prescription.
   */
  static getOneById(id, { populate = '' }) {
    return BaseModel.dispatchAPI('GET', {
      url: `${Prescription.modelName}/${id}?populate=${populate}`
    });
  }

  /**
   * Posts the stay prescription choices.
   * @static
   * @function postStayPrescriptionChoices
   *
   * @param {Object} body - The request body.
   * @returns {Promise} - A promise that resolves with the API response.
   */
  static postStayPrescriptionChoices(body) {
    return BaseModel.dispatchAPI('POST', {
      url: Prescription.modelName,
      body
    });
  }

  /**
   * Retrieves protocols with prescriptions.
   * @function getProtocols
   * @static
   *
   * @param {Object} params - Additional parameters for the API request.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static getProtocols({ ...params } = {}) {
    return BaseModel.dispatchAPI('GET', {
      url: 'protocols',
      params: { ...params }
    });
  }

  /**
   * Retrieves the favorite protocols.
   * @function getFavoritesProtocols
   * @static
   *
   * @param {Object} params - The parameters for the API request.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static getFavoritesProtocols({ ...params } = {}) {
    return BaseModel.dispatchAPI('GET', {
      url: 'favorites-protocols',
      params: { ...params }
    });
  }

  /**
   * Retrieves biologies prescriptions.
   * @static
   * @function getBiologiesPrescriptions
   *
   * @param {Object} params - The parameters for the API request.
   * @returns {Promise} A promise that resolves with the biologies prescriptions.
   */
  static getBiologiesPrescriptions({ ...params } = {}) {
    return BaseModel.fetchAPI('GET', 'stay-prescriptions', {}, params);
  }

  /**
   * Retrieves the enums for a given model.
   * @static
   * @function getEnums
   *
   * @param {string} modelName - The name of the model.
   * @returns {Promise} A promise that resolves with the enums.
   */
  static getEnums(modelName) {
    return BaseModel.dispatchAPI('GET', {
      url: `${modelName}/enums`
    });
  }
}
