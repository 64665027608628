import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Button, Row } from 'antd';
import { MedicineBoxFilled } from '@ant-design/icons';
import { usePrescriptionContext } from '../../../contexts/PrescriptionContext/PrescriptionContext';

/**
 * @component MedicationCard
 * @returns {React.Component} Card to display the button to search with synapse
 * @description This component is used in the exit prescription
 */
export const MedicationCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, type } = useParams();
  const { setPosologies, setEntities } = usePrescriptionContext();
  const translationKey = 'prescription.cards.medications';

  const handleSearchWithSynapse = () => {
    setPosologies([]);
    setEntities([]);
    navigate(`/prescription/create/medications/${type}/${id}`);
  };

  const title = (
    <Row justify="space-between" align="middle">
      <Row align="middle">
        <MedicineBoxFilled width={20} height={20} style={{ marginRight: 8 }} />
        {t(`${translationKey}.title`)}
      </Row>
      <Button onClick={() => handleSearchWithSynapse()}>
        {t(`${translationKey}.search_with_synapse`)}
      </Button>
    </Row>
  );

  return (
    <Card>
      <Card.Meta title={title} />
    </Card>
  );
};
