import { Routes, Route } from 'react-router-dom';
import { CreateUpdateVisitReasons } from './CreateUpdateVisitReasons';

/**
 * Component for handling routing related to visit reasons.
 *
 * @returns {JSX.Element} The rendered VisitReasonRouter component.
 */
export const VisitReasonRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdateVisitReasons purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdateVisitReasons purpose="edit" />}
    />
  </Routes>
);
