import PropTypes from 'prop-types';
import { useState } from 'react';
import useFields from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Component for creating or updating a sub center.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the component.
 * @returns {JSX.Element} - The rendered component.
 */
export const CreateUpdateSubCenter = ({ purpose }) => {
  const [address, setAddress] = useState('');
  const { fields, isFieldsLoading, base64 } = useFields(address, setAddress);
  const { user, setUser } = useAuthContext();

  const config = {
    onGetResource: {
      setFields: (data) => {
        setAddress(data.address || '');
        return {
          ...data,
          center: data.address || ''
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        address
      })
    },
    onUpdateResource: {
      setBody: (data) => {
        if (data._id === user._id) setUser({ ...user, ...data });
        return {
          ...data,
          address,
          ...(base64 ? { photo: base64 } : {})
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="sub-centers"
      resource="sub_centers"
      config={config}
    />
  );
};

CreateUpdateSubCenter.propTypes = {
  purpose: PropTypes.string.isRequired
};
