import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row } from 'antd';
import PropTypes from 'prop-types';
import { ConsultationsContext } from '../ConsultationsContext';
import { TitleMedicalBackground } from './MedicalBackground/TitleMedicalBackground';
import { MedicalBackgroundBottom } from './MedicalBackground/MedicalBackgroundBottom';

export const ShowMedicalBackground = ({ id }) => {
  const resource = 'consultations';
  const { t } = useTranslation();
  const { patient } = useContext(ConsultationsContext);
  const [modal, setModal] = useState(null);

  const onOpenModal = () => {
    const modalKey = Date.now(); // Use a unique key, or current timestamp
    setModal(<TitleMedicalBackground key={modalKey} id={id} />);
  };

  useEffect(() => {
    onOpenModal(true);
  }, [patient]);

  return (
    <Card>
      {modal}
      <Row>{t(`${resource}.form.surgical_history`)}</Row>
      <Row>
        <ul>
          {patient?.surgical_history?.map((operation) => (
            <li key={operation}>{operation}</li>
          ))}
        </ul>
      </Row>
      <Row>{t(`${resource}.form.medical_history`)}</Row>
      <Row>
        <ul>
          {patient?.medical_history?.map((medicalBackground) => (
            <li key={medicalBackground}>{medicalBackground}</li>
          ))}
        </ul>
      </Row>

      <MedicalBackgroundBottom />
    </Card>
  );
};
ShowMedicalBackground.propTypes = {
  id: PropTypes.string.isRequired
};
