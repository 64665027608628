import React, { useCallback, useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  ExceptionOutlined,
  WarningOutlined,
  FileDoneOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './components/ShowPatient/listContentShowPatient';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';

export const ShowPatient = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const navigate = useNavigate();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [patient, setPatient] = useState({});
  const listContent = useListContent(patient);

  const getPatient = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/patients/${id}`
      });
      setPatient(data);
    } catch (e) {
      message(e);
    }
  }, [id]);

  const archivePatient = async () => {
    try {
      await dispatchAPI('PATCH', { url: `/patients/archive/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const unArchivePatient = async () => {
    try {
      await dispatchAPI('PATCH', { url: `/patients/unarchive/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getPatient();
      setIsLoading(false);
    })();
  }, [getPatient]);

  return (
    <>
      <PageHeaderCustom
        title={`${patient.first_name} ${patient.last_name}`}
        extra={[
          <React.Fragment key="edit-button">
            <Link to={{ pathname: `${routes.PATIENTS}/edit/${id}` }}>
              <Button type="primary">
                {t('buttons.edit')}
                <EditOutlined />
              </Button>
            </Link>

            {!patient?.archived ? (
              <Popconfirm
                title={t('datatable.column.action.archive.title')}
                okText={t('datatable.column.action.archive.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.archive.cancel')}
                onConfirm={archivePatient}
                icon={<WarningOutlined />}
                key="archive-popconfirm"
              >
                <Button type="link" danger>
                  {`${t('buttons.archive')} `}
                  <ExceptionOutlined />
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                title={t('datatable.column.action.unarchive.title')}
                okText={t('datatable.column.action.unarchive.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.unarchive.cancel')}
                onConfirm={unArchivePatient}
                icon={<WarningOutlined />}
                key="unarchive-popconfirm"
              >
                <Button type="link">
                  {`${t('buttons.unarchive')} `}
                  <FileDoneOutlined />
                </Button>
              </Popconfirm>
            )}
          </React.Fragment>
        ]}
      />
      <ContentCustom>
        <Row>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <DescriptionList data={listContent} layout="vertical" translate />
          </Skeleton>
        </Row>
      </ContentCustom>
    </>
  );
};
