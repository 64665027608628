import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { message as AntdMessage } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { usePrescriptionContext } from '../../../contexts/PrescriptionContext/PrescriptionContext';
import { PrescriptionForm } from '../../../components/FreeTextPrescriptionForm/PrescriptionForm';
import { QuotesModal } from '../quotesModal/QuotesModal';

/**
 * @component CreateUpdateFreeTextPrescription
 * @returns {React.Component} Page to create a free text prescription
 */
export const CreateUpdateFreeTextPrescription = () => {
  const { id, type } = useParams();
  const navigate = useNavigate();
  const { user, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { patientInfos, patientId, setPatientId, content, setContent } =
    usePrescriptionContext();
  const { printDocument } = useDownloadDocument();
  const [loading, setLoading] = useState(false);

  /**
   * Create the body of the prescription.
   *
   * @function
   * @returns {Object|null} Returns the body of the prescription if it is valid,
   *                       otherwise returns null.
   */
  const createPrescriptionBody = () => {
    if (!content || !patientId || !patientInfos?.infos?.current_consultation) {
      return null;
    }

    return {
      doctor: user?._id,
      type,
      patient: patientId,
      consultation: patientInfos?.infos?.current_consultation?._id,
      purpose: 'FREE-TEXT',
      content: `<body>${content}</body>`
    };
  };

  /**
   * Submit the prescription to the server.
   *
   * @async
   * @function
   * @param {Object} body - The body of the prescription.
   * @throws {Error} Throws an error if the submission fails.
   * @returns {Promise<Object|null>} Returns a Promise that resolves to the prescription body
   *                                if the prescription has been created successfully,
   *                                otherwise resolves to null.
   */
  const submitPrescriptionToServer = async (body) => {
    try {
      setLoading(true);

      const { data } = await dispatchAPI('POST', {
        url: '/prescriptions',
        body
      });

      if (data && data._id) {
        setTimeout(async () => {
          await printDocument(data);
          navigate(`/consultations/show/${patientId}`);
          AntdMessage.success(t('success.message.prescription.create'));
        }, 500);
      }

      return null;
    } catch (error) {
      return message(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPatientId(id);
  }, [id]);

  return (
    <>
      <QuotesModal visible={loading} />
      <PrescriptionForm
        createBody={createPrescriptionBody}
        onSubmit={submitPrescriptionToServer}
        patientInfos={patientInfos}
        content={content}
        setContent={setContent}
        title={patientInfos?.headerTitle}
        showLabelInput={false}
        loading={loading}
      />
    </>
  );
};
