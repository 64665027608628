import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { Button, Form, Row, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { patchPatientApi } from '../../Components/patchPatientApi';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { ConsultationsContext } from '../../ConsultationsContext';
import { formItemLayoutCustom } from '../../../../utils/constants/formLayout';
import { SynapsePatientProfileWidget } from '../../../../components/Synapse/SynapsePatientProfileWidget';

const resource = 'consultations';

/**
 * This component renders the Medical Background form.
 * @component MedicalBackgroundForm
 * @param {Object} props Component props
 * @param {Array} props.fields Fields to display
 * @param {string} props.id Patient id
 * @param {Function} props.handleCloseModal Callback to execute on modal close
 * @returns {JSX.Element} Medical Background form
 */
export const MedicalBackgroundForm = ({ fields, id, handleCloseModal }) => {
  const generateFields = useGenerateFormItem();
  const { patient } = useContext(ConsultationsContext);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { handleSubmit } = patchPatientApi(id, handleCloseModal);

  useEffect(() => {
    form.setFieldsValue(patient);
  }, [patient, form, handleSubmit]);

  return (
    <>
      <h1>
        {t(`${resource}.form.medical_history`)} {t('of')}&nbsp;
        {patient?.first_name}&nbsp;
        {patient?.last_name}
      </h1>
      <Skeleton loading={!patient} active>
        <SynapsePatientProfileWidget patientInfos={patient} />
      </Skeleton>
      <Form form={form} onFinish={handleSubmit} {...formItemLayoutCustom}>
        {fields.map((field) => generateFields(resource, field))}
        <Row justify="end">
          <Button key="cancel" onClick={handleCloseModal}>
            {t('buttons.cancel')}
          </Button>
          <Button
            htmlType="submit"
            style={{
              borderRadius: '15px',
              margin: '2px'
            }}
          >
            {t('buttons.validate')}
          </Button>
        </Row>
      </Form>
    </>
  );
};

MedicalBackgroundForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func
};
MedicalBackgroundForm.defaultProps = {
  fields: undefined,
  handleCloseModal: undefined
};
