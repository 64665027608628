import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';
import { useErrorMessage } from '../../../../../utils/errorMessage';

/**
 * Hook to handle document submission.
 *
 * @hook useDocumentSubmit
 * @param {Object} params - The parameters passed to the hook.
 * @param {string} params.documentType - The type of the document to be submitted.
 * @param {string} params.documentWording - The wording of the document to be submitted.
 * @param {Function} params.setOpenModal - Function to set the open state of the modal.
 *
 * @returns {Object} An object containing the handleSubmit function.
 * @property {Function} handleSubmit - Function to handle the document submission.
 */
export const useDocumentSubmit = ({
  documentType,
  documentWording,
  setOpenModal,
  setLoading,
  patientId
}) => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { printDocument } = useDownloadDocument();
  const { subCenterRooms, setSubCenterRooms } = useState();
  const idSubCenter = user?.selected_sub_center || user?.default_sub_center;

  const { secretariat_phone_number: { number } = {} } = subCenterRooms || {};

  const getSubCenter = async () => {
    const population = [
      'first_visit_waiting_room',
      'follow_up_waiting_room',
      'return_visit_waiting_room'
    ];
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sub-centers/${idSubCenter}?populate=${population.join(',')}`
      });
      setSubCenterRooms(data);
      return data;
    } catch (e) {
      if (e.response) return message(e.response.status);
      return null;
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getSubCenter();
        return data;
      } catch (e) {
        if (e.response) return message(e.response.status);
        return null;
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async () => {
    if (patientId) {
      try {
        setLoading(true);
        const { data } = await dispatchAPI('POST', {
          url: `${documentType}/${patientId}`,
          body: {
            center: {
              phone_number: {
                number
              }
            },
            // concat the string and remove "s" from documentType to get the right key
            [`${documentType.slice(0, -1)}_wording`]: documentWording
          }
        });

        setTimeout(async () => {
          await printDocument(data);
        }, 1000);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message(error);
      }
    }
    setOpenModal(false);
  };

  return {
    handleSubmit
  };
};
