import { Link } from 'react-router-dom';
import { SettingOutlined } from '@ant-design/icons';

export const useActionColumns = () => [
  {
    key: 'actions',
    render: (record) => (
      <Link to={`/sub-centers/settings/${record._id}`}>
        <SettingOutlined />
      </Link>
    )
  }
];
