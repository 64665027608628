import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Skeleton, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom, CreateUpdateContainer } from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { usePrescriptionContext } from '../../../contexts/PrescriptionContext/PrescriptionContext';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { useFields } from './fields';
import { QuotesModal } from '../quotesModal/QuotesModal';

/**
 * @component
 * @name CreateUpdateRadiologyPrescription
 * @description A component to create/update radiology prescription
 * @param {String} purpose - A string to check if the component is used for creation or update
 * @returns {JSX.Element} React component
 */
export const CreateUpdateRadiologyPrescription = ({ purpose }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const fields = useFields();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const { printDocument } = useDownloadDocument();
  const { headerTitle, setPatientId, patientId, patientInfos, isLoading } =
    usePrescriptionContext();
  const [loading, setLoading] = useState(false);

  const postPrescription = async ({ radiology_prescriptions }) => {
    try {
      setLoading(true);

      const body = {
        purpose: 'RADIOLOGY',
        doctor: user._id,
        type: 'EXIT',
        patient: patientId,
        consultation: patientInfos?.infos?.current_consultation?._id,
        radiology_prescriptions
      };

      const { data } = await dispatchAPI('POST', {
        url: '/prescriptions',
        body
      });

      if (data && data._id) {
        setTimeout(async () => {
          await printDocument(data);
          navigate(-1);
        }, 500);
      }
    } catch (error) {
      message(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPatientId(id);
  }, [id]);

  const formExtra = (
    <Button
      loading={loading}
      disabled={loading}
      type="primary"
      htmlType="submit"
    >
      {t('buttons.validate')}
    </Button>
  );

  return (
    <ContentCustom>
      <QuotesModal visible={loading} />
      <Skeleton loading={isLoading} active>
        {id && (
          <>
            <PageHeaderCustom title={headerTitle} />
            <CreateUpdateContainer
              purpose={purpose}
              resource="radiology-prescription"
              fields={fields}
              customSubmit={postPrescription}
              formExtra={formExtra}
              isFormExtra
            />
          </>
        )}
      </Skeleton>
    </ContentCustom>
  );
};

CreateUpdateRadiologyPrescription.propTypes = {
  purpose: PropTypes.string.isRequired
};
