import { Popconfirm, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/**
 * Returns an array of columns for the IndividualTurnoverTargetTable component.
 * @hook
 *
 * @param {string} position - The position of the target.
 * @param {boolean} isEditing - Indicates whether the table is in editing mode.
 * @param {string} editingKey - The key of the currently editing record.
 * @param {function} cancel - The function to cancel editing.
 * @param {function} save - The function to save the edited record.
 * @param {function} edit - The function to start editing a record.
 * @param {boolean} saving - Indicates whether the save operation is in progress.
 * @returns {Array} An array of columns for the IndividualTurnoverTargetTable component.
 */
export const useColumns = (
  position,
  isEditing,
  editingKey,
  cancel,
  save,
  edit,
  saving
) => {
  const { t } = useTranslation();
  return [
    {
      title: t(
        `sub_centers.settings.individual_turnover_target.position.${position}`
      ),
      key: 'name',
      dataIndex: 'name',
      sorter: (a, b) => b.last_name.length - a.last_name.length
    },
    {
      title: t(
        'sub_centers.settings.individual_turnover_target.daily_turnover_target'
      ),
      key: 'daily_turnover_target',
      dataIndex: 'daily_turnover_target',
      sorter: (a, b) => a.daily_turnover_target - b.daily_turnover_target,
      editable: true,
      render: (value) => value && `${value} €`
    },
    {
      key: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="link"
              loading={saving}
              onClick={() => {
                save(record._id);
              }}
              style={{
                marginRight: 8
              }}
            >
              {t('buttons.save')}
            </Button>
            <Popconfirm
              title={t('settings.messages.confirm-deletion')}
              onConfirm={cancel}
            >
              <Button danger type="link">
                {t('buttons.cancel')}
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <Button
            type="link"
            disabled={editingKey !== ''}
            onClick={() => edit(record)}
          >
            <EditOutlined />
          </Button>
        );
      }
    }
  ];
};
