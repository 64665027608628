import { Input } from 'antd';

const usefields = () => {
  const constantsAndOtherSurveyFields = [
    {
      name: 'SBP',
      label: 'SBP'
    },
    {
      name: 'DBP',
      label: 'DBP'
    },
    {
      name: 'temperature',
      label: 'temperature',

      input: <Input type="number" suffix="°C" />
    },
    {
      name: 'heart_rate',
      label: 'heart_rate',

      input: <Input type="number" suffix="bpm" />
    },
    {
      name: 'OS',
      label: 'OS'
    },
    {
      name: 'oxygen_therapy',
      label: 'oxygen_therapy',

      input: <Input type="number" suffix="L/min" />
    },
    {
      name: 'peakflow',
      label: 'peakflow'
    },
    {
      name: 'height',
      label: 'height',

      input: <Input type="number" suffix="cm" />
    },
    {
      name: 'weight',
      label: 'weight',

      input: <Input type="number" suffix="kg" />
    },
    {
      name: 'capillary_blood_glucose',
      label: 'capillary_blood_glucose'
    },
    {
      name: 'hemocue',
      label: 'hemocue'
    },
    {
      name: 'micro_INR',
      label: 'micro_INR'
    },
    {
      name: 'quick_tetanos_test',
      label: 'quick_tetanos_test'
    },
    {
      name: 'antigenic_test',
      label: 'antigenic_test'
    },
    {
      name: 'exacto_pro',
      label: 'exacto_pro'
    }
  ];

  const delocalizedBiologyFields = [
    {
      name: 'hemoglobin',
      label: 'hemoglobin',

      input: <Input type="number" suffix="g/L" />
    },
    {
      name: 'sodium',
      label: 'sodium'
    },
    {
      name: 'potassium',
      label: 'potassium'
    },
    {
      name: 'chlorine',
      label: 'chlorine'
    },
    {
      name: 'creatinine',
      label: 'creatinine'
    },
    {
      name: 'clearance',
      label: 'clearance'
    },
    {
      name: 'CRP',
      label: 'CRP'
    },
    {
      name: 'tropo',
      label: 'tropo'
    },
    {
      name: 'D_Dimer',
      label: 'D_Dimer'
    },
    {
      name: 'B_HCG',
      label: 'B_HCG'
    }
  ];

  const urineAnalysisFields = [
    {
      name: 'ket',
      label: 'ket'
    },
    {
      name: 'glu',
      label: 'glu'
    },
    {
      name: 'leu',
      label: 'leu'
    },
    {
      name: 'nit',
      label: 'nit'
    },
    {
      name: 'ery',
      label: 'ery'
    },
    {
      name: 'pro',
      label: 'pro'
    },
    {
      name: 'urine_test_strip',
      label: 'urine_test_strip'
    }
  ];

  return {
    constantsAndOtherSurveyFields,
    delocalizedBiologyFields,
    urineAnalysisFields
  };
};
export default usefields;
