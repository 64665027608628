import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  ContainerOutlined,
  ThunderboltOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import { User } from '../../models/User';

export const ShowUser = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState();
  const listContent = useListContent(user);

  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${id}?populate=associated_center`
      });
      setUser(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const archiveUnarchivedUser = async () => {
    await User.archiveUnarchiveUser(id, { archived: !user.archived });
    navigate(-1);
  };

  useEffect(() => {
    (async () => {
      await getUser();
    })();
  }, [getUser]);

  return (
    <>
      {!isLoading && (
        <PageHeaderCustom
          title={t('users.show.title')}
          extra={
            user?.archived ? (
              <Popconfirm
                title={t('datatable.column.action.unarchive.title')}
                okText={t('datatable.column.action.unarchive.ok')}
                okButtonProps={{ type: 'primary' }}
                cancelText={t('datatable.column.action.unarchive.cancel')}
                onConfirm={archiveUnarchivedUser}
                icon={<WarningOutlined />}
              >
                <Button type="primary">
                  {`${t('buttons.unarchive')} `}
                  <ThunderboltOutlined />
                </Button>
              </Popconfirm>
            ) : (
              <>
                <Link to={{ pathname: `${routes.USERS}/edit/${id}` }}>
                  <Button type="primary">
                    {`${t('buttons.edit')} `}
                    <EditOutlined />
                  </Button>
                </Link>
                <Popconfirm
                  title={t('datatable.column.action.archive.title')}
                  okText={t('datatable.column.action.archive.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.archive.cancel')}
                  onConfirm={archiveUnarchivedUser}
                  icon={<WarningOutlined />}
                >
                  <Button type="link" danger>
                    {`${t('buttons.archive')} `}
                    <ContainerOutlined />
                  </Button>
                </Popconfirm>
              </>
            )
          }
        />
      )}
      <ContentCustom>
        <Row gutter={[0, 24]}>
          <Skeleton
            loading={isLoading}
            title={0}
            paragraph={0}
            avatar={{ size: 64 }}
            active
          >
            <Avatar size={64} src={user && user.photo ? user.photo : ``} />
          </Skeleton>
        </Row>
        <Row>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <DescriptionList data={listContent} layout="vertical" translate />
          </Skeleton>
        </Row>
      </ContentCustom>
    </>
  );
};
