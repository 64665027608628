import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Divider, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useDownloadDocument } from '../../../../utils/downloadDoc';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * Custom hook that returns columns configuration for the DoctorsLetter component.
 *
 * @hook
 *
 * @returns {Object} The columns configuration.
 */
export const useColumns = () => {
  const { viewDocument } = useDownloadDocument();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const handleDeleteDoctorsLetter = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `doctors-letters/${id}`,
        body: { archived: true }
      });
    } catch (error) {
      message(error);
    }
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => dayjs(date).format('DD/MM/YYYY')
    },
    {
      title: 'Motif de consultation',
      dataIndex: 'visit_reason',
      key: 'visit_reason'
    },
    {
      title: 'Médecin traitant',
      dataIndex: 'general_practitioner',
      key: 'general_practitioner'
    },
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          <EyeOutlined
            onClick={() =>
              viewDocument({ _id: record.doctors_letter_document })
            }
          />
          <Divider type="vertical" />
          <Popconfirm
            description={t('patients.form.messages.delete_doctors_letter')}
            onConfirm={() => handleDeleteDoctorsLetter(record._id)}
            okText={t('yes')}
            cancelText={t('no')}
          >
            <DeleteOutlined />
          </Popconfirm>
        </>
      )
    }
  ];

  return { columns };
};
