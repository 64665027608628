import { Switch, Select } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook for generating form fields based on user data.
 * @hook
 * @param {object} user - User data object
 * @param {function} patch2FA - Function for updating user double factor authentication settings
 * @param {boolean} isLoading - Boolean value indicating whether the page is loading
 * @returns {object} Object containing arrays of form fields for double factor authentication and default center settings
 */
const useFields = (user, patch2FA, isLoading) => {
  const { t } = useTranslation();
  const {
    double_factor_authentication,
    associated_center,
    default_sub_center,
    hiden_sub_centers
  } = user;

  const { Option } = Select;

  const doubleFactorAuthFields = [
    {
      label: 'double_factor_authentication',
      name: ['double_factor_authentication'],
      input: (
        <Switch
          onChange={(value) => {
            patch2FA({ double_factor_authentication: value });
          }}
          loading={isLoading}
          checked={double_factor_authentication}
          defaultChecked={double_factor_authentication}
          checkedChildren={t('yes')}
          unCheckedChildren={t('no')}
        />
      )
    }
  ];

  const defaultCenterFields = [
    {
      label: 'default_center',
      name: ['default_sub_center'],
      input: (
        <Select>
          {(associated_center?.sub_centers || [])
            ?.filter(
              (sub_center) => !hiden_sub_centers.includes(sub_center._id)
            )
            .map((sub_centers) => (
              <Option key={sub_centers?._id} value={sub_centers?._id}>
                {sub_centers?.name}
              </Option>
            ))}
        </Select>
      )
    },
    {
      label: 'hiden_sub_centers',
      name: ['hiden_sub_centers'],
      input: (
        <Select
          mode="multiple"
          allowClear
          style={{
            width: '100%'
          }}
          placeholder={t('users.form.select_center')}
        >
          {(associated_center?.sub_centers || [])
            ?.filter((sub_center) => sub_center._id !== default_sub_center)
            .map((sub_centers) => (
              <Option key={sub_centers?._id} value={sub_centers?._id}>
                {sub_centers?.name}
              </Option>
            ))}
        </Select>
      )
    }
  ];

  return {
    doubleFactorAuthFields,
    defaultCenterFields
  };
};

export default useFields;
