import { useTranslation } from 'react-i18next';
import { Button, Card, List, Modal, Space, Typography } from 'antd';
import { useState } from 'react';
import dayjs from 'dayjs';
import { Datatable } from '../../../components/DataTable/Datatable';
import { useConsultationsContext } from '../ConsultationsContext';

/**
 * React component to display paramedical transmissions.
 * @component ShowParamedicalsTransmissions
 * @returns {JSX.Element} JSX.Element
 */
export const ShowParamedicalsTransmissions = () => {
  const { t } = useTranslation();
  const { patient: { paramedicals_transmissions } = {} } =
    useConsultationsContext();
  const resource = 'consultations';

  const [openModal, setOpenModal] = useState(false);

  const columns = [
    {
      title: t(`${resource}.paramedicals_transmissions.date`),
      dataIndex: 'date',
      key: 'date',
      render: (date) => dayjs(date).format('DD-MM-YYYY H[h]mm')
    },
    {
      title: t(`${resource}.paramedicals_transmissions.transmissions`),
      dataIndex: 'label',
      key: 'label'
    },
    {
      title: t(`${resource}.paramedicals_transmissions.nurse`),
      dataIndex: 'nurse',
      key: 'nurse',
      render: ({ first_name, last_name }) => `${first_name} ${last_name}`.trim()
    }
  ];

  return (
    <>
      <Card
        title={t(`${resource}.paramedicals_transmissions.title`)}
        extra={
          <Button onClick={() => setOpenModal(true)}>
            {t('buttons.history')}
          </Button>
        }
      >
        <List>
          {paramedicals_transmissions?.map(
            ({ date, nurse: { first_name, last_name }, label }, index) =>
              index === 0 && (
                <List.Item>
                  <Space.Compact direction="vertical">
                    <Typography.Text>
                      {dayjs(date).format('DD-MM-YYYY H[h]mm')} -{' '}
                      {`${first_name} 
                      ${last_name}`}
                    </Typography.Text>
                    <Typography.Text>{label}</Typography.Text>
                  </Space.Compact>
                </List.Item>
              )
          )}
        </List>
      </Card>
      <Modal open={openModal} onCancel={() => setOpenModal(false)}>
        <Datatable
          dataSource={paramedicals_transmissions || []}
          resourceName="patients"
          columns={columns}
          customActionColumn
        />
      </Modal>
    </>
  );
};
