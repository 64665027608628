import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, Button } from 'antd';
import { ContentCustom } from '../../../components';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { usePrescriptionContext } from '../../../contexts/PrescriptionContext/PrescriptionContext';

import { FavoritesCard } from '../components/FavoritesCard';
import { MedicationCard } from '../components/MedicationCard';
import { CaresCard } from '../components/CaresCard';
import { TeleservicesCard } from '../components/TeleservicesCard';
import { ProtocolesCard } from '../components/ProtocolesCard';

/**
 * @component PrescriptionChoiceExit
 * @returns {React.Component} Page of the exit prescription
 */
export const PrescriptionChoiceExit = () => {
  const { t } = useTranslation();
  const { id, type } = useParams();
  const navigate = useNavigate();
  const { patientInfos, setContent } = usePrescriptionContext();

  const handleNewFreeTextPrescription = () => {
    setContent('');
    navigate(`/prescription/create/free-text/${type}/${id}`);
  };

  const title = (
    <Row justify-content="flex-end" align="left">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginRight: 8 }}
      >
        <path
          d="M1 21V3H15V21H10V16H6V21H1ZM5 13H7V11H5V13ZM5 9H7V7H5V9ZM9 13H11V11H9V13ZM9 9H11V7H9V9ZM19.5 15.5L18.1 14.1L19.175 13H16V11H19.175L18.1 9.9L19.5 8.5L23 12L19.5 15.5Z"
          fill="#004A8F"
        />
      </svg>
      {t('prescription.cards.exit_prescription.title')}
      <Button type="primary" onClick={() => handleNewFreeTextPrescription()}>
        + Prescription libre
      </Button>
    </Row>
  );

  return (
    <ContentCustom>
      <PageHeaderCustom
        title={patientInfos.headerTitle}
        onBack={() => navigate(-1)}
      />
      <Card title={title} className="prescription-choice-exit-card">
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <FavoritesCard />
          </Col>
          <Col span={8}>
            <Row gutter={[16, 16]}>
              <MedicationCard className="ant-card" />
            </Row>
            <Row gutter={[16, 16]}>
              <CaresCard className="ant-card" />
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={[16, 16]}>
              <TeleservicesCard className="ant-card" />
            </Row>
            <Row gutter={[16, 16]}>
              <ProtocolesCard className="ant-card" />
            </Row>
          </Col>
        </Row>
      </Card>
    </ContentCustom>
  );
};
