import { Link } from 'react-router-dom';
import { Divider, Popconfirm } from 'antd';
import {
  ContainerOutlined,
  SettingOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined,
  RollbackOutlined
} from '@ant-design/icons';

const iconSize = 18;

/**
 * Returns an array of columns for sub centers actions.
 *
 * @hook
 * @param {Object} t - i18n translate function.
 * @param {string} centerId - The ID of the center.
 * @param {Function} archivingSubCenter - The function to archive / unarchive sub center.
 * @returns {Array} - An array of columns for sub centers actions.
 */
export const useSubCentersActionsColumns = (
  t,
  centerId,
  archivingSubCenter
) => [
  {
    key: 'action',
    align: 'right',
    render: (record) => (
      <>
        <Link to={`/sub-centers/show/${record?._id}`}>
          <EyeOutlined style={{ fontSize: iconSize }} />
        </Link>
        <Divider type="vertical" />
        <Link to={`/sub-centers/edit/${record?._id}/${centerId}`}>
          <EditOutlined style={{ fontSize: iconSize }} />
        </Link>
        <Divider type="vertical" />
        <Link to={`/sub-centers/settings/${record?._id}`}>
          <SettingOutlined style={{ fontSize: iconSize }} />
        </Link>
        <Divider type="vertical" />
        {!record?.archived ? (
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => archivingSubCenter(record?._id, record?.archived)}
            icon={<WarningOutlined />}
          >
            <ContainerOutlined
              style={{ color: '#b51010', fontSize: iconSize }}
            />
          </Popconfirm>
        ) : (
          <Popconfirm
            title={t('datatable.column.action.unarchive.title')}
            okText={t('datatable.column.action.unarchive.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.unarchive.cancel')}
            onConfirm={() => archivingSubCenter(record?._id, record?.archived)}
            icon={<WarningOutlined />}
          >
            <RollbackOutlined
              style={{ color: '#b51010', fontSize: iconSize }}
            />
          </Popconfirm>
        )}
      </>
    )
  }
];
