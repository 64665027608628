import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Tree, Table, Spin } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * @component
 * @description Component that displays the tree card. It is used in PrescriptionChoiseStay component to display the tree with the stay prescriptions configurations.
 * @param {Object} props Component props
 * @param {string} props.category Category of the tree - IMAGING, BIOLOGY, ect...
 * @param {Object} props.treeProps Tree props
 * @param {Array} props.checkedKeys Array of checked keys
 * @param {Function} props.setCheckedKeysData Function to set the checked keys data
 * @returns {JSX.Element} Tree card component
 */

// TODO WIP
export const TreeCard = ({
  category,
  treeProps: { setTreeKeys } = {},
  checkedKeys = [],
  setCheckedKeys,
  setCheckedKeysData,
  favCheckedKeys
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkedKeysValues, setCheckedKeysValues] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  const [checkedKeysSet, setCheckedKeysSet] = useState(new Set());

  const getTreeKeys = (values) => {
    if (values?.length > 0) {
      const keys = values?.flatMap((value) => value.key);
      return keys;
    }
    return [];
  };

  useEffect(() => {
    const newSet = new Set([
      ...checkedKeys,
      ...checkedKeysValues,
      ...getTreeKeys(favCheckedKeys[category.toLowerCase()])
    ]);
    setCheckedKeysSet(newSet);
  }, [checkedKeys, checkedKeysValues, favCheckedKeys]);

  useEffect(() => {
    setCheckedKeysData(Array.from(checkedKeysSet));
  }, [checkedKeysSet]);

  const title = t(`prescription.enums.${category}`);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/config-stay-prescriptions/tree-options?category=${category}&archived=false&user_display=true`
      });

      if (isMounted) setTreeData(data);
    } catch (error) {
      message(error);
    } finally {
      if (isMounted) setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, []);

  const handleChecked = useCallback(
    (checkedNode) => {
      const checkedNodeFormatted = checkedNode?.filter(
        (node) => !node.children || !node.children.length > 0
      );

      if (
        checkedNodeFormatted?.length > 0 ||
        favCheckedKeys[category.toLowerCase()]?.length > 0
      ) {
        setTreeKeys([...checkedNodeFormatted]);
      }
    },
    [favCheckedKeys]
  );

  useEffect(() => {
    handleChecked(favCheckedKeys[category.toLowerCase()]);
  }, [handleChecked]);

  return (
    <Card
      title={
        <div>
          <mdiMicroscope style={{ marginRight: '8px' }} />
          {title}
        </div>
      }
      style={{ width: '100%' }}
    >
      <Spin spinning={loading}>
        {treeData.length > 0 ? (
          <Tree
            checkable
            onCheck={(checkedKeysValue, { checkedNodes }) => {
              handleChecked(checkedNodes);
              setCheckedKeysValues(checkedKeysValue);
              setCheckedKeys(checkedKeysValue);
            }}
            checkedKeys={Array.from(checkedKeysSet)}
            // checkedKeys={['65c643dd612f5fd3e7d4da14']}
            treeData={treeData}
            autoExpandParent
          />
        ) : (
          <Table />
        )}
      </Spin>
    </Card>
  );
};

TreeCard.propTypes = {
  category: PropTypes.string.isRequired,
  treeProps: PropTypes.shape({
    setTreeKeys: PropTypes.func.isRequired
  }).isRequired,
  checkedKeys: PropTypes.arrayOf(PropTypes.string),
  setCheckedKeysData: PropTypes.func.isRequired,
  setCheckedKeys: PropTypes.func.isRequired,
  favCheckedKeys: PropTypes.shape({}).isRequired
};

TreeCard.defaultProps = {
  checkedKeys: []
};
