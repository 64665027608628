/* eslint-disable import/no-extraneous-dependencies */
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { getSynapseToken } from '../../utils/synapse';

/**
 * This component renders the Synapse Patient Profile widget.
 * @component SynapsePatientProfileWidget
 * @param {Object} props Component props
 * @param {Object} props.patientInfos Patient infos
 * @returns {JSX.Element} Synapse Patient Profile widget
 */
export const SynapsePatientProfileWidget = ({ patientInfos }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [JWT, setJWT] = useState(null);
  const [profileWidget, setProfileWidget] = useState(null);

  const fetchNewToken = async () => {
    const token = await getSynapseToken(dispatchAPI);
    setJWT(token);
  };

  useEffect(() => {
    if (!JWT) {
      fetchNewToken();
      return;
    }
    if (
      !patientInfos ||
      profileWidget ||
      !document.getElementById('profilePlaceholder')
    )
      return;
    setTimeout(() => {
      // Initialize the widget once the component is mounted
      const newProfileWidget = new window.Synapse.PatientProfileWidget(JWT);

      const getPatientGender = () => {
        switch (patientInfos?.gender) {
          case 'WOMAN':
            return window.Synapse.ProfileType.Sex.FEMALE;
          case 'MAN':
            return window.Synapse.ProfileType.Sex.MALE;
          default:
            return null;
        }
      };

      const patient = {
        profile: {
          ...patientInfos,
          sex: getPatientGender(),
          age: dayjs().diff(patientInfos?.birth_date, 'year'),
          birthDate: new Date(patientInfos?.birth_date)
        }
      };

      newProfileWidget.init(
        document.getElementById('profilePlaceholder'),
        patient
      );

      newProfileWidget.setCallback('onTokenExpiration', () => {
        fetchNewToken();
      });

      // Set callback for profile update
      newProfileWidget.setCallback('onProfileUpdate', async (_, data) => {
        try {
          await dispatchAPI('PATCH', {
            url: `/patients/patch-grounds/${patientInfos?._id}`,
            body: data
          });
        } catch (error) {
          message(error);
        }
      });

      setProfileWidget(newProfileWidget);
    }, 100);
  }, [patientInfos, JWT]);

  return (
    <>
      {!profileWidget && <Skeleton active paragraph={{ rows: 10 }} />}
      <Row justify="center">
        <Col span={14} id="profilePlaceholder" />
      </Row>
    </>
  );
};

SynapsePatientProfileWidget.propTypes = {
  patientInfos: PropTypes.shape({
    gender: PropTypes.string,
    birth_date: PropTypes.string,
    _id: PropTypes.string
  }).isRequired
};
