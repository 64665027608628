import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Button } from 'antd';
import { ThunderboltOutlined, WarningTwoTone } from '@ant-design/icons';
import { ConsultationsContext } from '../../ConsultationsContext';

const url =
  'https://auth.mesvaccins.net/realms/professional/protocol/openid-connect/auth?client_id=kairos-pro-front&redirect_uri=https%3A%2F%2Fcvnpro.mesvaccins.net%2F&state=e8890f5b-fde1-4959-97ea-859900279dfd&response_mode=fragment&response_type=code&scope=openid&nonce=7b23923c-9420-4175-8c1c-3c187649ffea';

/**
 * `MedicalBackgroundBottom` is a component that displays the medical background information of a patient.
 * @component
 *
 * @prop {Object} [patient={}] - Object containing details about the patient.
 * @prop {Array} patient.consultations - List of patient's consultations.
 * @prop {Object} patient.consultations.medicalBackground - Medical background of the patient.
 * @prop {Array} patient.consultations.medicalBackground.allergies - List of allergies.
 * @prop {string} patient.consultations.medicalBackground.lifestyle - Patient's lifestyle information.
 *
 * @context {Object} ConsultationsContext - Context containing the patient's consultation data.
 *
 * @hook {function} useTranslation - Hook from 'react-i18next' library used for string localization.
 *
 * @ref
 */

export const MedicalBackgroundBottom = () => {
  const { t } = useTranslation();
  const { patient } = useContext(ConsultationsContext);
  return (
    <Row justify="space-between">
      <Col span={8}>
        {patient?.allergies?.includes(
          // TODO: Waiting for synapse API connection, will be deleted then
          'aucune'
        ) ? (
          <WarningTwoTone twoToneColor="#D3D3D3" />
        ) : (
          <WarningTwoTone twoToneColor="#FF0000" />
        )}{' '}
        {t('consultations.form.allergies')}
        <ul>
          {patient?.allergies?.map((allergy) => (
            <li key={allergy}>{allergy} </li>
          ))}
        </ul>
      </Col>
      <Col span={8}>
        <Row>{t('consultations.form.lifestyle')}</Row>
        <Row>{patient?.lifestyle}</Row>
      </Col>
      <Col span={8}>
        <Row>{t('consultations.form.vaccines')}</Row>
        <Row>
          <Button
            icon={<ThunderboltOutlined />}
            onClick={() => window.open(`${url}`, '_blank')}
          />
        </Row>
      </Col>
    </Row>
  );
};
