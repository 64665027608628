import { useCallback, useEffect, useState } from 'react';
import { Select, Tag, Input, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { userRoles } from '../../utils/constants/tagColors';
import { phoneNumberValidator } from '../../utils/validators';

const { Option } = Select;
const { TextArea } = Input;

/**
 * Custom hook that provides fields configuration for the profile form.
 *
 * @hook
 * @returns {Object} An object containing the fields configuration.
 */
const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState({});

  const fields = [
    {
      name: ['last_name'],
      rules: [{ required: true }],
      input: <Input disabled />
    },
    {
      name: ['first_name'],
      rules: [{ required: true }],
      input: <Input disabled />
    },
    {
      name: ['email'],
      rules: [{ required: true }],
      input: <Input disabled />
    },
    {
      name: ['role'],
      rules: [{ required: true }],
      input: (
        <Select loading={isLoading} disabled>
          {(enums.roles || []).map((role) => (
            <Option key={role} value={role}>
              <Tag color={userRoles[role.split(':')[1]]}>
                {t(`users.tags.${role.split(':')[1]}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      rules: [
        { required: true },
        { validator: (_, value) => phoneNumberValidator(t, value) }
      ],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    }
  ];

  switch (user.position) {
    case 'DOCTOR':
      fields.push(
        {
          name: ['cps_number'],
          input: <Input type="number" />
        },
        {
          name: ['specialization'],
          input: <Input />
        },
        {
          name: ['academic_carreer'],
          input: (
            <TextArea
              maxLength={100}
              placeholder={t('users.form.placeholders.maximum_characters')}
              showCount
              style={{ resize: 'none' }}
            />
          )
        }
      );
      break;
    default:
      break;
  }

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getEnums();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields
  };
};

export default useFields;
