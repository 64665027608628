import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import { ListSpecialist } from '../../specialist/ListSpecialist';
import { ListDirectory } from '../../directory/ListDirectory';
import { PageHeaderCustom } from '../../../components';
import { useManagerContext } from '../../../contexts/ManagerContext/ManagerContext';

/**
 * React component for displaying a list of data from various databases.
 *
 * @returns {JSX.Element} - The rendered React element.
 */
export const ListDataBase = () => {
  const { t } = useTranslation();
  const { activeKey, setActiveKey } = useManagerContext();

  // Configuration for the Tabs component, specifying keys, labels, and corresponding child components
  const items = [
    {
      key: 'specialists',
      label: t('data_bases.specialists.title'),
      children: <ListSpecialist customEdit withCreateButton />
    },
    {
      key: 'directories',
      label: t('data_bases.directories.title'),
      children: (
        <ListDirectory isPageHeaderCustom={false} withCreateButton customEdit />
      )
    }
  ];

  return (
    <ContentCustom>
      <PageHeaderCustom title={t('data_bases.title')} />
      <Tabs
        activeKey={activeKey}
        items={items}
        onChange={(key) => setActiveKey(key)}
      />
    </ContentCustom>
  );
};
