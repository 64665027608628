import PropTypes from 'prop-types';
import { Row, Table, Collapse, Col, Form, Typography } from 'antd';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CardsDisplay } from '../../CardsHistory/CardsDisplay';
import { SorterDateReason } from '../../../../consultations/Components/SorterDateReason';
import { useColumnsDoctorMails } from '../../../../consultations/PatientOverview/Documents/columns/columnsDoctorMails';
import { useColumnsPrescriptions } from '../../../../consultations/PatientOverview/Documents/columns/columnsPrescriptions';
import { useColumnsDateReasons } from '../../../../consultations/PatientOverview/Documents/columns/columnsDateReasons';
import useFields from '../../../../consultations/fields';

/**
 * Renders a component for displaying administrative documents.
 * This component displays various administrative tables, forms, and card displays.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.record - Information about the patient.
 * @param {string} props.record.first_name - The first name of the patient.
 * @param {string} props.record.last_name - The last name of the patient.
 * @param {string} props.resource - The resource for translation.
 * @returns {JSX.Element} The rendered component for displaying administrative documents.
 */
export const ContentModalAdministrativeDoc = ({ record, resource }) => {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const { Title } = Typography;
  const [form] = Form.useForm();
  const { first_name = '-', last_name = '-' } = record.patient || {};
  const [isSwitched, setIsSwitched] = useState(false);
  const columnsDoctorMails = useColumnsDoctorMails(isSwitched);
  const columnsPrescriptions = useColumnsPrescriptions();
  const columnsDateReasons = useColumnsDateReasons();
  const { identityCardFields, insuranceCardFields } = useFields();

  useEffect(() => {
    if (record) {
      form.setFieldsValue(record);
    }
  }, [record]);

  const handleSwitchChange = (enabled) => {
    setIsSwitched(enabled);
  };

  return (
    <Col>
      <Row>
        <Title>
          {t(`${resource}.form.administrative_documents`)}&nbsp;
          {first_name}&nbsp;
          {last_name}
        </Title>
      </Row>
      <Form form={form}>
        <Row justify="space-evenly">
          <CardsDisplay
            fields={identityCardFields}
            resource={resource}
            translationKey="identity_card"
          />
          <CardsDisplay
            fields={insuranceCardFields}
            resource={resource}
            translationKey="health_mutual_card"
          />
        </Row>
      </Form>
      <Collapse expandIconPosition="end">
        <Panel
          header={t(`${resource}.documents.referring_physician_mails`)}
          key="1"
        >
          <SorterDateReason handleSwitchChange={handleSwitchChange} />
          <Table columns={columnsDoctorMails} />
        </Panel>
      </Collapse>

      <Collapse expandIconPosition="end">
        <Panel
          header={t(`${resource}.documents.treatment_prescriptions`)}
          key="2"
        >
          <Table columns={columnsPrescriptions} />
        </Panel>
      </Collapse>

      <Collapse expandIconPosition="end">
        <Panel header={t(`${resource}.documents.visit_certificates`)} key="3">
          <Table columns={columnsDateReasons} />
        </Panel>
      </Collapse>

      <Collapse expandIconPosition="end">
        <Panel
          header={t(`${resource}.documents.administrative_documents`)}
          key="4"
        >
          <Table columns={columnsDateReasons} />
        </Panel>
      </Collapse>
    </Col>
  );
};

ContentModalAdministrativeDoc.propTypes = {
  record: PropTypes.shape({
    patient: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  }).isRequired,
  resource: PropTypes.string.isRequired
};
