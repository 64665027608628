import { Routes, Route } from 'react-router-dom';
import { ListDataBase } from './ListDataBase';
import { VisitReasonRouter } from './visitReasons/VisitReasonRouter';
import { ProtocolsRouter } from './protocols/ProtocolsRouter';
import { DataBaseContextProvider } from '../../contexts/DataBasesContext';
import { StayPrescriptionsRouter } from './configPrescriptions/StayPrescriptionsRouter';

/**
 * Component for routing related to the database module.
 *
 * @component
 * @returns {JSX.Element} The DatabaseRouter component.
 */
export const DataBaseRouter = () => (
  <DataBaseContextProvider>
    <Routes>
      <Route path="/visit-reasons/*" element={<VisitReasonRouter />} />
      <Route
        path="/config-stay-prescriptions/*"
        element={<StayPrescriptionsRouter />}
      />
      <Route path="/protocols/*" element={<ProtocolsRouter />} />
      <Route index element={<ListDataBase />} />
    </Routes>
  </DataBaseContextProvider>
);
