import { PrinterOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { PrintButtonModal } from '../modals/PrintButtonModal';

/**
 * A component that displays a print button.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.fields - The fields to be printed.
 * @param {string} props.documentWording - The wording of the document to be printed.
 * @param {string} props.documentType - The type of the document to be printed.
 *
 * @returns {JSX.Element} The PrintButton component.
 */
export const PrintButton = ({
  fields,
  documentWording,
  documentType,
  patientId
}) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Tooltip title={t('buttons.print')}>
        <Button type="Link" onClick={() => setOpenModal(true)}>
          <PrinterOutlined />
        </Button>
      </Tooltip>
      <PrintButtonModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        fields={fields}
        documentWording={documentWording}
        documentType={documentType}
        patientId={patientId}
      />
    </>
  );
};

PrintButton.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  documentWording: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  patientId: PropTypes.string
};

PrintButton.defaultProps = {
  patientId: ''
};
