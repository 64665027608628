import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { userRoles } from '../../utils/constants/tagColors';
import { getPhoneNumber } from '../../utils/formatters';

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('users.form.last_name').toUpperCase(),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('users.form.first_name').toUpperCase(),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.position').toUpperCase(),
      key: 'position',
      dataIndex: 'position',
      sorter: true,
      render: (position) => position && t(`users.positions.${position}`)
    },
    {
      title: t('users.form.role').toUpperCase(),
      key: 'role',
      dataIndex: 'role',
      render: (role) =>
        role && (
          <Tag color={userRoles[role.split(':')[1]]}>
            {t(`users.tags.${role.split(':')[1]}`, {
              defaultValue: role.split(':')[1]
            })}
          </Tag>
        ),
      filters: Object.keys(userRoles).map((role) => ({
        text: t(`users.tags.${role}`, { defaultValue: role }),
        value: `users:${role}`
      })),
      sorter: true
    },
    {
      title: t('users.form.phone_number.number').toUpperCase(),
      key: 'phone_number',
      dataIndex: 'phone_number',
      sorter: true,
      render: (phone_number) => getPhoneNumber(phone_number)
    },
    {
      title: t('users.form.email').toUpperCase(),
      key: 'email',
      dataIndex: 'email',
      sorter: true
    }
  ];
};
