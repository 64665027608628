import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Select, Switch, notification } from 'antd';
import { useErrorMessage } from '../../../utils/errorMessage';
import { GetEnums } from '../../../utils/getEnums';

/**
 * Custom hook for defining form fields for the CreateUpdateStayPrescriptions component.
 *
 * @param {Array} stayPrescriptions - The list of stay prescriptions to populate Select options.
 * @returns {Object} An object containing the `fields` array.
 */
export const useFields = ({
  prescriptionWording,
  setPrescriptionWording,
  category,
  setCategory,
  withOptions,
  setWithOptions,
  purpose
}) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { getEnums } = GetEnums();
  const [options, setOptions] = useState([]);

  const fetchEnums = async () => {
    try {
      const { categories } = await getEnums('config-stay-prescriptions');

      const optionsArray = (categories || []).map((item) => ({
        label: t(`data_bases.stay_prescriptions.categories.${item}`),
        value: item
      }));

      setOptions(optionsArray);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchEnums();
    })();
  }, []);

  const onChange = (checked) => {
    if (purpose === 'create') {
      setWithOptions(checked);
      if (checked) {
        notification.warning({
          message: t('notifications.with_options_warning')
        });
      }
    } else {
      notification.warning({
        message: t('notifications.edit_options_warning')
      });
    }
  };

  const fields = [
    {
      name: ['prescription_wording'],
      label: 'prescription_wording',
      rules: [{ required: true }],
      input: <Input onChange={(e) => setPrescriptionWording(e.target.value)} />,
      initialValue: prescriptionWording
    },
    {
      name: 'category',
      label: 'category',
      input: <Select options={options} onChange={(e) => setCategory(e)} />,
      rules: [{ required: true }],
      initialValue: category
    },
    {
      label: 'option_wording',
      input: (
        <Switch
          checkedChildren={t('data_bases.stay_prescriptions.form.with_options')}
          unCheckedChildren={t(
            'data_bases.stay_prescriptions.form.without_options'
          )}
          checked={withOptions}
          onChange={onChange}
        />
      )
    }
  ];

  return { fields };
};
