import { useTranslation } from 'react-i18next';
import { Card, Row } from 'antd';
import { useConsultationsContext } from '../ConsultationsContext';
import { PreviousComments } from '../../../components/PreviousComments/PreviousComments';
import { getVisitReasons } from '../../../utils/formatters';

export const ShowEntryNotifications = () => {
  const resource = 'consultations';
  const { t } = useTranslation();
  const { patient } = useConsultationsContext();
  const comment =
    patient?.current_consultation?.entry_notification?.description;
  const visitReasons = patient?.current_consultation?.visit_reasons;

  return (
    <Card>
      <Row>
        <h1>{t(`${resource}.entry_notifications`)}</h1>
      </Row>
      <Row>
        <h3>{t(`${resource}.form.visit_reasons`)}:&nbsp;</h3>{' '}
        {getVisitReasons(visitReasons)}
      </Row>
      <PreviousComments comment={comment} noDate />
    </Card>
  );
};
