import { Input, Form, Select } from 'antd';

const { Option } = Select;

/**
 * Custom hook to generate fields for specialist creation/update form.
 *
 * @returns {Object} An object containing the fields array.
 * @property {Array} fields - An array of field objects for specialist form.
 */
const useSpecialistFields = () => {
  const fields = [
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['second_name'],
      rules: [{ required: true }]
    },
    {
      name: ['speciality'],
      rules: [{ required: true }]
    },
    {
      name: ['secretariat_short_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['secretariat_short_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['secretariat_short_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['doctor_short_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['doctor_short_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['doctor_short_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['doctor_mobile_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['doctor_mobile_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['doctor_mobile_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['email']
    }
  ];

  return {
    fields
  };
};

export default useSpecialistFields;
