import PropTypes from 'prop-types';
import { Row, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { ConstantsAndOtherSurvey } from './SurveyOfConstants/ConstantsAndOtherSurvey';

import useFields from '../fieldsConstantsSurvey';

/**
 * Renders the survey of constants for a patient.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the patient.
 * @returns {JSX.Element} The rendered component.
 */
export const ShowSurveyOfConstants = ({ id }) => {
  const { t } = useTranslation();
  const {
    constantsAndOtherSurveyFields,
    delocalizedBiologyFields,
    urineAnalysisFields
  } = useFields();

  const constantsSurveyProps = [
    {
      title: 'constants_and_other_surveys',
      fields: constantsAndOtherSurveyFields
    },
    {
      title: 'delocalized_biology',
      fields: delocalizedBiologyFields
    },
    {
      title: 'urinary_analysis',
      fields: urineAnalysisFields
    }
  ];

  return (
    <Card>
      <Row justify="start">
        <h1>{t('consultations.form.constants_survey')}</h1>
      </Row>
      {constantsSurveyProps.map(({ title, fields }) => (
        <ConstantsAndOtherSurvey
          key={title}
          title={title}
          fields={fields}
          resource="constants_surveys"
          idPatient={id}
        />
      ))}
    </Card>
  );
};

ShowSurveyOfConstants.propTypes = {
  id: PropTypes.string.isRequired
};
