import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, Button } from 'antd';
import { useState } from 'react';
import { ContentCustom } from '../../../components';
import { LoadingModal } from '../../../components/Modals/LoadingModal';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { usePrescriptionContext } from '../../../contexts/PrescriptionContext/PrescriptionContext';
import { useValidateChoices } from './utils/useValidateChoices';

// Components
import { MedicationCard } from '../components/MedicationCard';
import { FavoritesCard } from '../components/FavoritesCard';
import { FavoriteProtocolsCard } from '../components/FavoriteProtocolsCard';
import { TreeCard } from '../components/TreeCard';
import { ProtocolsCard } from '../components/ProtocolsCard';
import { categoriesKeysTranslated } from './utils/categoriesKeysTranslated';
import { ModalValidateChoices } from './modals/ModalValidateChoices';
import { ModalCreateFavoriteProtocol } from './modals/ModalCreateFavoriteProtocol';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * @component
 * @description Component that displays the prescription choices
 * @returns {JSX.Element} Prescription choices
 */

// TODO WIP
export const PrescriptionChoiseStay = () => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);

  const [openAddFavoriteModal, setOpenAddFavoriteModal] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [checkedKeysData, setCheckedKeysData] = useState([]);
  const [favCheckedKeys, setFavCheckedKeys] = useState([]);

  const { message } = useErrorMessage();
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    doctors,
    patientInfos,
    categories,
    srnCareTreeKeys,
    setSrnCareTreeKeys,
    imagingTreeKeys,
    setImagingTreeKeys,
    biologyTreeKeys,
    setBiologyTreeKeys
  } = usePrescriptionContext();

  const { handleValidateChoices } = useValidateChoices({
    setLoading,
    patientInfos,
    srnCareTreeKeys,
    imagingTreeKeys,
    biologyTreeKeys
  });

  const doctorsSelectOptions = doctors.map((doctor) => ({
    label: `${doctor.first_name} ${doctor.last_name}`,
    value: doctor._id
  }));

  return (
    <ContentCustom>
      <PageHeaderCustom
        title={patientInfos.headerTitle}
        onBack={() => navigate(-1)}
      />
      <Card
        className="prescription-choice-stay-card"
        title={t('prescription.cards.stay_prescription.title')}
        extra={
          <>
            <Button onClick={() => setOpenAddFavoriteModal(true)}>
              {t('buttons.add_to_favorite')}
            </Button>
            <Button
              onClick={() => {
                if (
                  !srnCareTreeKeys.length &&
                  !imagingTreeKeys.length &&
                  !biologyTreeKeys.length
                ) {
                  return message('NO_CHOICES_SELECTED');
                }
                if (
                  user?.position === 'DOCTOR' &&
                  !imagingTreeKeys.some((item) => item.scanner)
                ) {
                  return handleValidateChoices({
                    doctor: user._id
                  });
                }
                setOpenModal(true);
                return null;
              }}
            >
              {t('buttons.validate_prescription_choice')}
            </Button>
          </>
        }
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <FavoriteProtocolsCard
                  className="ant-card"
                  forceRefresh={forceRefresh}
                  setFavCheckedKeys={setFavCheckedKeys}
                />
              </Col>
              <Col span={12}>
                <ProtocolsCard
                  className="ant-card "
                  checkedKeys={checkedKeys}
                  setCheckedKeys={setCheckedKeys}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
              <FavoritesCard setFavCheckedKeys={setFavCheckedKeys} />
            </Row>
            <Row gutter={[16, 16]}>
              <MedicationCard />
            </Row>
            <Row gutter={[16, 16]}>
              <Card>Consultation spécialiste</Card>
            </Row>
          </Col>
          <Col span={12}>
            {categories.map((category) => (
              <Row gutter={[16, 16]} key={category}>
                <TreeCard
                  category={category}
                  treeProps={
                    categoriesKeysTranslated({
                      setSrnCareTreeKeys,
                      setImagingTreeKeys,
                      setBiologyTreeKeys
                    })[category]
                  }
                  checkedKeys={checkedKeys}
                  setCheckedKeys={setCheckedKeys}
                  setCheckedKeysData={setCheckedKeysData}
                  favCheckedKeys={favCheckedKeys}
                />
              </Row>
            ))}
          </Col>
        </Row>
      </Card>
      {imagingTreeKeys.some((item) => item.scanner) && (
        <ModalValidateChoices
          setOpenModal={setOpenModal}
          openModal={openModal}
          handleValidateChoices={handleValidateChoices}
          doctorsSelectOptions={doctorsSelectOptions}
        />
      )}
      <ModalCreateFavoriteProtocol
        setOpenAddFavoriteModal={setOpenAddFavoriteModal}
        checkedKeysData={checkedKeysData}
        setForceRefresh={setForceRefresh}
        openAddFavoriteModal={openAddFavoriteModal}
        imagingTreeKeys={imagingTreeKeys}
        srnCareTreeKeys={srnCareTreeKeys}
        biologyTreeKeys={biologyTreeKeys}
      />
      <LoadingModal
        loading={loading}
        customTitle={t('modals.generate_prescription_title')}
        customMessage={t('modals.generate_document')}
      />
    </ContentCustom>
  );
};
