import { MedicineBoxFilled } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * A component that displays an exit prescriptions card.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.translationKey - The key to be used for translation.
 * @param {Function} props.t - The translation function.
 * @param {string} props.id - The id of the prescription.
 *
 * @returns {JSX.Element} The ExitPrescriptionsCard component.
 */
export const ExitPrescriptionsCard = ({ translationKey, t, id }) => (
  <Col span={8}>
    <Link to={`/prescription/prescription-choice/EXIT/${id}`}>
      <Card bordered={false} style={{ background: '#8AC7FF66' }} hoverable>
        <Row justify="center">
          <MedicineBoxFilled style={{ fontSize: '3em' }} />
        </Row>
        <Row justify="center">
          <span>{t(`${translationKey}.exit`)}</span>
        </Row>
      </Card>
    </Link>
  </Col>
);

ExitPrescriptionsCard.propTypes = {
  translationKey: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};
