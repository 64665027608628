/**
 * Format a patient's name by converting the last name to uppercase
 * and capitalizing the first letter of the first name.
 *
 * @param {string} firstName - The first name of the patient.
 * @param {string} lastName - The last name of the patient.
 * @returns {string} The formatted patient name.
 */
export function formatName(firstName, lastName) {
  const formattedLastName = lastName.toUpperCase();
  const formattedFirstName =
    firstName.charAt(0).toUpperCase() + firstName.slice(1);
  return `${formattedLastName} ${formattedFirstName}`;
}
