import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Tag } from 'antd';
import { ManOutlined, WomanOutlined, FlagFilled } from '@ant-design/icons';
import { ConsultationsContext } from '../../ConsultationsContext';
import { getAge } from '../../../../utils/getAge';
import { formatName } from '../../../home/utils/formatName';
import { Badge } from './Badge';

export const TitleInfos = () => {
  const { patient } = useContext(ConsultationsContext);
  const [convertedAge, setConvertedAge] = useState(null);
  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    birth_date: '',
    gender: '',
    daily_count: null,
    visit_type: null
  });
  const [child, setChild] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (patient) {
      const {
        first_name,
        last_name,
        birth_date,
        gender,
        reported,
        current_consultation
      } = patient || {};
      setData({
        first_name,
        last_name,
        birth_date,
        gender,
        reported,
        daily_count: current_consultation?.daily_count,
        visit_type: current_consultation?.visit_type
      });

      const { age, isChild } = getAge(birth_date);
      const converted = `${age}${t('patients.form.years')}`;
      setChild(isChild);
      setConvertedAge(converted);
    }
  }, [patient]);

  if (
    !data.first_name ||
    !data.last_name ||
    !data.birth_date ||
    !data.gender ||
    !convertedAge
  ) {
    return null;
  }

  return (
    <Row>
      <div style={{ marginRight: '10px' }}>
        <Badge number={data.daily_count} visitType={data.visit_type} />
      </div>
      <h1>{formatName(data.first_name, data.last_name)}</h1>
      <h1>
        &nbsp;
        {child ? (
          <Tag color="yellow">{t('consultations.form.child')}</Tag>
        ) : null}
      </h1>
      <h1>
        &nbsp;
        {data.gender === 'WOMAN' ? (
          <WomanOutlined style={{ fontSize: 18 }} />
        ) : (
          <ManOutlined style={{ fontSize: 18 }} />
        )}{' '}
        {convertedAge}
        &nbsp;
        {data.reported ? <FlagFilled style={{ color: 'red' }} /> : null}
      </h1>
    </Row>
  );
};
