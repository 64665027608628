import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { getPhoneNumber } from '../../utils/formatters';

/**
 * Custom hook that returns an array of columns for a sub centers table.
 *
 * @hook
 * @returns {Array} Array of column objects.
 */
export const useColumns = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [enums, setEnums] = useState([]);

  const getEnums = async () => {
    const { data } = await dispatchAPI('GET', { url: '/sub-centers/enums' });
    setEnums(data);
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return [
    {
      title: t('sub_centers.form.name').toUpperCase(),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('sub_centers.form.address').toUpperCase(),
      key: 'address',
      dataIndex: 'address',
      render: (address) => address || '-'
    },
    {
      title: t('sub_centers.form.type').toUpperCase(),
      key: 'type',
      dataIndex: 'type',
      render: (type) => t(`sub_centers.enums.types.${type}`),
      sorter: true,
      filters: enums?.types?.map((type) => ({
        text: t(`sub_centers.enums.types.${type}`),
        value: type
      }))
    },
    {
      title: t('sub_centers.form.secretariat_phone_number').toUpperCase(),
      key: 'secretariat_phone_number',
      dataIndex: 'secretariat_phone_number',
      render: (secretariat_phone_number) =>
        getPhoneNumber(secretariat_phone_number)
    }
  ];
};
