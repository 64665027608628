import PropTypes from 'prop-types';
import { Line } from '@ant-design/plots';
import { Row } from 'antd';

export const ChartConstant = ({ chartType, data }) => {
  const formattedData = data.map((item) => ({
    date: new Date(item.created_at).toLocaleDateString('fr-FR', {
      hour: '2-digit',
      minute: '2-digit'
    }),
    [chartType]: item[chartType]
  }));

  return (
    <Row justify="center">
      <Line
        data={formattedData}
        xField="date"
        yField={chartType}
        height={400}
        width={800}
      />
    </Row>
  );
};

ChartConstant.propTypes = {
  chartType: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
