import { createContext, useContext, useState } from 'react';

export const ManagerContext = createContext();

/**
 * Provides the ManagerContext to its children components.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} The rendered component.
 */
export const ManagerContextProvider = ({ children }) => {
  const [activeKey, setActiveKey] = useState('specialists');

  return (
    <ManagerContext.Provider value={{ activeKey, setActiveKey }}>
      {children}
    </ManagerContext.Provider>
  );
};

export const useManagerContext = () => useContext(ManagerContext);
