/**
 * Translate tree data
 * @function translateTreeData
 * @param {Array} data - The data to be translated
 * @param {String} translationKey - The translation key
 * @param {Function} t - The translation function
 * @returns {Array} The translated data
 */
export default (data, translationKey, t) =>
  data.map((element) => ({
    ...element,
    title: t(`${translationKey}.${element.title}`)
  }));
