import { Table } from 'antd';
import { useSubCentersColumns } from './SubCentersColumns';
import { useSubCentersActionsColumns } from './SubCentersActionsColumns';

/**
 * Renders a table component for displaying sub centers.
 *
 * @component
 * @param {function} t - i18n translate function.
 * @param {Object} record - The record object containing sub centers data.
 * @param {Array} typeArr - An array of sub center types.
 * @param {Function} archivingSubCenter - The function to archive / unarchive sub center.
 * @returns {JSX.Element} - The rendered table component.
 */
export const subCentersTable = (t, record, typeArr, archivingSubCenter) => (
  <Table
    columns={[
      ...useSubCentersColumns(t, typeArr),
      ...useSubCentersActionsColumns(t, record._id, archivingSubCenter)
    ]}
    dataSource={record.sub_centers}
    pagination={false}
    rowKey={(r) => r._id}
  />
);
