import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Col, Button, DatePicker, Row, Radio } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { usePatientContext } from '../../../../contexts/PatientContext/PatientContext';
import { GetEnums } from '../../../../utils/getEnums';

const { Group } = Radio;

/**
 * Renders the header component with sorter buttons and date picker for the list of patients.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {String} props.resource - The name of the resource. (ex: 'consultations')
 * @param {String} props.status - The status of the consultation. (ex: 'OPEN')
 * @param {Function} props.setStatus - The function to set the status of the consultation.
 * @returns {JSX.Element} The rendered component.
 */
export const SorterButtonsHeader = ({ resource, status, setStatus }) => {
  const { t } = useTranslation();
  const { setDatePicking, datePicking } = usePatientContext();
  const { getEnums } = GetEnums();
  const [enums, setEnums] = useState([]);
  const dateFormat = 'DD/MM/YYYY';
  const isToday = datePicking && dayjs(datePicking).isSame(dayjs(), 'day');

  const getStatus = async () => {
    const enumsStatus = await getEnums(resource);
    setEnums(enumsStatus?.sort_status);
  };

  useEffect(() => {
    (async () => {
      await getStatus();
    })();
  }, []);

  const handleRadioChange = (event) => {
    setStatus(event.target.value);
  };

  const changingDate = (value) => {
    setStatus('ALL');
    setDatePicking(value ? value.toDate() : null);
  };

  const addOneDay = () => {
    setDatePicking(dayjs(datePicking).add(1, 'day').toDate());
  };

  const subtractOneDay = () => {
    setDatePicking(dayjs(datePicking).subtract(1, 'day').toDate());
  };

  return (
    <Row>
      <Col span={24}>
        <Button
          type="primary"
          shape="circle"
          icon={<LeftOutlined />}
          onClick={subtractOneDay}
          style={{ marginRight: 16 }}
        />
        <DatePicker
          format={dateFormat}
          onChange={changingDate}
          value={datePicking && dayjs(datePicking)}
          defaultValue={dayjs(datePicking)}
          allowClear={false}
          panelRender={() => null}
        />
        <Button
          type="primary"
          shape="circle"
          icon={<RightOutlined />}
          onClick={addOneDay}
          style={{ marginLeft: 16 }}
        />
        {isToday && (
          <Group
            buttonStyle="solid"
            onChange={handleRadioChange}
            value={status}
            style={{ marginLeft: 16 }}
          >
            {enums?.map((item) => (
              <Radio.Button key={item} value={item}>
                {t(`patients.form.${item}`)}
              </Radio.Button>
            ))}
          </Group>
        )}
      </Col>
    </Row>
  );
};

SorterButtonsHeader.propTypes = {
  resource: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired
};
