import PropTypes from 'prop-types';
import { useEffect, useState, useContext } from 'react';
import { CreateUpdateContainer } from '../../../components';
import { useFields } from './fields';
import { DataBaseContext } from '../../../contexts/DataBasesContext';
import { useCreateUpdateConfig } from './utils/useCreateUpdateConfig';
import { ListOptions } from './options/ListOptions';

/**
 * Component for creating or updating Stay Prescriptions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the form (create/update).
 * @returns {JSX.Element} JSX element representing the CreateUpdateStayPrescriptions component.
 */
export const CreateUpdateStayPrescriptions = ({ purpose }) => {
  const [options, setOptions] = useState([]);

  const {
    prescriptionWording,
    setPrescriptionWording,
    category,
    setCategory,
    options: optimistOptions,
    setPurposeOptions
  } = useContext(DataBaseContext);

  const [withOptions, setWithOptions] = useState(
    !!options.length || !!optimistOptions.length
  );

  const { fields } = useFields({
    prescriptionWording,
    setPrescriptionWording,
    category,
    setCategory,
    withOptions,
    setWithOptions,
    purpose
  });

  const { config } = useCreateUpdateConfig({
    prescriptionWording,
    category,
    setPrescriptionWording,
    setCategory,
    setOptions,
    setWithOptions,
    options,
    optimistOptions
  });

  useEffect(() => {
    setPurposeOptions(purpose === 'create' ? 'create' : purpose);
  }, [purpose]);

  return (
    <CreateUpdateContainer
      fields={fields}
      purpose={purpose === 'create' ? 'create' : purpose}
      config={config}
      baseUrl="config-stay-prescriptions"
      resource="data_bases.stay_prescriptions"
      formExtra={
        <ListOptions
          options={options}
          setOptions={setOptions}
          withOptions={withOptions}
          prescriptionWording={prescriptionWording}
          category={category}
          purpose={purpose}
        />
      }
    />
  );
};

CreateUpdateStayPrescriptions.propTypes = {
  purpose: PropTypes.string.isRequired
};
