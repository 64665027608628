import { Button, Divider, Form, Modal, Row, Space } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useGenerateFormItem } from '../../../../../utils/generateFormItem';
import { useDocumentSubmit } from '../utils/useDocumentSubmit';
import { LoadingModal } from '../../../../../components/Modals/LoadingModal';

/**
 * A component that displays a modal with a print button.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.openModal - A boolean indicating whether the modal is open.
 * @param {Function} props.setOpenModal - Function to set the open state of the modal.
 * @param {Array} props.fields - The fields to be printed.
 * @param {string} props.documentType - The type of the document to be printed.
 * @param {string} props.documentWording - The wording of the document to be printed.
 *
 * @returns {JSX.Element} The PrintButtonModal component.
 */
export const PrintButtonModal = ({
  openModal,
  setOpenModal,
  fields,
  documentType,
  documentWording,
  patientId
}) => {
  const [form] = Form.useForm();
  const generateFormItems = useGenerateFormItem();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { handleSubmit } = useDocumentSubmit({
    setOpenModal,
    documentType,
    documentWording,
    setLoading,
    patientId
  });

  return (
    <Modal open={openModal} closable={false} footer={null}>
      <Form form={form} onFinish={handleSubmit}>
        {fields?.map((field) =>
          generateFormItems('consultations.documents', field)
        )}
        <Row justify="end">
          <Divider />
          <Space>
            <Button onClick={() => setOpenModal(false)}>
              {t('buttons.cancel')}
            </Button>
            <Button htmlType="submit">{t('buttons.print')}</Button>
          </Space>
        </Row>
      </Form>
      <LoadingModal
        loading={loading}
        customMessage={t('modals.generate_document')}
        customTitle={t('modals.generate_recommendation_title')}
      />
    </Modal>
  );
};

PrintButtonModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  documentType: PropTypes.string.isRequired,
  documentWording: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired
};
