import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { Card, Row } from 'antd';

/**
 * Renders a card component displaying previous comments.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.written_by - The name of the person who wrote the comment.
 * @param {string} props.comment - The content of the comment.
 * @param {string} props.date - The date of the comment.
 * @param {boolean} props.nodate - Determines whether to display the date or not.
 * @returns {JSX.Element} The rendered card component.
 */
export const PreviousComments = ({ written_by, comment, date, nodate }) => {
  const [newDate, setNewDate] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const displayedDate = dayjs(date).format('DD/MM/YYYY');
    setNewDate(displayedDate);
  }, []);

  return (
    <Card>
      {nodate && (
        <Row>{t('patients.form.header_message', { newDate, written_by })}</Row>
      )}
      <Row>
        <h3>{comment}</h3>
      </Row>
    </Card>
  );
};
PreviousComments.propTypes = {
  written_by: PropTypes.string,
  comment: PropTypes.string,
  date: PropTypes.string,
  nodate: PropTypes.bool
};
PreviousComments.defaultProps = {
  written_by: undefined,
  comment: undefined,
  date: undefined,
  nodate: false
};
