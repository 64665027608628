// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useReasonColumn } from './reasonColumn';
import { useDownloadColumn } from './downloadColumn';
import { usePrintColumn } from './printColumn';

/**
 * @hook
 * @name useColumnsStayPrescriptions
 * @description A custom hook to return columns for stay prescriptions table
 * @param {Boolean} isSwitched - A boolean to check if the table is in switched mode
 * @returns {Array} columns
 */
export const useColumnsStayPrescriptions = (isSwitched) => {
  const { t } = useTranslation();
  const reasonColumn = useReasonColumn();
  const downloadColumn = useDownloadColumn();
  const printColumn = usePrintColumn();
  const formatDate = (date) => dayjs(date).format('DD-MM-YYYY');

  const dateColumn = {
    title: t('consultations.date'),
    key: 'created_at',
    dataIndex: 'created_at',
    sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    render: (prescription_date) =>
      prescription_date ? formatDate(prescription_date) : '-'
  };

  const treatmentsExaminationsPrescribedColumn = {
    title: t('consultations.documents.treatments_examinations_prescribed'),
    key: 'prescribed_treatments',
    dataIndex: 'prescribed_treatments',
    sorter: (a, b) => {
      if (
        a.prescribed_treatments &&
        a.prescribed_treatments.length > 0 &&
        b.prescribed_treatments &&
        b.prescribed_treatments.length > 0
      ) {
        return (
          b.prescribed_treatments[0].length - a.prescribed_treatments[0].length
        );
      }
      return 0;
    }
  };

  return isSwitched
    ? [
        reasonColumn,
        dateColumn,
        treatmentsExaminationsPrescribedColumn,
        downloadColumn,
        printColumn
      ]
    : [
        dateColumn,
        reasonColumn,
        treatmentsExaminationsPrescribedColumn,
        downloadColumn,
        printColumn
      ];
};
