import { createGlobalStyle } from 'styled-components';
import '../SCSS/user/index.scss';

export const UserTheme = createGlobalStyle`
  body {
    --clientColor: #fff;
    --primaryColor: #004A8F;
    --addColor: #47d267;
    --itemHoverColor: var(--primaryColor);
    --componentBackground: #fff;
    --secondaryHover: var(--componentBackground);
    --subMenuBackground: var(--componentBackground);
    --menuDropdownBackground: var(--componentBackground);
    --pickerDropdown: var(--componentBackground);
    --primaryHover:  #e6f7ff;
    --secondaryColor: #8AC7FF;
    --disabledColor: #bfbfbf;
    --bodyBackground: linear-gradient(130deg, #FFF 0%, rgba(214, 234, 253, 0.10) 32.02%, rgba(214, 234, 253, 0.20) 60.51%, #FFF 100%), #FCFCFC;
    --textColor: #383838;
    --textColorSecondary: rgba(0, 0, 0, 0.45);
    --itemActiveBackground: #e6f7ff;
    --itemHoverBackground: #EEF7FF;
    --borderColor: #d9d9d9;
    --contrastBackground: #f5f5f5;
    --opacitySVG: 1;
    --primaryColorA04: #FFB94A;
    --borderColorA06: rgba(0, 74, 143, 0.50);
    --errorColor: #EC3E40;
    --primaryColorA07
    --primaryContrast
    --tabsGray
    --textColorInvert
    --textColorHover
    --textColorA015
    --textColorA075
    --disabledColorA04
    --disabledColorA06
    --itemActiveColor
    --logo
    --logoSmall
    --error100
    --errorA08
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: var(--contrastBackground);
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: var(--borderColor);
  }
`;
