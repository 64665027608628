import { BaseModel } from './BaseModel';

/**
 * Represents a Protocol model.
 * @class
 * @extends BaseModel
 */
export class Protocol extends BaseModel {
  /**
   * Creates an instance of the Protocol model.
   * @constructor
   * @param {Object} data - The data for the Protocol.
   */
  constructor(data) {
    super('protocols', undefined, data);
  }

  /**
   * The name of the model.
   * @static
   * @type {string}
   */
  static modelName = 'protocols';

  /**
   * Retrieves all protocols.
   * @static
   * @function getAll
   * @param {Object} params - Additional parameters for the API request.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static getAll({ ...params } = {}) {
    return BaseModel.fetchAPI('GET', `${Protocol.modelName}`, {}, params);
  }

  /**
   * Retrieves favorite protocols.
   * @static
   * @function getFavoritesProtocols
   * @param {Object} params - Additional parameters for the API request.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static getFavoritesProtocols({ ...params } = {}) {
    return BaseModel.fetchAPI(
      'GET',
      `${Protocol.modelName}/favorites`,
      {},
      params
    );
  }

  /**
   * Retrieves a protocol by its ID.
   * @static
   * @function getOneById
   * @param {string} id - The ID of the protocol.
   * @param {Object} params - Additional parameters for the API request.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static getOneById(id, { ...params } = {}) {
    return BaseModel.fetchAPI('GET', `${Protocol.modelName}/${id}`, {}, params);
  }

  /**
   * Creates a new protocol.
   * @static
   * @function create
   * @param {Object} data - The data for the new protocol.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static create(data) {
    return BaseModel.fetchAPI('POST', `${Protocol.modelName}`, data);
  }

  /**
   * Creates a new favorite protocol.
   * @static
   * @function createFavorite
   * @param {Object} data - The data for the new favorite protocol.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static createFavorite(data) {
    return BaseModel.fetchAPI('POST', `${Protocol.modelName}/favorites`, data);
  }

  /**
   * Updates a protocol by its ID.
   * @static
   * @function update
   * @param {string} id - The ID of the protocol to update.
   * @param {Object} data - The updated data for the protocol.
   * @param {Object} params - Additional parameters for the API request.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static update(id, data, { ...params } = {}) {
    return BaseModel.fetchAPI(
      'PATCH',
      `${Protocol.modelName}/${id}`,
      data,
      params
    );
  }
}
