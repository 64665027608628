/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import { usePrescriptionContext } from '../../contexts/PrescriptionContext/PrescriptionContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { getSynapseToken } from '../../utils/synapse';

/**
 * This component renders the Synapse StartStopp widget.
 * @component SynapsePosologyCheckerWidget
 * @returns {JSX.Element} Synapse StartStopp widget
 */
export const SynapseStartStoppWidget = () => {
  const { entities, patientAge } = usePrescriptionContext();
  const [JWT, setJWT] = useState(null);
  const [startStoppWidget, setStartStoppWidget] = useState(null);

  const { dispatchAPI } = useAuthContext();

  const fetchNewToken = async () => {
    const token = await getSynapseToken(dispatchAPI);
    setJWT(token);
  };

  const createDataStartStopp = () => ({
    entities,
    profile: { age: patientAge }
  });

  useEffect(() => {
    if (!startStoppWidget) return;

    const dataStartStopp = createDataStartStopp();
    startStoppWidget.update(dataStartStopp);
  }, [entities, startStoppWidget]);

  useEffect(() => {
    if (!JWT) {
      fetchNewToken();
      return;
    }

    const dataStartStopp = createDataStartStopp();
    const newStartStoppWidget = new window.Synapse.StartStoppWidget(JWT);
    newStartStoppWidget.init(
      document.getElementById('start-stopp-container'),
      dataStartStopp
    );

    newStartStoppWidget.setCallback('hasResults', (hasResults, maxLevel) => {
      // Handle results
    });

    newStartStoppWidget.setCallback('onTokenExpiration', (err, data) => {
      const newToken = fetchNewToken();
      newStartStoppWidget.setToken(newToken);
    });

    setStartStoppWidget(newStartStoppWidget);
  }, [JWT]);

  return (
    <Row justify="center">
      <Col span={14}>
        <Card id="start-stopp-container" />
      </Col>
    </Row>
  );
};
