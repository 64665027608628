import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { Prescription } from '../../../../models/Prescription';
import { useDownloadDocument } from '../../../../utils/downloadDoc';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * Custom hook for validating choices in a prescription stay.
 * @hook
 *
 * @param {Object} options - The options for the hook.
 * @param {Function} options.setLoading - The function to set the loading state.
 * @param {Array} options.imagingTreeKeys - The array of imaging tree keys.
 * @param {Array} options.srnCareTreeKeys - The array of srn care tree keys.
 * @param {Array} options.biologyTreeKeys - The array of biology tree keys.
 * @param {Object} options.patientInfos - The patient information object.
 * @returns {Object} - The object containing the handleValidateChoices function.
 */
export const useValidateChoices = ({
  setLoading,
  imagingTreeKeys,
  srnCareTreeKeys,
  biologyTreeKeys,
  patientInfos
}) => {
  const { user } = useAuthContext();
  const { printMultipleDocuments } = useDownloadDocument();
  const { message } = useErrorMessage();
  const navigate = useNavigate();

  /**
   * Handles the validation of choices for a prescription stay.
   * @function
   *
   * @param {Object} values - The values to be validated.
   * @returns {Promise<void>} - A promise that resolves when the validation is complete.
   */
  const handleValidateChoices = async (values) => {
    try {
      setLoading(true);
      const { doctor, ...rest } = values;

      const updatedImagingTreeKeys = imagingTreeKeys.map((item) => {
        const value = rest[item.key];
        if (value !== undefined) {
          return {
            ...item,
            free_text: value
          };
        }
        return item;
      });

      const body = {
        srnCareTreeKeys,
        updatedImagingTreeKeys,
        biologyTreeKeys,
        patient: patientInfos.infos._id,
        author: user._id,
        doctor: doctor || user._id,
        consultation: patientInfos?.infos?.current_consultation?._id
      };

      const { data: prescriptionsIds } =
        await Prescription.postStayPrescriptionChoices(body);

      setTimeout(async () => {
        await printMultipleDocuments(prescriptionsIds);
        navigate(-1);
      }, 2000);

      setLoading(false);
    } catch (error) {
      message(error);
    } finally {
      setLoading(false);
    }
  };

  return { handleValidateChoices };
};
