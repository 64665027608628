import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { newBodyFormatter } from './formattingDatasToDB';

/**
 * Custom hook for handling create and update API calls.
 *
 * @hook
 * @param {string} resource - The resource URL.
 * @param {string} id - The ID of the resource (for update).
 * @returns {object} - An object containing the `isSubmitting` state and the `handleSubmit` function.
 */
export const useCreateUpdateApiCalls = (resource, id) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();

  /**
   * Creates a new patient.
   *
   * @function
   * @async
   * @param {Object} body - The request body for creating the resource.
   * @param {boolean} createConsultation - A boolean indicating whether to create a consultation after creating the patient.
   * @returns {Promise<void>} - A promise that resolves when the resource is created.
   */
  const createResource = async (body, createConsultation) => {
    setIsSubmitting(true);
    const formattedBody = newBodyFormatter(body);
    try {
      const { data: newPatientId } = await dispatchAPI('POST', {
        url: `${resource}`,
        body: {
          ...formattedBody,
          associated_center: user.associated_center || user.selected_sub_center,
          written_by: `${user.first_name} ${user.last_name}`
        }
      });

      navigate(
        createConsultation === 'true'
          ? `/consultations/create/${newPatientId}`
          : -1
      );
    } catch (e) {
      message(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  /**
   * Updates an existing patient.
   *
   * @function
   * @async
   * @param {Object} body - The request body for updating the resource.
   * @param {boolean} createConsultation - A boolean indicating whether to create a consultation after updating the patient.
   * @returns {Promise<void>} - A promise that resolves when the resource is updated.
   */
  const updateResource = async (body, createConsultation) => {
    const formatedBody = newBodyFormatter(body);
    setIsSubmitting(true);

    try {
      await dispatchAPI('PATCH', {
        url: `${resource}/${id}`,
        body: {
          ...formatedBody,
          associated_center: user.associated_center,
          written_by: `${user.first_name} ${user.last_name}`
        }
      });

      setIsSubmitting(false);
      navigate(
        createConsultation === 'true' ? `/consultations/create/${id}` : -1
      );
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  /**
   * Handles the form submission.
   *
   * @function
   * @async
   * @param {Object} values - The form values.
   * @param {string} purpose - The purpose of the form (e.g., 'edit' or 'create').
   * @param {boolean} createConsultation - A boolean indicating whether to create a consultation after creating the patient.
   * @returns {void}
   */
  const handleSubmit = async (values, purpose, createConsultation) => {
    if (purpose === 'edit') await updateResource(values, createConsultation);
    if (purpose === 'create') await createResource(values, createConsultation);
  };

  return { isSubmitting, handleSubmit };
};
