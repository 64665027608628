import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { VisitReasonsTransfer } from './treeTransfer/VisitReasonsTranfer';
import { PrescriptionsTranfer } from './treeTransfer/PrescriptionsTranfer';
import { ProtocolMethods } from '../ProtocolMethods';

/**
 * Extra form component for protocols.
 * @component
 * @param {Object} props - The component props.
 * @param {string[]} props.visitReasonsData - Array of visit reasons data.
 * @param {Function} props.setVisitReasonsData - Function to set visit reasons data.
 * @param {string[]} props.prescriptionsData - Array of prescriptions data.
 * @param {Function} props.setPrescriptionsData - Function to set prescriptions data.
 * @returns {JSX.Element} JSX.Element.
 */
export const FormExtra = ({
  visitReasonsData,
  setVisitReasonsData,
  prescriptionsData,
  setPrescriptionsData
}) => {
  const translationKey = 'data_bases.protocols.form';
  const { t } = useTranslation();
  const { getCategories, getPrescriptions } = ProtocolMethods();
  const [categories, setCategories] = useState([]);
  const [stayPrescriptions, setStayPrescriptions] = useState([]);

  useEffect(() => {
    (async () => {
      await getCategories(setCategories);
      await getPrescriptions(setStayPrescriptions);
    })();
  }, []);

  return (
    <>
      <Typography.Title level={5}>
        {t(`${translationKey}.visit_reason`)}
      </Typography.Title>
      <VisitReasonsTransfer
        targetKeys={visitReasonsData}
        setTargetKeys={setVisitReasonsData}
        categories={categories}
      />
      <Typography.Title level={5}>
        {t(`${translationKey}.prescriptions_to_preselect`)}
      </Typography.Title>
      <PrescriptionsTranfer
        targetKeys={prescriptionsData}
        setTargetKeys={setPrescriptionsData}
        stayPrescriptions={stayPrescriptions}
      />
    </>
  );
};

FormExtra.propTypes = {
  visitReasonsData: PropTypes.arrayOf(PropTypes.string).isRequired,
  setVisitReasonsData: PropTypes.func.isRequired,
  prescriptionsData: PropTypes.arrayOf(PropTypes.string).isRequired,
  setPrescriptionsData: PropTypes.func.isRequired
};
