/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { usePrescriptionContext } from '../../contexts/PrescriptionContext/PrescriptionContext';
import { getSynapseToken } from '../../utils/synapse';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * This component renders the Synapse Interaction widget.
 * @component SynapsePosologyCheckerWidget
 * @returns {JSX.Element} Synapse Interaction widget
 */
export const SynapseInteractionWidget = () => {
  const { entities, patientInfos } = usePrescriptionContext();
  const [JWT, setJWT] = useState(null);
  const [interactionWidget, setInteractionWidget] = useState(null);
  const { dispatchAPI } = useAuthContext();

  const fetchNewToken = async () => {
    const token = await getSynapseToken(dispatchAPI);
    setJWT(token);
  };

  const createDataInteraction = () => ({
    entities,
    profile: patientInfos?.infos
  });

  useEffect(() => {
    if (!interactionWidget) return;

    const dataInteraction = createDataInteraction();
    interactionWidget.update(dataInteraction);
  }, [entities, interactionWidget]);

  useEffect(() => {
    if (!JWT) {
      fetchNewToken();
      return;
    }
    const dataInteraction = createDataInteraction();
    const newInteractionWidget = new window.Synapse.InteractionWidget(JWT);
    newInteractionWidget.init(
      document.getElementById('interaction-container'),
      dataInteraction
    );

    newInteractionWidget.setCallback('hasResults', (hasResults, maxLevel) => {
      // Handle results
    });

    newInteractionWidget.setCallback('onTokenExpiration', (err, data) => {
      fetchNewToken();
    });

    setInteractionWidget(newInteractionWidget);
  }, [JWT]);

  return <Card id="interaction-container" />;
};
