import React from 'react';
import PropTypes from 'prop-types';
import { ListResource, ContentCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useColumns } from './columns';

/**
 * Renders a component for listing directory entries.
 * This component utilizes a ListResource component for displaying directory data.
 *
 * @component
 *
 * @param {object} props Component props.
 * @param {boolean} [props.withContentCustom=false] Determines if the content is custom.
 * @param {boolean} [props.isPageHeaderCustom=true] Determines if the page header is custom.
 * @param {boolean} [props.withCreateButton=null] Determines if the create button is displayed.
 * @param {boolean} [props.customEdit=false] Determines if the edit button is custom.
 * @returns {JSX.Element} The rendered component for listing directory entries.
 */
export const ListDirectory = ({
  withContentCustom,
  isPageHeaderCustom,
  withCreateButton,
  customEdit
}) => {
  const { user } = useAuthContext();
  const centerId = user.associated_center._id;
  const extraQuery = `center=${centerId}`;
  const columns = useColumns();

  const ListComponent = (
    <ListResource
      resourceName="directories"
      resourceModelName="Directory"
      columns={columns}
      extraQuery={extraQuery}
      showAction={false}
      withArchiveButton={false}
      archiveAction
      isPageHeaderCustom={isPageHeaderCustom}
      withUploadButton={false}
      withCreateButton={withCreateButton ? { path: 'directory/create' } : true}
      path={customEdit ? 'directory' : null}
    />
  );

  return (
    <>
      {withContentCustom && <ContentCustom>{ListComponent}</ContentCustom>}
      {!withContentCustom && ListComponent}
    </>
  );
};

ListDirectory.propTypes = {
  withContentCustom: PropTypes.bool,
  isPageHeaderCustom: PropTypes.bool,
  withCreateButton: PropTypes.bool,
  customEdit: PropTypes.bool
};

ListDirectory.defaultProps = {
  withContentCustom: false,
  isPageHeaderCustom: true,
  withCreateButton: null,
  customEdit: false
};
