import { Card, Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

export const ShowPricing = () => {
  const { t } = useTranslation();
  const resource = 'consultations';
  return (
    <Card>
      <Row justify="start">
        <h1>{t(`${resource}.form.pricing`)}</h1>
      </Row>
      <Row>
        <Col span={12}>
          <Row justify="center">{t(`${resource}.form.medical`)}</Row>
          <Button
            style={{
              borderRadius: '15px',
              margin: '2px'
            }}
          >
            {t(`${resource}.form.new_care_sheet`)}
          </Button>
        </Col>

        <Col span={12}>
          <Row justify="center">{t(`${resource}.form.paramedic`)}</Row>
          <Button
            style={{
              borderRadius: '15px',
              margin: '2px'
            }}
          >
            {t(`${resource}.form.new_care_sheet`)}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};
