import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

/**
 * A utility hook that displays error messages using Ant Design's notification component.
 * @hook
 * @returns {Object} An object with `message` and `success` functions.
 */
export const useErrorMessage = () => {
  const { t } = useTranslation();
  return {
    message: (
      status = '404',
      duration = 4.5,
      key = undefined,
      notificationType = 'error'
    ) => {
      const s = status?.response?.status || status;
      const d = status?.response?.data?.description;
      notification[notificationType]({
        message: t(`errors.message.${d || s}`),
        key: key || 'network_feedback',
        duration
      });
    }
  };
};
