import { Input } from 'antd';

export const useDoctorFields = () => {
  const doctorFields = [
    {
      name: ['rpps_number'],
      rules: [{ required: true }],
      input: <Input type="number" />
    }
  ];

  return { doctorFields };
};
