import { MailOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

// work in progress
export const DoctorsLetter = () => {
  // eslint-disable-next-line no-unused-vars
  let test;

  return (
    <Tooltip title="Courrier du médecin traitant">
      <MailOutlined />
    </Tooltip>
  );
};
