import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Popover, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';
import { usePrescriptionContext } from '../../contexts/PrescriptionContext/PrescriptionContext';
import { DrugsList } from '../prescription/MedicationPrescription/DrugsList';

// Synapse components
import { SynapseAutocompleteWidget } from '../../components/Synapse/SynapseAutoCompleteWidget';
import { SynapsePosologyCheckerWidget } from '../../components/Synapse/SynapsePosologyCheckerWidget';
import { SynapseAdverseEffectWidget } from '../../components/Synapse/SynapseAdverseEffectWidget';
import { SynapseInteractionWidget } from '../../components/Synapse/SynapseInteractionWidget';
import { SynapsePrescriptionSummaryWidget } from '../../components/Synapse/SynapsePrescriptionSummaryWidget';

/**
 * @component CreateUpdateFavoriteMedicationPrescription
 * @returns {React.Component} Page to create a favorite medication prescription
 */
export const CreateUpdateFavoriteMedicationPrescription = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { type } = useParams();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { posologies } = usePrescriptionContext();

  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState(null);
  const translationKey = 'prescription.favorites.form';

  const postPrescription = async () => {
    if (posologies.length > 0) {
      try {
        setLoading(true);

        const body = {
          purpose: 'MEDICATION',
          author: user._id,
          authorized: [user._id],
          type,
          label,
          posologies
        };

        await dispatchAPI('POST', {
          url: '/prescriptions/favorites',
          body
        });

        navigate(-1);
      } catch (error) {
        message(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <ContentCustom>
      <PageHeaderCustom title={t(`${translationKey}.titles.${type}`)} />
      <Row style={{ flexDirection: 'column', gap: 16 }}>
        <Row justify="center" gutter={24}>
          <Col span={24}>
            <Input
              placeholder={t(`${translationKey}.label`)}
              onChange={(e) => setLabel(e.target.value)}
            />
          </Col>
          <Col span={12}>
            <SynapseAutocompleteWidget />
          </Col>
          <Col span={12}>
            <SynapsePrescriptionSummaryWidget />
          </Col>
        </Row>
        <Row justify="center">
          <DrugsList />
        </Row>
        <Row justify="center" gutter={24}>
          <Col span={24}>
            <SynapsePosologyCheckerWidget />
          </Col>
        </Row>
        <Row justify="center" gutter={24}>
          <Col span={12}>
            <SynapseAdverseEffectWidget />
          </Col>
          <Col span={12}>
            <SynapseInteractionWidget />
          </Col>
        </Row>
      </Row>
      <Row justify="center">
        <Popover
          content={t('warnings.message.empty_posology_or_label')}
          trigger={posologies.length < 1 || !label ? 'hover' : 'none'}
        >
          <Button
            loading={loading}
            disabled={posologies.length < 1 || !label || loading}
            type="primary"
            onClick={() => postPrescription()}
          >
            {t('buttons.validate')}
          </Button>
        </Popover>
      </Row>
    </ContentCustom>
  );
};
