import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowRightOutlined } from '@ant-design/icons';
import { usePatientMethods } from '../../methods';

/**
 * ExitButton component.
 * Renders a button to exit a patient.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.record - The patient record.
 * @param {string} props.record._id - The ID of the patient.
 * @returns {JSX.Element} The ExitButton component.
 */
export const ExitButton = ({ record }) => {
  const { _id } = record;
  const { t } = useTranslation();
  const { exitPatient } = usePatientMethods();

  const handleClick = async () => {
    await exitPatient(_id);
  };

  return (
    <Button
      style={{
        borderRadius: '15px'
      }}
      icon={<ArrowRightOutlined />}
      onClick={handleClick}
    >
      {t('patients.form.patient_exit_button')}
    </Button>
  );
};

ExitButton.propTypes = {
  record: PropTypes.shape({
    _id: PropTypes.string
  })
};

ExitButton.defaultProps = {
  record: {
    _id: ''
  }
};
