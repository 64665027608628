import PropTypes from 'prop-types';
import { Table } from 'antd';
import { useColumnsPhotos } from '../columns/columnsPhotos';
import { SorterDateReason } from '../../../Components/SorterDateReason';
import { useSwitchState } from '../../../Components/switchState';

/**
 * @component PhotosTable
 * @description A custom table component for displaying photos
 * @param {Array} data - array of photos
 * @param {Function} onChange - function to handle table change
 * @returns {JSX} - JSX component
 */
export const PhotosTable = ({ data, onChange }) => {
  const { isSwitched, handleSwitchChange } = useSwitchState();
  const columns = useColumnsPhotos(isSwitched, data);

  return (
    <>
      <SorterDateReason handleSwitchChange={handleSwitchChange} />
      <Table
        rowKey={(record) => record?._id}
        columns={columns}
        dataSource={data}
        onChange={onChange}
      />
    </>
  );
};

PhotosTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired
};
