import PropTypes from 'prop-types';
import { Col } from 'antd';
import { CardsDisplay } from './CardsDisplay';

/**
 * Renders a component that displays the patient cards.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.fieldsId - The fields for the identity card.
 * @param {Array} props.fieldsInsurance - The fields for the health mutual card.
 * @returns {JSX.Element} The JSX element representing the CardPatientCards component.
 */
export const CardPatientCards = ({ fieldsId, fieldsInsurance }) => {
  const keys = ['identity_card', 'health_mutual_card'];

  const cardStyle = {
    marginbottom: '16px'
  };
  return (
    <Col>
      {keys.map((key) => (
        <CardsDisplay
          fields={key === 'identity_card' ? fieldsId : fieldsInsurance}
          resource="patients"
          translationKey={key}
          key={key}
          style={cardStyle}
        />
      ))}
    </Col>
  );
};

CardPatientCards.propTypes = {
  fieldsId: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })),
  fieldsInsurance: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string })
  )
};

CardPatientCards.defaultProps = {
  fieldsId: undefined,
  fieldsInsurance: undefined
};
