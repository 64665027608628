import { Link } from 'react-router-dom';

export const useListContent = (data = {}) => {
  const { secretariat_phone_number, name, address, manager, provider } = data;

  const labels = [
    {
      label: 'centers.form.name',
      span: 1,
      content: name || '-'
    },
    {
      label: 'centers.form.address',
      span: 1,
      content: address || '-'
    },
    {
      label: 'users.form.phone_number_full',
      span: 1,
      content:
        secretariat_phone_number && secretariat_phone_number.number ? (
          <div>
            {`${
              secretariat_phone_number.country_code
            } ${secretariat_phone_number.number.slice(
              0,
              1
            )} ${secretariat_phone_number.number
              .slice(1)
              .replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: 'centers.form.manager',
      span: 1,
      content: manager ? (
        <Link
          to={`/users/show/${manager._id}`}
        >{`${manager.last_name} ${manager.first_name}`}</Link>
      ) : (
        '-'
      )
    },
    {
      label: 'centers.form.manager_email',
      span: 1,
      content: manager?.email ? manager.email : '-'
    },
    {
      label: 'centers.form.manager_phone',
      span: 1,
      content: manager?.phone_number ? (
        <div>
          {`${
            manager.phone_number.country_code
          } ${manager.phone_number.number.slice(
            0,
            1
          )} ${manager.phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                    `}
        </div>
      ) : (
        '-'
      )
    },
    {
      label: 'centers.form.provider',
      span: 1,
      content: provider || '-'
    }
  ];

  return labels;
};
