import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import SignaturePad from 'react-signature-canvas';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * SignatureCanvas component.
 * This component is used to capture a signature from the user.
 *
 * @component
 * @param {Function} handleFinish - Callback function to handle finishing the signature.
 * @param {Function} [handleCancel] - Callback function to handle canceling the signature.
 * @param {number|string} [canvasWidth=425] - Width of the signature canvas.
 * @param {number|string} [canvasHeight=200] - Height of the signature canvas.
 * @param {Object} [wrapperStyle] - Additional styles to apply to the wrapper element.
 * @returns {JSX.Element} SignatureCanvas component.
 */
export const SignatureCanvas = ({
  handleFinish,
  handleCancel,
  canvasWidth,
  canvasHeight,
  wrapperStyle
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [saved, setSaved] = useState(false);
  const sigCanvas = useRef({});

  const onCancel = () => {
    sigCanvas.current.clear();
    setSaved(false);
    if (handleCancel) handleCancel();
  };

  const onFinish = () => {
    if (sigCanvas.current.isEmpty()) {
      return message('empty_signature', 4.5, 'signature');
    }
    const image = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');

    setSaved(true);
    return handleFinish(image);
  };

  return (
    <>
      <Row
        gutter={4}
        style={{
          border: '1px solid #2b2b2b',
          width: canvasWidth,
          height: canvasHeight,
          ...wrapperStyle
        }}
      >
        <SignaturePad
          canvasProps={{
            width: canvasWidth,
            height: canvasHeight,
            className: 'signature-canvas'
          }}
          ref={sigCanvas}
        />
      </Row>
      <Row
        justify="end"
        gutter={4}
        style={{
          marginTop: 16,
          width: canvasWidth
        }}
      >
        <Col>
          <Button
            type="link"
            danger
            icon={<CloseOutlined />}
            onClick={onCancel}
          >
            {t('buttons.start_over')}
          </Button>
        </Col>
        <Col>
          <Button
            disabled={saved}
            type="add"
            onClick={onFinish}
            icon={<CheckOutlined />}
          >
            {saved ? t('buttons.signature_saved') : t('buttons.save_signature')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

SignatureCanvas.propTypes = {
  handleFinish: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  canvasWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  canvasHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  wrapperStyle: PropTypes.shape({})
};

SignatureCanvas.defaultProps = {
  handleCancel: undefined,
  canvasWidth: 425,
  canvasHeight: 200,
  wrapperStyle: undefined
};
