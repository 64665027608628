import React from 'react';
import PropTypes from 'prop-types';
import { ListResource } from '../../components';
import { useColumns } from './columns';

/**
 * Component to display a list of specialists.
 * This component renders a ListResource component configured with specialist columns.
 *
 * @component
 * @returns {JSX.Element} ListSpecialist component.
 */
export const ListSpecialist = ({ withCreateButton, customEdit }) => {
  const resource = 'specialists';
  const resourceModelName = 'Specialist';
  const columns = useColumns();

  return (
    <ListResource
      resourceName={`${resource}`}
      resourceModelName={resourceModelName}
      columns={columns}
      showAction={false}
      withArchiveButton={false}
      isPageHeaderCustom={false}
      archiveAction
      withCreateButton={
        withCreateButton
          ? {
              path: 'specialists/create'
            }
          : true
      }
      path={customEdit ? 'specialists' : null}
    />
  );
};

ListSpecialist.propTypes = {
  withCreateButton: PropTypes.bool,
  customEdit: PropTypes.bool
};

ListSpecialist.defaultProps = {
  withCreateButton: null,
  customEdit: false
};
