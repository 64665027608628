import { useTranslation } from 'react-i18next';
import { Popconfirm, Divider, Button } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from './icons';

/**
 * Custom hook that returns the columns configuration for the waiting rooms table.
 *
 * @hook
 * @param {Object} options - Options for the columns configuration.
 * @param {boolean} options.isEditing - Flag indicating if the table is in editing mode.
 * @param {Function} options.save - Function to save the changes made to a record.
 * @param {Function} options.edit - Function to enter editing mode for a record.
 * @param {Function} options.cancel - Function to cancel editing mode.
 * @param {Function} options.del - Function to delete a record.
 * @returns {Array} - The columns configuration for the waiting rooms table.
 */
export const useColumns = ({ isEditing, save, edit, cancel, del }) => {
  const { t } = useTranslation();
  const iconSize = 18;

  return [
    {
      title: t('waiting_room.form.title').toUpperCase(),
      key: 'title',
      dataIndex: 'title',
      editable: true
    },
    {
      title: t('waiting_room.form.icon').toUpperCase(),
      key: 'icon',
      dataIndex: 'icon',
      editable: true,
      render: (icon) =>
        icon ? (
          <FontAwesomeIcon
            icon={icons.find((option) => option.value === icon).icon}
          />
        ) : null
    },
    {
      title: t('waiting_room.form.type').toUpperCase(),
      key: 'type',
      dataIndex: 'type',
      editable: true,
      render: (type) => t(`waiting_room.types.${type}`)
    },
    {
      key: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="link"
              onClick={() => {
                save(record._id);
              }}
              style={{
                marginRight: 8
              }}
            >
              {t('buttons.save')}
            </Button>
            <Button type="link" danger onClick={cancel}>
              {t('buttons.cancel')}
            </Button>
          </span>
        ) : (
          <span>
            <EditOutlined
              style={{
                fontSize: iconSize
              }}
              onClick={() => {
                edit(record);
              }}
            />
            <Divider type="vertical" />
            <Popconfirm
              title={t('warnings.message.delete')}
              onConfirm={() => del('WAITING_ROOM', record._id)}
            >
              <DeleteOutlined
                style={{ color: '#b51010', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </span>
        );
      }
    }
  ];
};
