import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { icons } from './icons';

const { Option } = Select;

/**
 * Editable cell component for waiting rooms.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.editing - Indicates whether the cell is in editing mode.
 * @param {string} props.dataIndex - The data index of the cell.
 * @param {ReactNode} props.children - The content of the cell.
 * @param {string} props.inputType - The type of input for the cell.
 * @returns {JSX.Element} The rendered component.
 */
export const WaitingRoomsEditableCell = ({
  editing,
  dataIndex,
  children,
  inputType,
  ...restProps
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState({});
  let inputNode;

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/rooms/enums' });

      data.types = data.types.filter((type) => type !== 'CONSULTING_ROOMS');

      setEnums(data);
    } catch (error) {
      message(error);
    }
  };

  if (inputType === 'icon') {
    inputNode = (
      <Select style={{ width: '100%' }}>
        {icons.map((option) => (
          <Option key={option.value} value={option.value}>
            <FontAwesomeIcon icon={option.icon} />
          </Option>
        ))}
      </Select>
    );
  } else if (inputType === 'type') {
    inputNode = (
      <Select style={{ width: '100%' }}>
        {enums?.types?.map((type) => (
          <Option key={type} value={type}>
            {t(`waiting_room.types.${type}`)}
          </Option>
        ))}
      </Select>
    );
  } else inputNode = <Input />;

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: inputType !== 'icon' && true,
              message: t('warnings.message.fill')
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

WaitingRoomsEditableCell.propTypes = {
  editing: PropTypes.bool,
  dataIndex: PropTypes.string,
  children: PropTypes.node.isRequired,
  inputType: PropTypes.string
};

WaitingRoomsEditableCell.defaultProps = {
  editing: false,
  dataIndex: '',
  inputType: ''
};
