import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { Divider, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDownloadDocument } from '../../../../utils/downloadDoc';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { getBirthDate as getFormattedDate } from '../../../../utils/formatters';

/**
 * Custom hook that returns the columns configuration for the TreatmentPrescriptions table.
 * @returns {Object} The columns configuration.
 */
export const useColumns = ({ fromConsultation }) => {
  const { viewDocument, downloadDocument } = useDownloadDocument();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  /**
   * Handles the deletion of a treatment prescription.
   * @param {string} id - The ID of the treatment prescription to delete.
   * @returns {void}
   */
  const handleDeleteTreatmentPrescriptions = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `treatment-prescriptions/${id}`,
        body: { archived: true }
      });
    } catch (error) {
      message(error);
    }
  };

  const columns = [
    {
      title: t('patients.form.posted_date'),
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (date) => getFormattedDate(date)
    },
    {
      title: t('patients.form.general_practitioner'),
      dataIndex: 'general_practitioner',
      key: 'general_practitioner',
      sorter: (a, b) =>
        b.general_practitioner.length - a.general_practitioner.length
    },
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          {fromConsultation && (
            <>
              <DownloadOutlined
                onClick={() =>
                  downloadDocument(record.treatment_prescriptions_document)
                }
              />
              <Divider type="vertical" />
            </>
          )}
          <EyeOutlined
            onClick={() =>
              viewDocument({ _id: record.treatment_prescriptions_document._id })
            }
          />
          {!fromConsultation && (
            <>
              <Divider type="vertical" />
              <Popconfirm
                description={t('patients.form.messages.delete_doctors_letter')}
                onConfirm={() => handleDeleteTreatmentPrescriptions(record._id)}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <DeleteOutlined />
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ];

  return { columns };
};
