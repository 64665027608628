import { useTranslation } from 'react-i18next';

/**
 * Custom hook that generates a list of labels for a sub center.
 *
 * @hook
 * @param {Object} data - The data object containing the sub center information.
 * @returns {Array} - An array of label objects.
 */
export const useListContent = (data = {}) => {
  const { t } = useTranslation();
  const { secretariat_phone_number, name, address, type } = data;

  const labels = [
    {
      label: 'sub_centers.form.name',
      span: 1,
      content: name
    },
    {
      label: 'sub_centers.form.type',
      span: 1,
      content: t(`sub_centers.enums.types.${type}`)
    },
    {
      label: 'sub_centers.form.address',
      span: 1,
      content: address
    },
    {
      label: 'users.form.phone_number_full',
      span: 1,
      content:
        secretariat_phone_number && secretariat_phone_number.number ? (
          <div>
            {`${
              secretariat_phone_number.country_code
            } ${secretariat_phone_number.number.slice(
              0,
              1
            )} ${secretariat_phone_number.number
              .slice(1)
              .replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          '-'
        )
    }
  ];

  return labels;
};
