import PropTypes from 'prop-types';
import { Form, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { useFields } from './fields';
import { useColumns } from './columns';

/**
 * Represents a form component for treatment prescriptions.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Array} props.filesList - The list of files.
 * @param {Function} props.setFilesList - The function to set the list of files.
 * @returns {JSX.Element} The treatment prescriptions form component.
 */
export const TreatmentPrescriptionsForm = ({
  filesList = [],
  setFilesList
}) => {
  const [submittable, setSubmittable] = useState(false);
  const [doctorsLetterFile, setDoctorsLetterFile] = useState({});

  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();
  const { treatmentPrescriptionsFields } = useFields({
    filesList,
    setFilesList,
    doctorsLetterFile,
    setDoctorsLetterFile,
    submittable,
    form
  });
  const { columns } = useColumns({ fromConsultation: true });

  const values = Form.useWatch([], form);
  useEffect(() => {
    form
      .validateFields({
        validateOnly: true
      })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  return (
    <>
      <Table dataSource={filesList} columns={columns} />
      <Form form={form}>
        {treatmentPrescriptionsFields.map((field) =>
          generateFields('patients', field)
        )}
      </Form>
    </>
  );
};

TreatmentPrescriptionsForm.propTypes = {
  filesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFilesList: PropTypes.func.isRequired
};
