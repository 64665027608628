import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  WarningOutlined,
  ContainerOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import SubCentersTable from './ShowCenterSubCentersTable';
import UsersTable from './ShowCenterUsersTable';
import { routes } from '../../utils/constants/adminRoutes';

/**
 * Composant qui affiche les détails d'un centre, y compris les sous-centres et les utilisateurs associés.
 *
 * @returns {JSX.Element} Composant React représentant les détails du centre.
 */
export const ShowCenter = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [center, setCenter] = useState({});
  const listContent = useListContent(center);

  const getCenter = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/centers/${id}?populate=manager,sub_centers`
      });
      setCenter(data);
    } catch (e) {
      message(e);
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getCenter();
      setIsLoading(false);
    })();
  }, [getCenter]);

  const deleteCenter = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/centers/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={center.name}
        extra={[
          <>
            <Link to={{ pathname: `${routes.CENTERS}/edit/${id}` }}>
              <Button type="primary">
                {t('buttons.edit')}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteCenter}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.archive')} `}
                <ContainerOutlined />
              </Button>
            </Popconfirm>
          </>
        ]}
      />
      <ContentCustom>
        <Row>
          <Divider orientation="left">{t('basiclayout.menu.company')}</Divider>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <DescriptionList data={listContent} layout="vertical" translate />
          </Skeleton>
        </Row>
        <Row>
          <Divider orientation="left">{t('basiclayout.menu.centers')}</Divider>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <SubCentersTable center={center} />
          </Skeleton>
        </Row>
        <Row>
          <Divider orientation="left">{t('users.title')}</Divider>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <UsersTable />
          </Skeleton>
        </Row>
      </ContentCustom>
    </>
  );
};
