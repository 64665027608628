import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Popconfirm, Table } from 'antd';
import {
  CopyOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined,
  RollbackOutlined,
  ContainerOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const iconSize = 18;

/**
 * A customizable DataTable component for displaying tabular data.
 *
 * @component
 * @param {Object[]} dataSource - The array of data objects to be displayed in the table.
 * @param {string} resourceName - The name of the resource being displayed in the table.
 * @param {string} path - The path of the current location.
 * @param {string} customParam - A custom parameter for the table.
 * @param {Object[]} columns - An array of column configurations for the table.
 * @param {Object} customActionColumn - Configuration for a custom action column.
 * @param {string} populate - The populate parameter for the table.
 * @param {Object} style - The style object for the table.
 * @param {string} extraQuery - An extra query parameter for the table.
 * @param {boolean} isArchived - Flag indicating whether the table is displaying archived data.
 * @param {boolean} forceRefresh - Flag indicating whether to force a data refresh.
 * @param {boolean} showAction - Flag indicating whether to show the action column.
 * @param {Object} editAction - Configuration for the edit action.
 * @param {Object} archiveAction - Configuration for the archive action.
 * @param {Object} duplicateAction - Configuration for the duplicate action.
 * @param {Function} onDoubleClickAction - Function to be called on double click action.
 * @param {Object} scroll - Configuration for the table scroll.
 * @param {Object} expandable - Configuration for the expandable rows.
 * @param {string} rowKey - The key property of the data objects to be used as the row key.
 * @param {string} centerId - The center ID for the table.
 * @param {boolean} isInConsultation - Flag indicating whether the table is in consultation mode.
 * @param {boolean} isWaitingForExit - Flag indicating whether the table is waiting for exit.
 * @param {Function} setForceRefresh - Function to set the force refresh flag.
 * @returns {JSX.Element} The DataTable component.
 */
export const Datatable = ({
  dataSource,
  resourceName,
  path,
  customParam,
  columns,
  customActionColumn,
  populate,
  style,
  extraQuery,
  isArchived,
  forceRefresh,
  showAction,
  editAction,
  archiveAction,
  duplicateAction,
  onDoubleClickAction,
  scroll,
  expandable,
  rowKey,
  centerId,
  isInConsultation,
  isWaitingForExit,
  setForceRefresh
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [filteredResources, setFilteredResources] = useState([]);
  let modifiedData;
  const params = new URLSearchParams(location.search);
  const searchValue = params.get('k');
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [pagination, setPagination] = useState({
    pageSize: 10,
    total: 0,
    showTotal: (total, range) => `${range[1]} sur ${total} éléments`,
    showSizeChanger: true
  });
  const customParamFormat = customParam?.split(' ').join('_');

  const fetchData = useCallback(
    async (page = pagination) => {
      setIsLoading(true);
      const searchURL = searchValue ? `/search/${searchValue}` : null;
      let sortingParameter;
      if (currentSorter) sortingParameter = `sort=${currentSorter}&`;
      let filterParameter;
      if (currentFilters)
        filterParameter = `${currentFilters.replaceAll('__', '&')}`;

      const url = `/${resourceName}${searchURL || ''}?archived=${isArchived}&${
        extraQuery ? `${extraQuery}&` : ''
      }${sortingParameter || ''}${filterParameter || ''}${
        populate ? `populate=${populate}&` : ''
      }limit=${pageSize}&skip=${(currentPage - 1) * pageSize}`;

      try {
        const { data, headers } = await dispatchAPI('GET', { url });
        setPagination({
          ...page,
          total: parseInt(headers?.['x-total-count'] || 0, 10)
        });

        setResources(data);
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    },
    [
      searchValue,
      currentPage,
      pageSize,
      currentSorter,
      currentFilters,
      forceRefresh,
      extraQuery,
      isArchived
    ]
  );

  const archiveResource = async (record) => {
    try {
      await dispatchAPI('PATCH', {
        url: archiveAction.pathname || `/${resourceName}/archive/${record._id}`,
        body: { archived: !record.archived }
      });
      if (setForceRefresh) setForceRefresh(!forceRefresh);
      if (!dataSource) await fetchData();
    } catch (e) {
      message(e);
    }
  };

  const duplicateResource = async (id) => {
    try {
      await dispatchAPI('POST', { url: `/${resourceName}/duplicate/${id}` });
      await fetchData();
    } catch (e) {
      message(e);
    }
  };

  const handlePageChange = (page, filters, sorters = {}) => {
    let sortingParameter;
    if (sorters) {
      if (!sorters.order) {
        sortingParameter = null;
      } else if (sorters.order === 'descend') {
        sortingParameter = `&s=-${sorters.columnKey}`;
      } else {
        sortingParameter = `&s=${sorters.columnKey}`;
      }
    }
    let filterParameter = '';
    Object.entries(filters || {}).forEach((el) => {
      if (el[1] && el[1].length) filterParameter += `${el[0]}=${[...el[1]]}__`;
    });
    navigate({
      pathname,
      search: `?p=${page.current}${
        page && page.pageSize ? `&pS=${page.pageSize}` : ''
      }${sortingParameter || ''}${
        filterParameter ? `&f=${filterParameter}` : ''
      }${searchValue ? `&k=${searchValue}` : ''}`
    });
  };

  useEffect(() => {
    (async () => {
      if (!dataSource) await fetchData();
    })();
    if (dataSource) setResources(dataSource);
  }, [fetchData, dataSource]);

  useEffect(() => {
    if (isInConsultation) {
      modifiedData = resources.filter(
        (patient) => patient.current_consultation
      );
    } else if (isWaitingForExit) {
      modifiedData = resources.filter((patient) => patient.awaiting_exit);
    } else {
      modifiedData = resources;
    }
    setFilteredResources(modifiedData);
  }, [isInConsultation, isWaitingForExit, resources]);

  const actionColumn = [
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          {duplicateAction && (
            <CopyOutlined
              onClick={() => duplicateResource(record?.[rowKey])}
              style={{ fontSize: iconSize }}
            />
          )}
          {showAction && (
            <Link
              to={{
                pathname: showAction.pathname
                  ? showAction.pathname(record)
                  : `${path || pathname}/show/${record?.[rowKey]}/${
                      customParamFormat || ''
                    }`
              }}
            >
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Link>
          )}
          {editAction && (
            <>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: editAction.pathname
                    ? editAction.pathname(record)
                    : `${path || pathname}/edit/${record?.[rowKey]}/${
                        centerId || ''
                      }`
                }}
              >
                <EditOutlined style={{ fontSize: iconSize }} />
              </Link>
            </>
          )}
          {archiveAction && (
            <>
              <Divider type="vertical" />
              {!record?.archived ? (
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() =>
                    archiveAction.customAction
                      ? archiveAction.customAction(record)
                      : archiveResource(record)
                  }
                  icon={<WarningOutlined />}
                >
                  <ContainerOutlined
                    style={{ color: '#b51010', fontSize: iconSize }}
                  />
                </Popconfirm>
              ) : (
                <Popconfirm
                  title={t('datatable.column.action.unarchive.title')}
                  okText={t('datatable.column.action.unarchive.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.unarchive.cancel')}
                  onConfirm={() =>
                    archiveAction.customAction
                      ? archiveAction.customAction(record)
                      : archiveResource(record)
                  }
                  icon={<WarningOutlined />}
                >
                  <RollbackOutlined
                    style={{ color: '#b51010', fontSize: 16 }}
                  />
                </Popconfirm>
              )}
            </>
          )}
        </>
      )
    }
  ];

  const adjustedColumns = columns.map((col) => {
    let order;
    let orderKey;
    if (currentSorter) {
      order = 'ascend';
      orderKey = currentSorter;
    }
    if (currentSorter && currentSorter.charAt(0) === '-') {
      order = 'descend';
      orderKey = currentSorter.substring(1);
    }
    const filters = {};
    if (currentFilters) {
      const filtersList = currentFilters.split('__');
      filtersList.forEach((f) => {
        if (f.split('=').length) {
          const [key, values] = f.split('=');
          filters[key] = values;
        }
      });
    }

    return {
      ...col,
      sortOrder: col.key === orderKey ? order : false,
      filteredValue: filters[col.key] ? filters[col.key].split(',') : []
    };
  });

  const getKey = () => resources.find((r) => r.key);
  const keyExists = getKey();

  return (
    <Table
      style={style}
      scroll={scroll}
      rowKey={rowKey}
      rowClassName="rowStyle"
      onRow={(record) => ({
        ...(onDoubleClickAction
          ? {
              onDoubleClick: () =>
                onDoubleClickAction.action
                  ? onDoubleClickAction.action(record)
                  : navigate(`${path || pathname}/show/${record[rowKey]}`)
            }
          : {})
      })}
      dataSource={
        isInConsultation || isWaitingForExit ? filteredResources : resources
      }
      loading={isLoading}
      onChange={handlePageChange}
      pagination={{
        ...pagination,
        current: currentPage,
        pageSize
      }}
      columns={
        customActionColumn
          ? adjustedColumns
          : [...adjustedColumns, ...actionColumn]
      }
      expandable={
        // eslint-disable-next-line no-nested-ternary
        !isLoading && resources.length && expandable
          ? keyExists
            ? {
                ...expandable,
                expandedRowKeys:
                  expandable.expandedRowKeys || resources.map((r) => r.key)
              }
            : expandable
          : undefined
      }
    />
  );
};

Datatable.propTypes = {
  resourceName: PropTypes.string.isRequired,
  path: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string.isRequired,
      dataIndex: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
      ]),
      render: PropTypes.func,
      sorter: PropTypes.bool,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.string
        })
      )
    })
  ).isRequired,
  customActionColumn: PropTypes.bool,
  populate: PropTypes.string,
  style: PropTypes.shape({}),
  extraQuery: PropTypes.string,
  forceRefresh: PropTypes.bool,
  centerId: PropTypes.string,
  isArchived: PropTypes.bool,
  archiveAction: PropTypes.shape({
    pathname: PropTypes.string,
    customAction: PropTypes.func
  }),
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  duplicateAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  onDoubleClickAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  scroll: PropTypes.shape({}),
  expandable: PropTypes.shape({
    expandedRowKeys: PropTypes.arrayOf(PropTypes.string)
  }),
  rowKey: PropTypes.string,
  isInConsultation: PropTypes.bool,
  isWaitingForExit: PropTypes.bool,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  customParam: PropTypes.string,
  setForceRefresh: PropTypes.func
};

Datatable.defaultProps = {
  path: null,
  customActionColumn: false,
  populate: null,
  style: null,
  extraQuery: null,
  forceRefresh: null,
  editAction: true,
  archiveAction: {
    pathname: null,
    customAction: null
  },
  isArchived: false,
  showAction: true,
  duplicateAction: false,
  onDoubleClickAction: true,
  scroll: null,
  centerId: null,
  expandable: undefined,
  rowKey: '_id',
  isInConsultation: false,
  isWaitingForExit: false,
  dataSource: null,
  customParam: null,
  setForceRefresh: () => {}
};
