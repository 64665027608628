import { Route, Routes } from 'react-router-dom';
import { ManagerContextProvider } from '../../../contexts/ManagerContext/ManagerContext';
import { ListDataBase } from './ListDataBase';
import { SpecialistRouter } from '../../specialist/SpecialistRouter';
import { DirectoryRouter } from '../../directory/DirectoryRouter';

/**
 * Renders the ManagerDataBaseRouter component.
 * This component provides routing for the manager configurations in the database.
 *
 * @returns {JSX.Element} The rendered ManagerDataBaseRouter component.
 */
export const ManagerDataBaseRouter = () => (
  <ManagerContextProvider>
    <Routes>
      <Route index element={<ListDataBase />} />
      <Route path="/specialists/*" element={<SpecialistRouter />} />
      <Route path="/directory/*" element={<DirectoryRouter />} />
    </Routes>
  </ManagerContextProvider>
);
