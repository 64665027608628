import PropTypes from 'prop-types';
import { Card } from 'antd';
import { useDrop } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  fa6,
  fa7,
  fa8,
  fa9,
  faHourglassStart,
  faRadiation,
  faCommentMedical
} from '@fortawesome/free-solid-svg-icons';
import { Patient } from './Patient';
import { ConsultingRoom } from './ConsultingRoom';

const iconMap = {
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  fa6,
  fa7,
  fa8,
  fa9,
  faHourglassStart,
  faRadiation,
  faCommentMedical
};

/**
 * Represents a room component.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setRefresh - The function to refresh the component.
 * @param {Object} props.room - The room object.
 * @param {string} props.room.type - The type of the room.
 * @param {string} props.room.title - The title of the room.
 * @param {string} props.room.color - The color of the room.
 * @param {string} props.room.icon - The icon of the room.
 * @param {string} props.room._id - The ID of the room.
 * @param {Array<Object>} props.room.consultations - The array of consultations in the room.
 * @param {Array<Object>} props.room.rooms - The array of rooms in the room.
 *
 * @returns {JSX.Element} The rendered Room component.
 */
export const Room = ({ setRefresh, room }) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'PATIENT',
    drop: () => ({ target: room?._id }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }));

  const isActive = canDrop && isOver;

  const cardStyle = {
    styles: {
      header: {
        backgroundColor: isActive
          ? 'rgba(138, 199, 255, 0.6)'
          : 'rgba(138, 199, 255, 0.2)'
      },
      body: {
        backgroundColor: isActive
          ? 'rgba(138, 199, 255, 0.6)'
          : 'rgba(138, 199, 255, 0.2)'
      }
    }
  };

  if (room?.type === 'CONSULTING_ROOMS') {
    return (
      <Card
        className="kanban-room"
        {...cardStyle}
        title={
          <span>
            <FontAwesomeIcon icon={iconMap[room?.icon]} /> {room?.title}
          </span>
        }
      >
        {room?.rooms?.map((r) => (
          <ConsultingRoom key={r._id} room={r} setRefresh={setRefresh} />
        ))}
      </Card>
    );
  }

  return (
    <div ref={drop}>
      <Card
        className="kanban-room"
        {...cardStyle}
        title={
          <span>
            <FontAwesomeIcon icon={iconMap[room?.icon]} /> {room?.title}
          </span>
        }
      >
        {room?.consultations?.map((consultation) => (
          <Patient
            key={consultation._id}
            departureRoomId={room._id}
            setRefresh={setRefresh}
            consultation={consultation}
          />
        ))}
      </Card>
    </div>
  );
};

Room.propTypes = {
  setRefresh: PropTypes.func,
  room: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string,
    _id: PropTypes.string,
    consultations: PropTypes.arrayOf(PropTypes.shape({})),
    rooms: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

Room.defaultProps = {
  setRefresh: () => {},
  room: {
    type: '',
    title: '',
    color: '',
    icon: '',
    _id: '',
    patients: [],
    rooms: []
  }
};
