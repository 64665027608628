import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, List, Button, Spin } from 'antd';
import { HeartFilled, FileOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { usePrescriptionContext } from '../../../contexts/PrescriptionContext/PrescriptionContext';

/**
 * @component FavoriteProtocolesCard
 * @param {boolean} props.forceRefresh - Indicates if the state needs to be refreshed.
 * @param {Function} props.setCheckedKeys - Function to set the checked keys.
 * @returns {React.Component} Card to display the list of favorite protocols
 * @description This component is used in the exit prescription
 */
export const FavoriteProtocolsCard = ({ forceRefresh, setFavCheckedKeys }) => {
  const { t } = useTranslation();
  const { getFavoritesProtocols, favoritesProtocols } =
    usePrescriptionContext();
  const [loading, setLoading] = useState(false);
  const translationKey = 'prescription.cards.favorite-protocols';

  useEffect(() => {
    (async () => {
      await getFavoritesProtocols(setLoading);
    })();
  }, [forceRefresh]);

  const handleSelectFavorite = (favorite) => {
    setFavCheckedKeys(favorite.checked_keys);
  };

  return (
    <Card
      className="ant-card "
      title={
        <>
          <HeartFilled /> {t(`${translationKey}.title`)}
        </>
      }
    >
      <Spin spinning={loading}>
        <List
          dataSource={favoritesProtocols}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                description={
                  <Button
                    type="link"
                    onClick={() => handleSelectFavorite(item)}
                  >
                    <FileOutlined /> {item?.label}
                  </Button>
                }
              />
            </List.Item>
          )}
        />
      </Spin>
    </Card>
  );
};

FavoriteProtocolsCard.propTypes = {
  forceRefresh: PropTypes.bool.isRequired,
  setFavCheckedKeys: PropTypes.func.isRequired
};
