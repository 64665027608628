import { Button, Divider, Form, Input, Modal, Row, Space } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Protocol } from '../../../../models/Protocol';

/**
 * Modal component for creating a favorite protocol.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setOpenAddFavoriteModal - Function to set the state of the modal visibility.
 * @param {Array} props.checkedKeysData - Array of checked keys data.
 * @param {Function} props.setForceRefresh - Function to set the state to force refresh.
 * @param {boolean} props.openAddFavoriteModal - Boolean indicating if the modal is open or not.
 * @param {Object} props.form - The form object.
 * @returns {JSX.Element} The JSX element representing the modal for creating a favorite protocol.
 */

// TODO WIP
export const ModalCreateFavoriteProtocol = ({
  setOpenAddFavoriteModal,
  // checkedKeysData,
  setForceRefresh,
  openAddFavoriteModal,
  srnCareTreeKeys,
  imagingTreeKeys,
  biologyTreeKeys
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const postProtocol = ({ label }) => {
    Protocol.createFavorite({
      label,
      checked_keys: {
        srn_care: srnCareTreeKeys,
        imaging: imagingTreeKeys,
        biology: biologyTreeKeys
      }
    });
    setOpenAddFavoriteModal(false);
    setForceRefresh(true);
  };

  return (
    <Modal
      open={openAddFavoriteModal}
      onCancel={() => setOpenAddFavoriteModal(false)}
      closable={false}
      footer={null}
    >
      <Form form={form} onFinish={postProtocol}>
        <Form.Item name="label" label="Protocol" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Row justify="end">
          <Divider />
          <Space>
            <Button onClick={() => setOpenAddFavoriteModal(false)}>
              {t('buttons.cancel')}
            </Button>
            <Button htmlType="submit">{t('buttons.validate')}</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

ModalCreateFavoriteProtocol.propTypes = {
  setOpenAddFavoriteModal: PropTypes.func.isRequired,
  // checkedKeysData: PropTypes.arrayOf(PropTypes.string).isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  openAddFavoriteModal: PropTypes.bool.isRequired,
  form: PropTypes.shape({}).isRequired,
  srnCareTreeKeys: PropTypes.arrayOf(PropTypes.string),
  imagingTreeKeys: PropTypes.arrayOf(PropTypes.string),
  biologyTreeKeys: PropTypes.arrayOf(PropTypes.string)
};

ModalCreateFavoriteProtocol.defaultProps = {
  srnCareTreeKeys: [],
  imagingTreeKeys: [],
  biologyTreeKeys: []
};
