import React from 'react';
import PropTypes from 'prop-types';

/**
 * Displays a badge component with a number inside, colored based on the visit type.
 * @component
 *
 * @param {Object} props - The props of the component.
 * @param {number} props.number - The number to display inside the badge.
 * @param {string} props.visitType - The type of visit, determines the color of the badge. Should be one of 'FIRST_VISIT' or any other string.
 *
 * @returns {JSX.Element} A Badge component.
 */
export const Badge = ({ number, visitType }) => {
  const color = visitType === 'FIRST_VISIT' ? '#004a8f' : '#ec3e40';

  const badgeStyle = {
    backgroundColor: color,
    color: 'white',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 'bold'
  };

  return <div style={badgeStyle}>{number}</div>;
};

Badge.propTypes = {
  number: PropTypes.number.isRequired,
  visitType: PropTypes.string.isRequired
};
