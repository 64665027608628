import { MedicineBoxOutlined } from '@ant-design/icons';
import { Checkbox, Popconfirm, Space, Tooltip } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Patient } from '../../../models/Patient';
import { useHandleChange } from '../utils/handleChange';

// work in progress
export const CareIcon = ({ patient }) => {
  const [enums, setEnums] = useState([]);
  const [transmittedCares, settransmittedCares] = useState([]);

  const { t } = useTranslation();
  const { handleChange } = useHandleChange(
    transmittedCares,
    settransmittedCares
  );

  const handleConfirm = async () => {
    await Patient.patchTransmittedMedications(patient._id, {
      transmittedCares
    });
  };

  const getPatientEnums = useCallback(async () => {
    const data = await Patient.getEnums();
    setEnums(data.careTerms);
  }, []);

  useEffect(() => {
    (async () => {
      await getPatientEnums();
    })();
  }, []);

  const careTerms = enums.map((term) => (
    <Checkbox onChange={handleChange} key={term} value={term}>
      {t(`patients.icons.care.${term}`)}
    </Checkbox>
  ));

  return (
    <Tooltip title="Premiers soins">
      <Popconfirm
        placement="bottomRight"
        title="Premiers soins"
        description={
          <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
            <Space direction="vertical">{careTerms}</Space>
          </div>
        }
        okText={t('buttons.validate')}
        cancelText={t('buttons.cancel')}
        icon={<MedicineBoxOutlined />}
        onConfirm={handleConfirm}
      >
        <MedicineBoxOutlined />
      </Popconfirm>
    </Tooltip>
  );
};

CareIcon.propTypes = {
  patient: PropTypes.shape({
    _id: PropTypes.string
  })
};

CareIcon.defaultProps = {
  patient: {}
};
