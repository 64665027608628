import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Button, Row, message } from 'antd';
import { ContentCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { TurnoverTargets } from './components/Collapses/TurnoverTargets';
import { WaitingRooms } from './components/Collapses/WaitingRooms';
import { ConsultingRooms } from './components/Collapses/ConsultingRooms';
import { KanbanColumns } from './components/Collapses/KanbanColumns';

/**
 * SubCenterSettings component.
 * Renders a form for editing sub-center settings.
 *
 * @returns {JSX.Element} SubCenterSettings component.
 */
export const SubCenterSettings = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const [rooms, setRooms] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const errorMessage = t('messages.error');

  const getResource = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/sub-centers/${id}` });
      form.setFieldsValue(data);
    } catch (error) {
      message.error(errorMessage);
    }
  };

  const getRooms = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/rooms/WAITING_ROOM/${id}`
      });
      setRooms(data);
    } catch (error) {
      message.error(errorMessage);
    }
  };

  const patchSubCenter = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/sub-centers/${id}`,
        body
      });
      message.success(t('success.message.sub_center.updated_successfully'));
      navigate(-1);
    } catch (e) {
      message.error(errorMessage);
    }
  };

  const postRoom = async (type) => {
    try {
      await dispatchAPI('POST', {
        url: '/rooms',
        body: {
          type,
          language: navigator.language,
          sub_center: id
        }
      });
      setRefresh(!refresh);
    } catch (error) {
      message.error(errorMessage);
    }
  };

  const props = {
    refresh,
    setRefresh,
    id,
    postRoom
  };

  useEffect(() => {
    (async () => {
      await getRooms();
      await getResource();
    })();
  }, [refresh]);

  return (
    <ContentCustom>
      <Form form={form} onFinish={patchSubCenter}>
        <TurnoverTargets form={form} id={id} />
        <WaitingRooms rooms={rooms} {...props} />
        <ConsultingRooms {...props} />
        <KanbanColumns refresh={refresh} setRefresh={setRefresh} />
        <Row justify="end">
          <Button type="link" onClick={() => navigate(-1)}>
            {t('buttons.cancel')}
          </Button>
          <Button htmlType="submit" type="primary">
            {t('buttons.save_changes')}
          </Button>
        </Row>
      </Form>
    </ContentCustom>
  );
};
