import { useParams } from 'react-router-dom';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';
import { RegisterBackButton } from '../../components/RegisterBackButton/RegisterBackButton';

/**
 * CreateUpdateConsultation component
 * @component
 * @return {object} - The UI of the CreateUpdateConsultation component
 */
export const CreateUpdateConsultation = () => {
  const { id } = useParams();
  const purpose = 'create';
  const resource = 'consultations';
  const { newConsultationFields } = useFields();

  return (
    <CreateUpdateContainer
      fields={newConsultationFields}
      purpose={purpose}
      baseUrl={`${resource}/${id}`}
      resource="consultations"
      isPageHeaderCustom={false}
      layout="horizontal"
      isFormExtra
      formExtra={<RegisterBackButton />}
      navigateCustom
      customUrl="/"
    />
  );
};
