import { useTranslation } from 'react-i18next';
import { Popconfirm, Divider, Button } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

export const useColumns = ({ isEditing, save, edit, cancel, del }) => {
  const iconSize = 18;
  const { t } = useTranslation();
  return [
    {
      title: t('consulting_room.form.title').toUpperCase(),
      key: 'title',
      dataIndex: 'title',
      editable: true
    },
    {
      title: t('consulting_room.form.color').toUpperCase(),
      key: 'color',
      dataIndex: 'color',
      editable: true,
      render: (color) => (
        <div
          style={{
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            backgroundColor: color
          }}
        />
      )
    },
    {
      title: t('consulting_room.form.maximum_capacity').toUpperCase(),
      key: 'maximum_capacity',
      dataIndex: 'maximum_capacity',
      editable: true
    },
    {
      key: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="link"
              onClick={() => {
                save(record._id);
              }}
              style={{
                marginRight: 8
              }}
            >
              {t('buttons.save')}
            </Button>
            <Button type="link" danger onClick={cancel}>
              {t('buttons.cancel')}
            </Button>
          </span>
        ) : (
          <span>
            <EditOutlined
              style={{
                fontSize: iconSize
              }}
              onClick={() => {
                edit(record);
              }}
            />
            <Divider type="vertical" />
            <Popconfirm
              title={t('warnings.message.delete')}
              onConfirm={() => del(record.type, record._id)}
            >
              <DeleteOutlined
                style={{ color: '#b51010', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </span>
        );
      }
    }
  ];
};
