import { useTranslation } from 'react-i18next';
import { Switch } from 'antd';
import { DataBaseMethods } from '../DataBaseMethods';

/**
 * Custom hook for defining columns for the Stay Prescriptions data table.
 *
 * @returns {Object} An object containing the `columns` array.
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const { onChangeUserDisplay } = DataBaseMethods();
  const resource = 'config-stay-prescriptions';

  const columns = [
    {
      title: t('data_bases.stay_prescriptions.columns.prescription_wording'),
      dataIndex: 'prescription_wording',
      key: 'prescription_wording',
      sorter: (a, b) =>
        a.prescription_wording.localeCompare(b.prescription_wording)
    },
    {
      title: t('data_bases.visit-reasons.columns.user_display'),
      key: 'actions',
      align: 'rigth',
      fixed: 'rigth',
      render: (record) => (
        <Switch
          checkedChildren={t('buttons.displayed')}
          unCheckedChildren={t('buttons.hidden')}
          defaultChecked={record.user_display}
          onChange={(checked) =>
            onChangeUserDisplay(resource, checked, record._id)
          }
        />
      )
    }
  ];

  return { columns };
};
