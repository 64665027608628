/**
 * Formats the body object to a specific structure for database insertion.
 * @function
 * @param {Object} body - The body object to be formatted.
 * @returns {Object} - The formatted body object.
 */
export const newBodyFormatter = (body) => {
  const newBody = {
    ...body,
    ...body.patient.main,
    ...body.patient.admin,
    ...body.patient.comments,
    ...body.patient.amoAmc,
    ...body.patient.card,
    ...body.patient.report_reception
  };

  const { reported_comment_reception, written_by, description, ...rest } =
    newBody;

  const formattedBody = {
    ...rest,
    comments: {
      description
    },
    report_reception: {
      reported_comment_reception
    },
    files: [
      { identity_card: body.card.identity_card?.fileList?.[0] || null },
      {
        social_security_card:
          body.card.social_security_card?.fileList?.[0] || null
      },
      {
        health_mutual_card: body.card.health_mutual_card?.fileList?.[0] || null
      }
    ]
  };

  return formattedBody;
};
