import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'antd';
import { Datatable } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useSubCentersActionsColumns } from './sub-centers/SubCentersActionsColumns';
import { SubCenter } from '../../models/SubCenter';

/**
 * Rend un composant de tableau pour afficher les sous-centres.
 *
 * @param {Function} t - Fonction de traduction i18n.
 * @param {Object} record - L'objet d'enregistrement contenant les données des sous-centres.
 * @param {Array} typeArr - Un tableau de types de sous-centres.
 * @param {Function} archiveAction - La fonction pour archiver / désarchiver le sous-centre.
 * @returns {JSX.Element} - Le composant de tableau rendu.
 */
const ShowCenterSubCentersTable = ({ center }) => {
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const { t } = useTranslation();
  const [enums, setEnums] = useState({});
  const [refresh, setRefresh] = useState(false);

  const columns = [
    {
      title: t('sub_centers.form.name').toUpperCase(),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => b.name.length - a.name.length
    },
    {
      title: t('sub_centers.form.type').toUpperCase(),
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => b.type.length - a.type.length,
      filters: enums?.types?.map((type) => ({
        text: type,
        value: type
      })),
      onFilter: (value, record) => record.type.indexOf(value) === 0
    },
    {
      title: t('sub_centers.form.address').toUpperCase(),
      dataIndex: 'address',
      key: 'address',
      render: (address) => address || '-'
    },
    {
      title: t('sub_centers.form.secretariat_phone_number').toUpperCase(),
      dataIndex: 'secretariat_phone_number',
      key: 'secretariat_phone_number',
      render: (secretariat_phone_number) =>
        secretariat_phone_number && secretariat_phone_number.number ? (
          <div>
            {`${
              secretariat_phone_number.country_code
            } ${secretariat_phone_number.number.slice(
              0,
              1
            )} ${secretariat_phone_number.number
              .slice(1)
              .replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          '-'
        )
    },
    {
      title: t('sub_centers.form.number_of_users').toUpperCase(),
      dataIndex: 'number_of_users',
      key: 'number_of_users',
      sorter: true
    }
  ];

  const archiveUnarchiveSubCenter = async (subCenterId, archived) => {
    await SubCenter.archivingSubCenter(subCenterId, { archived: !archived });
    setRefresh(!refresh);
  };

  const getEnums = async () => {
    const { data } = await dispatchAPI('GET', {
      url: '/sub-centers/enums'
    });
    setEnums(data);
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return (
    <Col span={24}>
      <Row justify="end" align="middle" style={{ marginBottom: 16 }}>
        <Link to={`/sub-centers/create/${id}`}>
          <Button type="primary">{t('buttons.create')}</Button>
        </Link>
      </Row>
      <Datatable
        forceRefresh={refresh}
        columns={[
          ...columns,
          ...useSubCentersActionsColumns(
            t,
            center._id,
            archiveUnarchiveSubCenter
          )
        ]}
        customActionColumn
        resourceName={`centers/sub-centers/${id}`}
        path="/sub-centers"
        centerId={id}
        extraQuery="archived=false"
      />
    </Col>
  );
};

ShowCenterSubCentersTable.propTypes = {
  center: PropTypes.string.isRequired
};

export default ShowCenterSubCentersTable;
