import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSocketContext } from '../../contexts/SocketContext';
import { usePatientContext } from '../../contexts/PatientContext/PatientContext';
import { ListResource } from '../../components';
import { useColumns } from './components/ListPatients/columnsPatientsList';
import { useActionColumns } from './components/ListPatients/actionColumns';
import { SorterButtonsHeader } from './components/ListPatients/SorterButtonsHeader';

/**
 * Renders a list of patients with consultations.
 *
 * @component
 * @returns {JSX.Element} The list of patients component.
 */
export const ListPatients = () => {
  const { enums, forceRefresh, setForceRefresh, datePicking } =
    usePatientContext();
  const { socket } = useSocketContext();
  const columns = useColumns(enums);
  const actionColumn = useActionColumns();
  const [status, setStatus] = useState('ALL');
  const startOfDay = dayjs(datePicking).startOf('day').toISOString();
  const endOfDay = dayjs(datePicking).endOf('day').toISOString();

  const resourceModelName = 'Consultation';
  const resource = 'consultations';
  const population = 'patient,visit_reasons,sub_center';
  const extraQuery = `date>=${startOfDay}&date<=${endOfDay}${
    status !== 'ALL' ? `&status=${status}` : ''
  }`;

  useEffect(() => {
    if (socket) {
      socket.on('changePatientRoom', () => {
        setForceRefresh((prevRefresh) => !prevRefresh);
      });
    }
  }, [socket]);

  useEffect(() => {}, [forceRefresh]);

  return (
    <ListResource
      resourceName={`${resource}`}
      customActionColumn
      columns={[...columns, ...actionColumn]}
      resourceModelName={resourceModelName}
      populate={population}
      extraQuery={extraQuery}
      deleteAction={false}
      isOriginalContentCustom={false}
      isPageHeaderCustom={false}
      extraButtons={
        <SorterButtonsHeader
          resource={resource}
          status={status}
          setStatus={setStatus}
        />
      }
      onDoubleClickAction={false}
      forceRefresh={forceRefresh}
    />
  );
};
