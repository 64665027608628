import { createContext, useContext, useEffect, useState } from 'react';
import { notification, message as Message } from 'antd';
import { useTranslation } from 'react-i18next';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { useStateWithLocalStorage } from '../utils';
import { BaseModel } from '../models/BaseModel';
import { useErrorMessage } from '../utils/errorMessage';

export const AuthContext = createContext({ isValid: false });
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' }
});

export const AuthContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [user, setUser] = useStateWithLocalStorage('user', {
    first_name: 'John',
    last_name: 'Doe',
    role: 'admins:ADMIN'
  });
  const [isDoubleFactorAuth, setIsDoubleFactorAuth] = useState(false);
  const [token, setToken] = useStateWithLocalStorage('token');
  const [refreshToken, setRefreshToken] =
    useStateWithLocalStorage('refresh_token');
  const [remember, setRememberMe] = useStateWithLocalStorage(
    'remember_me',
    !!refreshToken
  );
  const [isValid, setIsValid] = useState(!!refreshToken || !!token);
  const [pendingTasksTray, setPendingTasksTray] = useStateWithLocalStorage(
    'pendingTasksTray',
    []
  );

  const [chooseCenter, setChooseCenter] = useState(false);
  const [redirectToAccount, setRedirectToAccount] = useState(false);

  const setSession = (accessToken) => {
    if (accessToken) {
      setToken(accessToken);
      setIsValid(true);
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      setToken(null);
      setRefreshToken(null);
      setUser(null);
      setIsValid(false);
      delete axiosInstance.defaults.headers.common.Authorization;
    }
  };

  const loginAPI = async (email, password, rememberMe) => {
    const result = await axiosInstance.get('/login', {
      auth: {
        username: email,
        password
      }
    });
    if (!result.data.double_factor_authentication) {
      if (result.data.user.role === 'users:SUPER-USER') {
        setChooseCenter(true);
        setUser(result.data.user);
        setRememberMe(rememberMe);
        setToken(result.data.token);
        setIsDoubleFactorAuth(false);
        if (rememberMe) setRefreshToken(result.data.refresh_token);
        return result;
      }
      setSession(result.data.token);
      setUser(result.data.user);
      setRememberMe(rememberMe);
      setIsDoubleFactorAuth(false);
      Message.success(t('login.success'));
      if (rememberMe) setRefreshToken(result.data.refresh_token);
    }
    setIsDoubleFactorAuth(true);
    setUser(result.data.user);
    return result;
  };

  const login2FA = async (body) => {
    const result = await axiosInstance.post('/login', body);
    if (result.data.user.role === 'users:SUPER-USER') {
      setChooseCenter(true);
      setUser(result.data.user);
      setToken(result.data.token);
      setIsDoubleFactorAuth(false);
      Message.success(t('login.success'));
      if (body.rememberMe) setRefreshToken(result.data.refresh_token);
      return result;
    }
    setSession(result.data.token);
    setUser(result.data.user);
    setIsDoubleFactorAuth(false);
    Message.success(t('login.success'));
    if (body.rememberMe) setRefreshToken(result.data.refresh_token);
    return result;
  };

  const createPwd = async (values) => {
    await axiosInstance.post('/login/create-pwd', values);
    setIsDoubleFactorAuth(false);
    Message.success(t('login.passwordCreated'));
  };

  const registerAPI = (values) => axiosInstance.post('/register', values);

  const logout = () => {
    setSession(null);
    setUser(null);
    setIsDoubleFactorAuth(false);
  };

  const isTokenValid = async () => {
    if (!token) {
      setSession(null);
      return false;
    }
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded?.exp < currentTime) {
        if (remember && refreshToken) {
          const result = await axiosInstance.post('/token/refresh', {
            refresh_token: refreshToken
          });
          setToken(result.data.newToken);
          return { newToken: result.data.newToken };
        }
        notification.warn({
          Message: t('login.expiredSession'),
          key: 'network_feedback'
        });
        setSession(null);
        return false;
      }
    } catch (e) {
      notification.warn({
        Message: t('login.expiredSession'),
        key: 'network_feedback'
      });
      setSession(null);
      return false;
    }
    if (!isValid) {
      setIsValid(true);
    }
    return true;
  };

  useEffect(() => {
    (async () => {
      await isTokenValid();
    })();
  }, []);

  const fetchAPI = async (
    url,
    method = 'GET',
    body = undefined,
    responseType = 'json',
    cancelToken = undefined,
    params = undefined
  ) => {
    const { newToken } = await isTokenValid();
    if (
      ['POST', 'PATCH', 'DELETE'].includes(method) &&
      !window.navigator.onLine
    ) {
      setPendingTasksTray([
        ...(pendingTasksTray || []),
        ...[{ url, method, body }]
      ]);
      return new Response();
    }
    const result = await axiosInstance({
      url,
      method,
      responseType,
      cancelToken,
      data: body,
      params,
      headers: {
        Authorization: `Bearer ${newToken || token}`
      }
    });
    return result;
  };

  const dispatchAPI = (type, options) => {
    switch (type) {
      case 'LOGIN':
        return loginAPI(options.email, options.password, options.rememberMe);
      case 'LOGIN2FA':
        return login2FA(options);
      case 'CREATE_PWD':
        return createPwd(options);
      case 'REGISTER':
        return registerAPI(options);
      case 'LOGOUT':
        return logout();
      case 'GET':
        return fetchAPI(
          options.url,
          'GET',
          null,
          options.responseType,
          options.cancelToken,
          options.params
        );
      case 'DELETE':
        return fetchAPI(options.url, 'DELETE');
      case 'POST':
      case 'PATCH':
        return fetchAPI(options.url, type, options.body);
      default:
        throw new Error('Unknown dispatchAPI type!');
    }
  };
  BaseModel.dispatchAPI = dispatchAPI;
  BaseModel.message = message;

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        token,
        isValid,
        isDoubleFactorAuth,
        dispatchAPI,
        remember,
        pendingTasksTray,
        setPendingTasksTray,
        setSession,
        chooseCenter,
        setChooseCenter,
        redirectToAccount,
        setRedirectToAccount
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
