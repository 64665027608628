import { useTranslation } from 'react-i18next';
import { getPhoneNumber } from '../../utils/formatters';

/**
 * Hook to generate columns for the specialists table.
 * This hook returns an array of column objects configured with translated titles and data rendering functions.
 *
 * @hook useColumns
 * @returns {Array} An array of column objects for the specialists table.
 */

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('specialists.form.first_name').toUpperCase(),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('specialists.form.second_name').toUpperCase(),
      key: 'second_name',
      dataIndex: 'second_name'
    },
    {
      title: t('specialists.form.speciality').toUpperCase(),
      key: 'speciality',
      dataIndex: 'speciality',
      sorter: true
    },
    {
      title: t('specialists.form.secretariat_short_number').toUpperCase(),
      key: 'secretariat_short_number',
      dataIndex: 'secretariat_short_number',
      render: (secretariat_short_number) =>
        getPhoneNumber(secretariat_short_number)
    },
    {
      title: t('specialists.form.doctor_short_number').toUpperCase(),
      key: 'doctor_short_number',
      dataIndex: 'doctor_short_number',
      render: (doctor_short_number) => getPhoneNumber(doctor_short_number)
    },
    {
      title: t('specialists.form.doctor_mobile_number').toUpperCase(),
      key: 'doctor_mobile_number',
      dataIndex: 'doctor_mobile_number',
      render: (doctor_mobile_nulber) => getPhoneNumber(doctor_mobile_nulber)
    },
    {
      title: t('specialists.form.email').toUpperCase(),
      key: 'email',
      dataIndex: 'email'
    }
  ];
};
