import { CardPicture } from '../components/Fields/CardPicture';
import { NoCardRadioButton } from '../components/CardsHistory/NoCardRadioButton';
import { CardUpload } from '../../../components/CardUpload/CardUpload';
import { FormatedDatePicker } from '../components/Fields/FormatedDatePicker';

/**
 * Represents an array of insurance card fields.
 *
 * @type {Array}
 */
export const insuranceCardFields = [
  {
    noLabel: true,
    name: ['card', 'health_mutual_card_picture'],
    rules: [{ type: 'file' }],
    input: <CardPicture />
  },
  {
    noLabel: true,
    name: ['card', 'health_mutual_card'],
    rules: [{ type: 'file' }],
    input: (
      <CardUpload
        dbKey="health_mutual_card"
        resource="patients"
        antdKey="card"
      />
    )
  },
  {
    label: 'date',
    name: ['patient', 'card', 'health_mutual_card_exp_date'],
    rules: [
      {
        required: false
      },
      { type: 'date' }
    ],
    input: (
      <FormatedDatePicker antdKey="card" dbKey="health_mutual_card_exp_date" />
    )
  },
  {
    label: 'no_health_mutual_code',
    name: ['patient', 'card', 'no_health_mutual_code'],
    rules: [{ type: 'date' }],
    input: <NoCardRadioButton dbKey="no_health_mutual_code" />
  }
];
