import PropTypes from 'prop-types';
import { useState } from 'react';
import { Dropdown, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentModalAdministrativeDoc } from './ModalsDropdown/ContentModalAdministrativeDoc';
import { ReportComments } from './ModalsDropdown/Modals/ReportComments';
import { AddAdministrativeComments } from './ModalsDropdown/Modals/AddAdministrativeComment';
import { ContentModalPrescriptionDoc } from './ModalsDropdown/ContentModalPrescriptionDoc';

/**
 * Renders a dropdown menu with various options for a patient record.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.record - The patient record object.
 * @param {string} props.record.first_name - The first name of the patient.
 * @param {string} props.record.last_name - The last name of the patient.
 * @returns {JSX.Element} The rendered dropdown menu component.
 */
export const ListDropDown = ({ record }) => {
  const [openModal, setOpenModal] = useState({
    key: null,
    title: '',
    content: null
  });
  const resource = 'patients';
  const { t } = useTranslation();

  const modalInfo = {
    add_comment: {
      content: (
        <AddAdministrativeComments
          record={record?.patient}
          setOpenModal={setOpenModal}
        />
      )
    },
    report_patient: {
      content: (
        <ReportComments record={record?.patient} setOpenModal={setOpenModal} />
      )
    },
    administrative_documents: {
      content: (
        <ContentModalAdministrativeDoc record={record} resource={resource} />
      )
    },
    prescriptions: {
      content: (
        <ContentModalPrescriptionDoc record={record} resource={resource} />
      )
    }
  };

  const handleMenuClick = (e) => {
    const key = e.key;
    if (modalInfo[key]) {
      setOpenModal({ key, ...modalInfo[key] });
    }
  };

  const handleModalClose = () => {
    setOpenModal({ key: null, content: null });
  };

  const items = [
    {
      label: t(`${resource}.list.dropdown.add_comment`),
      key: 'add_comment'
    },
    {
      label: t(`${resource}.list.dropdown.report_patient`),
      key: 'report_patient'
    },
    {
      label: t(`${resource}.list.dropdown.administrative_documents`),
      key: 'administrative_documents'
    },
    {
      label: t(`${resource}.list.dropdown.prescriptions`),
      key: 'prescriptions'
    }
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick
  };

  return (
    <>
      <Dropdown.Button menu={menuProps} type="link" />
      {openModal.key && (
        <Modal
          width="60%"
          title={openModal.title}
          open={!!openModal.key}
          onCancel={handleModalClose}
          footer={null}
        >
          {openModal.content}
        </Modal>
      )}
    </>
  );
};

ListDropDown.propTypes = {
  record: PropTypes.shape({
    patient: {
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }
  }).isRequired
};
