import { useTranslation } from 'react-i18next';
import { Form, Select } from 'antd';
import { useConsultationsContext } from '../../ConsultationsContext';

const { Item } = Form;

export const SelectRoom = () => {
  const { t } = useTranslation();
  const { subCenterRooms, selectedVisitValue } = useConsultationsContext();

  const conditions = {
    FIRST_VISIT: () => [
      {
        label: subCenterRooms?.first_visit_waiting_room?.title,
        value: subCenterRooms?.first_visit_waiting_room?._id
      }
    ],
    FOLLOW_UP_VISIT: () => [
      {
        label: subCenterRooms?.follow_up_waiting_room?.title,
        value: subCenterRooms?.follow_up_waiting_room?._id
      }
    ],
    RETURN_VISIT: () => [
      {
        label: subCenterRooms?.return_visit_waiting_room?.title,
        value: subCenterRooms?.return_visit_waiting_room?._id
      }
    ]
  };
  const options = (conditions[selectedVisitValue] || (() => []))();

  return (
    <Item name={['waiting_room']}>
      <Select
        allowClear
        style={{
          width: '100%'
        }}
        placeholder={t('consultations.form.multiple_select_placeholder')}
        options={options}
      />
    </Item>
  );
};
