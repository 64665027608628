import { Input, Form, Select } from 'antd';

/**
 * Custom hook for generating form fields for directory data.
 * This hook provides an array of form field configurations for managing directory entries.
 *
 * @returns {Object} An object containing fields configuration for directory data.
 * @returns {Array} An array of form field configurations for directory data.
 * @returns {Object} fields - The array of form field configurations.
 * @returns {Array} fields.name - The name of the form field.
 * @returns {Array} fields.rules - The validation rules for the form field.
 * @returns {JSX.Element} fields.input - The input component for the form field.
 */
const { Option } = Select;

const useDirectoryFields = () => {
  const fields = [
    {
      name: ['label'],
      rules: [{ required: true }],
      input: <Input />
    },
    {
      name: ['contact'],
      input: <Input />
    },
    {
      name: ['short_number'],
      input: <Input type="number" />
    },
    {
      name: ['phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['mobile_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['mobile_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['mobile_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['email'],
      input: <Input />
    }
  ];

  return {
    fields
  };
};

export default useDirectoryFields;
