import { Typography } from 'antd';
import PropTypes from 'prop-types';

const { Paragraph, Title } = Typography;

/**
 * A component that displays a row of specialist consultations.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.resource - The resource to be used for translation.
 * @param {Function} props.t - The translation function.
 * @param {Array<string>} props.specialistConsultation - The array of specialist consultations to be displayed.
 *
 * @returns {JSX.Element} The RowSpecialistConsultation component.
 */
export const RowSpecialistConsultation = ({
  resource,
  t,
  specialistConsultation
}) => (
  <>
    <Title level={5}>
      {t(`${resource}.medical_consultation.specialist_consultation`)}
    </Title>
    <Paragraph>{specialistConsultation}</Paragraph>
  </>
);

RowSpecialistConsultation.propTypes = {
  resource: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  specialistConsultation: PropTypes.string
};

RowSpecialistConsultation.defaultProps = {
  specialistConsultation: ''
};
