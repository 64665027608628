import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import { ConsultationsContext } from '../../ConsultationsContext';

export const MedicalBackgroundSelect = ({ dbKey, defaultValue }) => {
  const { t } = useTranslation();
  const { Item } = Form;
  const { enums } = useContext(ConsultationsContext);

  let options;
  switch (dbKey) {
    case 'allergies':
      options = enums?.allergies?.map((item) => ({
        label: item.name,
        value: item.name
      }));
      break;
    case 'surgical_history':
      options = enums?.surgical_history?.map((item) => ({
        label: item.name,
        value: item.name
      }));
      break;
    case 'medical_history':
      options = enums?.medical_history?.map((item) => ({
        label: item.name,
        value: item.name
      }));
      break;
    default:
      break;
  }

  return (
    <Item name={['consultations', 'medicalBackground', `${dbKey}`]}>
      <Select
        mode="multiple"
        allowClear
        style={{
          width: '100%'
        }}
        placeholder={t('consultations.form.multiple_select_placeholder')}
        options={options}
        defaultValue={defaultValue}
      />
    </Item>
  );
};
MedicalBackgroundSelect.propTypes = {
  dbKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

MedicalBackgroundSelect.defaultProps = {
  defaultValue: ''
};
