import { useCallback, useEffect, useState, forwardRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { useGenerateFormItem } from '../../utils/generateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';

export const CreateUpdateContainer = forwardRef(
  (
    {
      layout,
      purpose,
      fields,
      loadingFields,
      resource,
      baseUrl,
      config,
      formExtra,
      tradKey,
      submitLabel,
      customSubmit,
      isParentLoading,
      isFormExtra,
      isPageHeaderCustom,
      navigateCustom,
      customUrl
    },
    ref
  ) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { message } = useErrorMessage();
    const { dispatchAPI, user } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const generateFields = useGenerateFormItem();
    const [form] = Form.useForm();
    const { onGetResource, onCreateResource, onUpdateResource } = config;

    const updateResource = async (body) => {
      setIsSubmitting(true);
      try {
        await dispatchAPI('PATCH', {
          url: customUrl || `${baseUrl}/${id}`,
          body:
            onUpdateResource && onUpdateResource.setBody
              ? onUpdateResource.setBody(body)
              : body
        });
        navigate(-1);
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const createResource = async (body) => {
      setIsSubmitting(true);
      try {
        const updatedBody = {
          ...body,
          written_by: `${user?.first_name} ${user?.last_name}`
        };
        await dispatchAPI('POST', {
          url: `${baseUrl}`,
          body:
            onCreateResource && onCreateResource.setBody
              ? onCreateResource.setBody(updatedBody)
              : updatedBody
        });
        if (navigateCustom) {
          navigate(customUrl);
        } else {
          navigate(-1);
        }
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const getResource = useCallback(async () => {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: customUrl || `${baseUrl}/${id}`
        });
        form.setFieldsValue(
          onGetResource && onGetResource.setFields
            ? onGetResource.setFields(data)
            : data
        );
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    }, [purpose, id, loadingFields, baseUrl]);

    useEffect(() => {
      if (purpose !== 'create' && purpose !== 'createOption' && id) {
        setIsLoading(true);
        if (!loadingFields)
          (async () => {
            await getResource();
          })();
      }
    }, [getResource]);

    const handleSubmit = async (values) => {
      if (customSubmit) customSubmit(values);
      if (purpose === 'edit') await updateResource(values);
      if (purpose === 'create') await createResource(values);
    };

    return (
      <>
        {isPageHeaderCustom ? (
          <PageHeaderCustom title={t(`${resource}.form.title.${purpose}`)} />
        ) : null}
        <ContentCustom>
          <Spin spinning={isLoading || isParentLoading}>
            <Form
              ref={ref}
              {...formItemLayout}
              onFinish={handleSubmit}
              form={form}
              layout={layout || 'vertical'}
            >
              {fields.map((field) =>
                generateFields(tradKey || resource, field)
              )}
              {formExtra}
              {!isFormExtra ? (
                <Form.Item {...tailFormItemLayout}>
                  <Row justify="end">
                    <Button
                      style={{ margin: '0 10px' }}
                      type="link"
                      danger
                      onClick={() => navigate(-1)}
                    >
                      {`${t('buttons.cancel')} `}
                      <CloseOutlined />
                    </Button>
                    <Button type="add" htmlType="submit" loading={isSubmitting}>
                      {`${t(submitLabel || 'buttons.save')} `}
                      <CheckOutlined />
                    </Button>
                  </Row>
                </Form.Item>
              ) : null}
            </Form>
          </Spin>
        </ContentCustom>
      </>
    );
  }
);

CreateUpdateContainer.propTypes = {
  purpose: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  layout: PropTypes.string,
  loadingFields: PropTypes.bool,
  isPageHeaderCustom: PropTypes.bool,
  isFormExtra: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  isParentLoading: PropTypes.bool,
  navigateCustom: PropTypes.bool,
  customUrl: PropTypes.string
};

CreateUpdateContainer.defaultProps = {
  config: {},
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  submitLabel: null,
  customSubmit: null,
  isParentLoading: false,
  isPageHeaderCustom: false,
  isFormExtra: false,
  layout: 'vertical',
  navigateCustom: false,
  customUrl: null
};
