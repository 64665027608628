import { BaseModel } from './BaseModel';

/**
 * Represents a Patient model.
 * @class
 * @extends BaseModel
 */
export class Patient extends BaseModel {
  /**
   * Creates a new instance of the Patient model.
   * @param {Object} data - The data for the patient.
   */
  constructor(data) {
    super('patient', undefined, data);
  }

  /**
   * The name of the model.
   * @type {string}
   */
  static modelName = 'patients';

  /**
   * Retrieves a single patient by ID.
   * @param {string} id - The ID of the patient.
   * @param {Object} options - Additional options for the request.
   * @param {string} options.populate - The fields to populate in the response.
   * @returns {Promise} A promise that resolves with the patient data.
   */
  static getOneById(id, { ...params } = {}) {
    return BaseModel.dispatchAPI('GET', {
      url: `${Patient.modelName}/${id}`,
      params: { ...params }
    });
  }

  /**
   * Patch transmitted medications for a patient.
   * @static
   * @function
   *
   * @param {string} id - The ID of the patient.
   * @param {Array} paramedicalsTransmissions - The array of transmitted medications.
   * @returns {Promise} A promise that resolves with the API response.
   */
  static patchTransmittedMedications(id, { ...paramedicalsTransmissions }) {
    return BaseModel.fetchAPI(
      'PATCH',
      `${Patient.modelName}/${id}/transmissions`,
      { ...paramedicalsTransmissions }
    );
  }

  /**
   * Patch the current medications of a patient.
   * @static
   * @function
   *
   * @param {string} id - The ID of the patient.
   * @param {object} medications - The medications to be patched.
   * @param {Array} medications.notCurrentMedications - The list of medications that are no longer current.
   * @param {object} medications.currentMedications - The updated current medications.
   * @returns {Promise} A promise that resolves with the API response.
   */
  static patchCurrentMedications(
    id,
    { notCurrentMedications, ...currentMedications }
  ) {
    return BaseModel.fetchAPI(
      'PATCH',
      `${Patient.modelName}/${id}/current-medications`,
      { notCurrentMedications, ...currentMedications }
    );
  }

  /**
   * Retrieves the enums for the patient model.
   * @returns {Promise} A promise that resolves with the enum data.
   */
  static getEnums() {
    return BaseModel.fetchAPI('GET', `${Patient.modelName}/enums`);
  }
}
