import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

const { Option } = Select;

/**
 * A component that renders a select dropdown for choosing patient rooms.
 *
 * @component
 * @param {Object} record - The patient record object.
 * @param {string} record._id - The ID of the patient record.
 * @param {string} record.room - The current room of the patient.
 * @returns {JSX.Element} The rendered component.
 */
export const RoomsSelect = ({ record }) => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [rooms, setRooms] = useState([]);
  const subCenterId = user?.selected_sub_center || user?.default_sub_center;

  const getRooms = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/rooms/sub-center/${subCenterId}`
      });
      setRooms(data);
    } catch (error) {
      message(error);
    }
  };

  const changePatientRoom = async (targeted_room) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/rooms/change-patient-room/${record.room}`,
        body: {
          targeted_room_id: targeted_room,
          user_last_name: user.last_name,
          user_first_name: user.first_name,
          consultation_id: record._id
        }
      });
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getRooms();
    })();
  }, []);

  return record.status === 'CLOSED' ? (
    t('patients.form.messages.already_discharged')
  ) : (
    <Select
      loading={!rooms.length}
      defaultValue={record.room}
      onChange={changePatientRoom}
      style={{ width: '100%' }}
    >
      {rooms.map((room) => (
        <Option key={room._id} value={room._id}>
          {room.title}
        </Option>
      ))}
    </Select>
  );
};

RoomsSelect.propTypes = {
  record: PropTypes.shape({
    _id: PropTypes.string,
    room: PropTypes.string,
    status: PropTypes.string
  })
};

RoomsSelect.defaultProps = {
  record: {
    _id: '',
    room: '',
    status: ''
  }
};
