import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Collapse, Card } from 'antd';
import { MedicalComments } from './MedicalComments/MedicalComments';
import { PreviousComments } from '../../../components/PreviousComments/PreviousComments';
import { ConsultationsContext } from '../ConsultationsContext';

const { Panel } = Collapse;

export const ShowMedicalComments = ({ fields }) => {
  const resource = 'consultations';
  const { t } = useTranslation();
  const { patient } = useContext(ConsultationsContext);
  const medicalComments = patient?.current_consultation?.medical_comments;

  return (
    <Collapse expandIconPosition="end">
      <Panel
        header={<h3>{t('consultations.form.medical_comments')}</h3>}
        key="1"
      >
        {medicalComments
          ? medicalComments.map((data) => (
              <PreviousComments
                key={data.description + data.written_by}
                written_by={data.written_by}
                date={data.date}
                comment={data.description}
              />
            ))
          : null}
        <Card>
          <MedicalComments resource={resource} fields={fields} />
        </Card>
      </Panel>
    </Collapse>
  );
};
ShowMedicalComments.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({}))
};
ShowMedicalComments.defaultProps = {
  fields: []
};
