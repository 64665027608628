import { useEffect, useState, useCallback } from 'react';
import { Spin, Row } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { KanbanColumn } from './KanbanColumn';
import { useSocketContext } from '../../contexts/SocketContext';

/**
 * `HomeDoctorKanban` is a component that displays the kanban board for the doctor.
 * It contains all the rooms and the patients in each room.
 *
 * @component
 *
 * @returns {JSX.Element} JSX.Element
 *
 */
export const HomeDoctorKanban = () => {
  const { user, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { socket } = useSocketContext();
  const [columns, setColumns] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const subCenterId = user?.selected_sub_center || user?.default_sub_center;

  const getKanbanColumns = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/kanban-columns/${subCenterId}`
      });

      const columnsToDisplay = data.filter(
        (column) => column.title !== 'SALLES NON AFFICHÉES'
      );

      setColumns(columnsToDisplay);
    } catch (error) {
      return message(error);
    }
    return null;
  }, [refresh, subCenterId]);

  // Refresh the kanban columns with the socket when a patient is moved to another room
  useEffect(() => {
    if (socket) {
      socket.on('changePatientRoom', () => {
        setRefresh((prevRefresh) => !prevRefresh);
      });
    }
  }, [socket]);

  useEffect(() => {
    (async () => {
      await getKanbanColumns();
    })();
  }, [getKanbanColumns]);

  return (
    <Spin size="large" spinning={!columns}>
      <DndProvider backend={HTML5Backend}>
        <Row className="kanban-row" gutter={[12, 12]}>
          {columns?.map((column) => (
            <KanbanColumn
              key={column._id}
              setRefresh={setRefresh}
              column={column}
            />
          ))}
        </Row>
      </DndProvider>
    </Spin>
  );
};
