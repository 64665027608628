/**
 * Translates category keys to corresponding tree keys.
 * @function
 *
 * @param {Object} options - The options object.
 * @param {Function} options.setSrnCareTreeKeys - The function to set SRN_CARE tree keys.
 * @param {Function} options.setImagingTreeKeys - The function to set IMAGING tree keys.
 * @param {Function} options.setBiologyTreeKeys - The function to set BIOLOGY tree keys.
 * @returns {Object} - The translated category keys with corresponding tree keys.
 */
export const categoriesKeysTranslated = ({
  setSrnCareTreeKeys,
  setImagingTreeKeys,
  setBiologyTreeKeys
}) => ({
  SRN_CARE: {
    setTreeKeys: setSrnCareTreeKeys
  },
  IMAGING: {
    setTreeKeys: setImagingTreeKeys
  },
  BIOLOGY: {
    setTreeKeys: setBiologyTreeKeys
  }
});
