import { useTranslation } from 'react-i18next';
import { getPhoneNumber } from '../../utils/formatters';
/**
 * Custom hook for generating columns for directory data table.
 * This hook provides columns configuration for displaying directory data.
 *
 *
 * @hook
 * @returns {Array} An array of column configurations for directory data table.
 */
export const useColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('directories.form.label').toUpperCase(),
      key: 'label',
      dataIndex: 'label',
      sorter: true
    },
    {
      title: t('directories.form.contact').toUpperCase(),
      key: 'contact',
      dataIndex: 'contact',
      sorter: true
    },
    {
      title: t('directories.form.short_number').toUpperCase(),
      key: 'short_number',
      dataIndex: 'short_number'
    },
    {
      title: t('directories.form.phone_number').toUpperCase(),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (phone_number) => getPhoneNumber(phone_number)
    },
    {
      title: t('directories.form.mobile_number').toUpperCase(),
      key: 'mobile_number',
      dataIndex: 'mobile_number',
      render: (mobile_number) => getPhoneNumber(mobile_number)
    },
    {
      title: t('directories.form.email').toUpperCase(),
      key: 'email',
      dataIndex: 'email',
      render: (email) => email || '-'
    }
  ];
};
