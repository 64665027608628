import { useTranslation } from 'react-i18next';
import { Card, Input } from 'antd';
import { MobileFilled } from '@ant-design/icons';

const { Search } = Input;

/**
 * @component
 * @description Component that displays the teleservices card
 * @returns {JSX.Element} Teleservices card
 */
// Work in progress
export const TeleservicesCard = () => {
  const { t } = useTranslation();
  const translationKey = 'prescription.cards.teleservices';

  return (
    <Card
      style={{ width: '100%' }}
      title={
        <div>
          <MobileFilled style={{ marginRight: '8px' }} />
          {t(`${translationKey}.title`)}
        </div>
      }
    >
      <Search placeholder={t('prescription.cards.placeholder')} />
    </Card>
  );
};
