import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { message as AntdMessage } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { PrescriptionForm } from '../../components/FreeTextPrescriptionForm/PrescriptionForm';

/**
 * @component CreateUpdateFavoriteFreeTextPrescription
 * @returns {React.Component} Page to create a favorite free text prescription
 */
export const CreateUpdateFavoriteFreeTextPrescription = () => {
  const { type } = useParams();
  const navigate = useNavigate();
  const { user, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [content, setContent] = useState('');
  const [label, setLabel] = useState('');
  const [loading, setLoading] = useState(false);
  const translationKey = 'prescription.favorites.form';

  /**
   * Create the body of the prescription.
   *
   * @function
   * @returns {Object|null} Returns the body of the prescription if it is valid,
   *                       otherwise returns null.
   */
  const createBody = () => {
    if (!content) {
      return null;
    }

    return {
      author: user?._id,
      authorized: [user?._id],
      type,
      label,
      purpose: 'FREE-TEXT',
      content: `<body>${content}</body>`
    };
  };

  const onFinish = async () => {
    try {
      setLoading(true);

      await dispatchAPI('POST', {
        url: '/prescriptions/favorites',
        body: createBody()
      });
      navigate(-1);
      return AntdMessage.success(t('success.message.prescription.create'));
    } catch (error) {
      return message(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PrescriptionForm
      createBody={createBody}
      onSubmit={onFinish}
      content={content}
      setContent={setContent}
      label={label}
      setLabel={setLabel}
      title={t(`${translationKey}.titles.${type}`)}
      translationKey={translationKey}
      loading={loading}
    />
  );
};
