import PropTypes from 'prop-types';
import { TreeTransfer } from '../../../../../components/TreeTransfer/TreeTransfer';

/**
 * Transfer component for prescriptions.
 * @component
 * @param {Object} props - The component props.
 * @param {string[]} props.targetKeys - Array of target keys.
 * @param {Function} props.setTargetKeys - Function to set target keys.
 * @param {Object[]} props.stayPrescriptions - Array of stay prescriptions data.
 * @returns {JSX.Element} JSX.Element.
 */
export const PrescriptionsTranfer = ({
  targetKeys,
  setTargetKeys,
  stayPrescriptions
}) => {
  const onChange = (keys) => {
    setTargetKeys(keys);
  };
  return (
    <TreeTransfer
      dataSource={stayPrescriptions}
      targetKeys={targetKeys}
      onChange={onChange}
    />
  );
};

PrescriptionsTranfer.propTypes = {
  targetKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTargetKeys: PropTypes.func.isRequired,
  stayPrescriptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
