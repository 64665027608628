import dayjs from 'dayjs';

/**
 * Custom hook that generates a list of labels and their corresponding content based on the provided data.
 * @param {Object} data - The data object containing the patient information.
 * @returns {Array} - An array of label objects with their corresponding content.
 */
export const useListContent = (data = {}) => {
  const {
    first_name,
    last_name,
    email,
    phone_number_fix,
    phone_number_mobile,
    phone_number_trustworthy_person,
    birth_date
  } = data;

  const labels = [
    {
      label: 'patients.form.first_name',
      span: 1,
      content: (first_name && first_name) || '-'
    },
    {
      label: 'patients.form.last_name',
      span: 1,
      content: (last_name && last_name) || '-'
    },
    {
      label: 'patients.form.email',
      span: 1,
      content: (email && email) || '-'
    },
    {
      label: 'patients.form.phone_number.number_fix',
      span: 1,
      content:
        phone_number_fix && phone_number_fix.number ? (
          <div>
            {`${phone_number_fix.country_code} ${phone_number_fix.number.slice(
              0,
              1
            )} ${phone_number_fix.number.slice(1).replace(/(.{2})/g, '$1 ')}
                `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: 'patients.form.phone_number.number_mobile',
      span: 1,
      content:
        phone_number_mobile && phone_number_mobile.number ? (
          <div>
            {`${
              phone_number_mobile.country_code
            } ${phone_number_mobile.number.slice(
              0,
              1
            )} ${phone_number_mobile.number.slice(1).replace(/(.{2})/g, '$1 ')}
                `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: 'patients.form.phone_number.number_trustworthy_person',
      span: 1,
      content:
        phone_number_trustworthy_person &&
        phone_number_trustworthy_person.number ? (
          <div>
            {`${
              phone_number_trustworthy_person.country_code
            } ${phone_number_trustworthy_person.number.slice(
              0,
              1
            )} ${phone_number_trustworthy_person.number
              .slice(1)
              .replace(/(.{2})/g, '$1 ')}
                  `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: 'patients.form.birthdate',
      span: 1,
      content: (birth_date && dayjs(birth_date).format('DD/MM/YYYY')) || '-'
    }
  ];

  return labels;
};
