import PropTypes from 'prop-types';
import { Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStellairContext } from '../../../../contexts/StellairContext';

const resource = 'patients';

/**
 * A button component used for retrieving patient data when he or she does not have a vitale card.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object.
 * @returns {JSX.Element} - The rendered button component.
 */
export const InsiButton = ({ form }) => {
  const { t } = useTranslation();
  const { getPatientData } = useStellairContext();

  return (
    <Row justify="end">
      <Button
        onClick={() => getPatientData(form)}
        style={{
          borderRadius: '15px'
        }}
      >
        {t(`${resource}.form.insi_button`)}
      </Button>
    </Row>
  );
};

InsiButton.propTypes = {
  form: PropTypes.shape({}).isRequired
};
