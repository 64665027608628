import dayjs from 'dayjs';

/**
 * Get the age of a patient
 *
 * @function
 * @param {string} dateString - The date of birth of the patient
 * @returns {Object} - The age of the patient and if the patient is a child
 */
export const getAge = (dateString) => {
  const today = dayjs();
  const birthDate = dayjs(dateString);
  const conversion = today.diff(birthDate, 'years');
  const isChild = conversion <= 13;
  return { age: conversion, isChild };
};
