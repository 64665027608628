import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CreateUpdateSubCenter } from './CreateUpdateSubCenter';
import { ShowSubCenter } from './ShowSubCenter';
import { ListSubCenters } from './ListSubCenters';
import { SubCenterSettings } from './SubCenterSettings';

export const SubCenterRouter = () => (
  <Routes>
    <Route
      path="/create/:id"
      element={<CreateUpdateSubCenter purpose="create" />}
    />
    <Route
      path="/edit/:id/:centerId"
      element={<CreateUpdateSubCenter purpose="edit" />}
    />
    <Route path="/show/:id" element={<ShowSubCenter />} />
    <Route path="/settings/:id" element={<SubCenterSettings />} />
    <Route path="/" element={<ListSubCenters />} />
  </Routes>
);
