import { MedicineBoxFilled } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import PropsTypes from 'prop-types';

/**
 * A component that displays a stay prescriptions card.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.translationKey - The key to be used for translation.
 * @param {Function} props.t - The translation function.
 * @param {string} props.id - The id of the prescription.
 *
 * @returns {JSX.Element} The StayPrescriptionsCard component.
 */
export const StayPrescriptionsCard = ({ translationKey, id, t }) => (
  <Col span={8}>
    <Link to={`/prescription/prescription-choice/STAY/${id}`}>
      <Card bordered={false} style={{ background: '#8AC7FF66' }} hoverable>
        <Row justify="center">
          <MedicineBoxFilled style={{ fontSize: '3em' }} />
        </Row>
        <Row justify="center">
          <span>{t(`${translationKey}.stay`)}</span>
        </Row>
      </Card>
    </Link>
  </Col>
);

StayPrescriptionsCard.propTypes = {
  translationKey: PropsTypes.string.isRequired,
  id: PropsTypes.string.isRequired,
  t: PropsTypes.func.isRequired
};
