import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button } from 'antd';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { ConsultationsContext } from '../../ConsultationsContext';
import { formItemLayoutCustom } from '../../../../utils/constants/formLayout';
import { usePostConstantsSurvey } from '../../Components/postConstants-survey';

export const ConstantsAndOtherSurveyModal = ({
  resource,
  fields,
  title,
  handleCloseModal,
  idPatient
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();

  const {
    patient: {
      current_consultation = {},
      first_name = '-',
      last_name = '-'
    } = {}
  } = useContext(ConsultationsContext) || {};
  const { _id } = current_consultation;

  const { handleSubmit } = usePostConstantsSurvey(
    handleCloseModal,
    idPatient,
    _id
  );

  return (
    <>
      <Row justify="start">
        <h3>
          {first_name} {last_name} - {t(`consultations.form.${title}`)}
        </h3>
      </Row>
      <Form form={form} onFinish={handleSubmit} {...formItemLayoutCustom}>
        <Row>
          <Col span={24}>
            {fields.map((field) => generateFields(resource, field))}
          </Col>
        </Row>
        <Row justify="end">
          <Button
            style={{
              borderRadius: '15px'
            }}
            onClick={handleCloseModal}
          >
            {' '}
            {t('buttons.cancel')}
          </Button>
          <Button
            htmlType="submit"
            style={{
              borderRadius: '15px'
            }}
          >
            {t('buttons.register')}
          </Button>
        </Row>
      </Form>
    </>
  );
};

ConstantsAndOtherSurveyModal.propTypes = {
  resource: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  handleCloseModal: PropTypes.func.isRequired,
  idPatient: PropTypes.string.isRequired
};
ConstantsAndOtherSurveyModal.defaultProps = {
  fields: [],
  subTitle: ''
};
