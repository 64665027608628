import { useState, useEffect } from 'react';
import { CustomAction } from '../components/CustomAction';
import { CollapseRessources } from '../components/CollapseResources';
import { useColumns } from './columns';
import { GetEnums } from '../../../utils/getEnums';

/**
 * Component for listing Stay Prescriptions and managing their user display settings.
 *
 * @component
 * @returns {JSX.Element} JSX element representing the ListStayPrescriptions component.
 */
export const ListStayPrescriptions = () => {
  const { columns } = useColumns();
  const { getEnums } = GetEnums();
  const [enums, setEnums] = useState([]);
  const resource = 'config-stay-prescriptions';

  const editAction = {
    pathname: (record) =>
      record && record._id ? `/data-base/${resource}/edit/${record._id}` : null
  };

  const fetchEnums = async () => {
    const { categories } = await getEnums(resource);
    setEnums(categories || []);
  };

  useEffect(() => {
    (async () => {
      await fetchEnums();
    })();
  }, []);

  return (
    <>
      <CustomAction resource={resource} />
      {enums.map((category) => (
        <CollapseRessources
          key={category}
          resource={resource}
          translationKey="data_bases.stay_prescriptions"
          category={category}
          columns={columns}
          editAction={editAction}
        />
      ))}
    </>
  );
};
