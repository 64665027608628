import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'antd';
import { CardItem } from './CardItem';
import { useAuthContext } from '../../../../contexts/AuthContext';

export const Kanban = ({ refresh, setRefresh }) => {
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const [columns, setColumns] = useState([]);

  const getKanbanColumns = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `kanban-columns/${id}`
    });
    setColumns(data);
  };

  useEffect(() => {
    (async () => {
      await getKanbanColumns();
    })();
  }, [refresh]);

  return (
    <Row>
      {columns.map((column) => (
        <Col span={6} key={column._id}>
          <CardItem
            _id={column._id}
            title={column.title}
            rooms={column.rooms}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </Col>
      ))}
    </Row>
  );
};

Kanban.propTypes = {
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired
};
