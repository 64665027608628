import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Spin, Button, Form, Col, Tag } from 'antd';
import { FlagFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { usePatientContext } from '../../contexts/PatientContext/PatientContext';
import { useStellairContext } from '../../contexts/StellairContext';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';
import { useCreateUpdateApiCalls } from './components/createUpdateApiCalls';
import { BackButton } from '../../components/BackButton/BackButton';
import { CollapsePatient } from './components/MainAdmin/CollapsePatient';
import { CardPatientCards } from './components/CardsHistory/CardPatientCards';
import { CollapseAmoAmc } from './components/AmoAmc/CollapseAmoAmc';
import { CollapseComments } from './components/AdminAndReportComments/CollapseComments';
import { CollapseUnpaid } from './components/Unpaid/CollapseUnpaid';
import { ExtraButtons } from './ExtraButtons';
import { formItemLayoutCustom } from '../../utils/constants/formLayout';
import { mainFields } from './fields/mainFields';
import { useAdministrativeFields } from './fields/administrativeFields';
import { amoAmcFields } from './fields/amoAmcFields';
import { commentFields } from './fields/commentFields';
import { reportFields } from './fields/reportFields';
import { identityCardFields } from './fields/identityCardFields';
import { insuranceCardFields } from './fields/insuranceCardFields';
import { CollapseDoctorsLetter } from './components/DoctorsLetter/CollapseDoctorsLetter';
import { VitaleCardInformationsModal } from '../../components/Stellair/VitaleCardInformationsModal';
import { ErrorModal } from '../../components/Modals/ErrorModal';
import { useCustomErrorMessage } from './useCustomErrorMessage';
import { InsiButton } from './components/MainAdmin/InsiButton';
import { getBirthDate } from '../../utils/formatters';
import { CollapseTreatmentPrescriptions } from './components/TreatmentPrescriptions/CollapseTreatmentPrescriptions';

const resource = 'patients';

const tagStyle = {
  borderRadius: '50%',
  width: '24px',
  height: '24px',
  padding: 0,
  lineHeight: '24px',
  textAlign: 'center'
};

/**
 * CreateUpdatePatient component.
 *
 * @component
 * @prop {Object} props - The component props.
 * @prop {string} props.purpose - The purpose of the component (e.g., 'create' or 'edit').
 * @returns {JSX.Element} The rendered CreateUpdatePatient component.
 */
export const CreateUpdatePatient = ({ purpose }) => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { setVitaleCardData, patientStellairId } = useStellairContext();
  const { isSubmitting, handleSubmit } = useCreateUpdateApiCalls(resource, id);
  const [address, setAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState({
    success: false,
    error: false
  });
  const [patientGender, setPatientGender] = useState();
  const administrativeFields = useAdministrativeFields(address, setAddress);

  const searchParams = new URLSearchParams(location.search);
  const createConsultation = searchParams.get('createConsultation');

  const {
    patient,
    getPatient,
    doctorsLetterFilesList,
    setDoctorsLetterFilesList,
    treatmentPrescriptions,
    setTreatmentPrescriptions
  } = usePatientContext();

  const {
    report_reception,
    reported,
    comments,
    social_security_center,
    long_term_diseases
  } = patient;

  const setPatientFields = () => {
    if (state?.patient?.patient) {
      const { patient: patientState } = state.patient || {};
      setPatientGender(patientState.gender);
      const birthDate = patientState?.birth_date
        ? dayjs(patientState?.birth_date.$d).toISOString()
        : null;
      const fieldsValue = {
        ...patientState,
        ...(birthDate ? { birth_date: getBirthDate(birthDate) } : {})
      };
      form.setFieldsValue(fieldsValue);
    }
  };

  const patientIsMan = () => {
    if (patient.gender === 'MAN') {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setVitaleCardData(null); // This is to reset the vitaleCardData when the component is unmounted
    if (purpose === 'edit' && id) {
      (async () => {
        setIsLoading(true);
        await getPatient(id);
        setIsLoading(false);
      })();
    }
  }, [purpose, id]);

  useEffect(() => {
    if (Object.keys(patient).length > 2) {
      form.setFieldsValue(patient);
    }
  }, [patient]);

  useEffect(() => {
    setPatientFields();
  }, [state]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handlePregnantChange = () => {
    form.setFieldValue('pregnant', null);
  };

  return (
    <ContentCustom>
      <VitaleCardInformationsModal
        visible={isModalVisible.success}
        setVisible={setIsModalVisible}
        form={form}
        setIsModalVisible={setIsModalVisible}
      />
      <ErrorModal
        visible={isModalVisible.error}
        setVisible={setIsModalVisible}
        customMessage={useCustomErrorMessage()}
      />
      <PageHeaderCustom
        title={t(`${resource}.form.title.${purpose}`)}
        extra={<ExtraButtons setIsModalVisible={setIsModalVisible} />}
      />
      <Spin spinning={isLoading}>
        <Form
          onKeyDown={handleKeyPress}
          scrollToFirstError
          form={form}
          onFinish={(values) =>
            handleSubmit(
              { ...values, address, stellair_uuid: patientStellairId },
              purpose,
              createConsultation
            )
          }
          {...formItemLayoutCustom}
        >
          <Row justify="space-between" gutter={[16, 16]}>
            <Col xs={24} lg={14} xl={16} xxl={18}>
              <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
                <CollapsePatient
                  form={form}
                  purpose={purpose}
                  fields={mainFields(
                    patientIsMan(),
                    handlePregnantChange,
                    patientGender
                  )}
                  header={t(`${resource}.form.collapse_main`)}
                  defaultActiveKey={['1']}
                  extra={<InsiButton form={form} />}
                />
                <CollapsePatient
                  form={form}
                  fields={administrativeFields}
                  header={t(`${resource}.form.collapse_admin`)}
                  defaultActiveKey={['1']}
                />
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Row gutter={[16, 16]}>
                    <CollapseAmoAmc
                      fields={amoAmcFields({
                        social_security_center,
                        long_term_diseases
                      })}
                      header={t(`${resource}.form.collapse_amo_amc_datas`)}
                      longTermDiseases={long_term_diseases}
                    />
                    <CollapseComments
                      comments={comments}
                      fields={commentFields}
                      commentType="description"
                      header={
                        <>
                          {t(
                            `${resource}.form.collapse_administrative_comments`
                          )}{' '}
                          {comments?.length > 0 && (
                            <Tag color="#ADD8E6" style={tagStyle}>
                              {comments?.length}
                            </Tag>
                          )}
                        </>
                      }
                    />
                    {reported && (
                      <CollapseComments
                        comments={report_reception}
                        fields={reportFields}
                        commentType="reported_comment_reception"
                        header={
                          <>
                            {t(`${resource}.form.collapse_reported`)}{' '}
                            <FlagFilled style={{ color: 'red' }} />{' '}
                            <Tag color="#ADD8E6" style={tagStyle}>
                              {report_reception?.length}
                            </Tag>
                          </>
                        }
                      />
                    )}
                    <CollapseUnpaid
                      header={t(`${resource}.form.collapse_unpaid`)}
                    />
                  </Row>
                </Col>
                <CollapseDoctorsLetter
                  header={t(`${resource}.form.collapse_doctors_letter`)}
                  filesList={doctorsLetterFilesList}
                  setFilesList={setDoctorsLetterFilesList}
                />
                <CollapseTreatmentPrescriptions
                  header={t(
                    `${resource}.form.collapse_treatment_prescriptions`
                  )}
                  filesList={treatmentPrescriptions}
                  setFilesList={setTreatmentPrescriptions}
                />
              </Row>
            </Col>
            <Col xs={24} lg={10} xl={8} xxl={6}>
              <CardPatientCards
                fieldsId={identityCardFields}
                fieldsInsurance={insuranceCardFields}
              />
            </Col>
          </Row>
          <Row justify="end" style={{ marginTop: 16 }}>
            <BackButton />
            <Button type="add" htmlType="submit" loading={isSubmitting}>
              {`${t('buttons.register')}`}
            </Button>
          </Row>
        </Form>
      </Spin>
    </ContentCustom>
  );
};
CreateUpdatePatient.propTypes = {
  purpose: PropTypes.string.isRequired
};
