import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Image, Row, Tag } from 'antd';
import { CompareButton } from '../components/CompareButton';

/**
 * @hook
 * @name useColumnsPhotos
 * @description A custom hook to return columns for photos table
 * @param {Boolean} isSwitched - A boolean to check if the table is in switched mode
 * @param {Array} consultationsWithPhotos - An array of consultations with photos
 * @returns {Array} columns
 */
export const useColumnsPhotos = (isSwitched, consultationsWithPhotos) => {
  const { t } = useTranslation();
  const formatDate = (date) => dayjs(date).format('DD-MM-YYYY');

  const insightColumn = {
    title: t('consultations.documents.insight'),
    key: 'insight',
    dataIndex: 'photo',
    render: (photo) =>
      photo.map((p) => (
        <Image width={40} height={40} src={p?.metadata?.type} key={p?._id} />
      ))
  };

  const reasonColumn = {
    title: t('consultations.reason'),
    key: 'diagnostic',
    dataIndex: 'diagnostic',
    sorter: true,
    render: (diagnostic, record) => {
      const displayValue =
        diagnostic && diagnostic.length > 0 ? (
          diagnostic.join(', ')
        ) : (
          <Tag color="#87d068" style={{ borderRadius: '15px' }}>
            {t('consultations.form.current_consultation')}
          </Tag>
        );

      const content =
        record.rowSpan > 1 ? (
          <>
            <Row>{displayValue}</Row>
            <Row>
              <CompareButton
                consultationsWithPhotos={consultationsWithPhotos}
                record={record}
              />
            </Row>
          </>
        ) : (
          displayValue
        );

      return content;
    },

    onCell: (record, rowIndex) => {
      const props = {};
      if (
        rowIndex > 0 &&
        consultationsWithPhotos[rowIndex - 1].diagnostic[0] ===
          record.diagnostic[0]
      ) {
        props.colSpan = 0;
      }
      if (record.rowSpan > 1) {
        props.rowSpan = record.rowSpan;
      }
      return props;
    }
  };

  const dateColumn = {
    title: t('consultations.date'),
    key: 'date',
    dataIndex: 'date',
    sorter: true,
    render: (prescription_date) =>
      prescription_date ? formatDate(prescription_date) : '-'
  };

  return isSwitched
    ? [insightColumn, reasonColumn, dateColumn]
    : [insightColumn, dateColumn, reasonColumn];
};
