import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * Database methods for interacting with the API.
 * @function
 * @param {string} searchValue - The search value to use when retrieving prescriptions.
 * @returns {Object} An object containing database methods.
 */
export const DataBaseMethods = (searchValue) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  /**
   * Retrieves prescriptions from the database.
   *
   * @function
   * @async
   * @param {string} resource - The resource to retrieve prescriptions from. (ex: 'config-stay-prescriptions')
   * @param {string} [filters=null] - Optional - The filters to apply when retrieving prescriptions. (ex: '?archived=false')
   * @param {function} [setLoading=null] - Optional - loading state setter function. (ex: setLoading)
   * @returns {Promise<Array>} - A promise that resolves to an array of prescriptions. (ex: [{ _id: 'ObjectId', ... }])
   */
  const getPrescriptions = async (
    resource,
    filters = null,
    setLoading = null
  ) => {
    try {
      if (setLoading) setLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/${resource}${searchValue ? `/search/${searchValue}` : ''}${
          filters ? `/${filters}` : ''
        }`
      });
      if (data && data.length > 0) return data;
    } catch (error) {
      message(error);
    } finally {
      if (setLoading) setLoading(false);
    }
    return [];
  };

  /**
   * Updates the user display for a resource.
   * @function
   * @async
   * @param {string} resource - The resource name. (ex: 'visit-reasons')
   * @param {boolean} checked - The new user display value. (true or false)
   * @param {string} id - The ID of the resource. (ex: 'ObjectId')
   * @param {string} [path=''] - Optional - The optional path for the resource. (ex: '/option-display')
   * @returns {Promise} The result of the patch operation.
   */
  const onChangeUserDisplay = async (resource, checked, id, path = '') => {
    if (!resource || !id || typeof checked !== 'boolean') {
      return;
    }

    try {
      await dispatchAPI('PATCH', {
        url: `/${resource}${path}/${id}`,
        body: {
          user_display: checked
        }
      });
    } catch (error) {
      message(error);
    }
  };

  return {
    getPrescriptions,
    onChangeUserDisplay
  };
};
