import PropTypes from 'prop-types';
import { Card, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Kanban } from '../Kanban/Kanban';

const { Panel } = Collapse;

export const KanbanColumns = ({ refresh, setRefresh }) => {
  const { t } = useTranslation();
  return (
    <Card style={{ marginBottom: 24 }}>
      <Collapse expandIconPosition="end" defaultActiveKey={['5']} ghost>
        <Panel header={t('sub_centers.settings.kanban_columns')} key="5">
          <DndProvider backend={HTML5Backend}>
            <div>
              <Kanban refresh={refresh} setRefresh={setRefresh} />
            </div>
          </DndProvider>
        </Panel>
      </Collapse>
    </Card>
  );
};

KanbanColumns.propTypes = {
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func
};

KanbanColumns.defaultProps = {
  refresh: false,
  setRefresh: () => {}
};
