import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { Datatable } from '../../components';

/**
 * Renders a table displaying center users.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const ShowCenterUsersTable = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState({});
  const [subCenters, setSubCenters] = useState([]);

  const columns = [
    {
      title: t('users.form.last_name').toUpperCase(),
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: true
    },
    {
      title: t('users.form.first_name').toUpperCase(),
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.position').toUpperCase(),
      dataIndex: 'position',
      key: 'position',
      sorter: true,
      render: (position) => position && t(`users.positions.${position}`),
      filters:
        enums.positions &&
        enums.positions.map((position) => ({
          key: position,
          text: t(`users.positions.${position}`),
          value: position
        }))
    },
    {
      title: t('users.form.email').toUpperCase(),
      dataIndex: 'email',
      key: 'email',
      sorter: true
    },
    {
      title: t('users.form.phone_number.number').toUpperCase(),
      dataIndex: 'phone_number',
      key: 'phone_number',
      sorter: true,
      render: (phone_number) =>
        phone_number &&
        phone_number.number && (
          <div>
            {`${phone_number.country_code} ${phone_number.number.slice(
              0,
              1
            )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}`}
          </div>
        )
    },
    {
      title: t('users.form.default_sub_center').toUpperCase(),
      dataIndex: 'default_sub_center',
      key: 'default_sub_center',
      sorter: true,
      render: (default_sub_center) =>
        default_sub_center && default_sub_center.name,
      filters:
        subCenters &&
        subCenters.map((subCenter) => ({
          key: subCenter._id,
          text: subCenter.name,
          value: subCenter._id
        }))
    }
  ];

  const getEnums = async () => {
    const { data } = await dispatchAPI('GET', {
      url: '/users/enums'
    });
    setEnums(data);
  };

  const getSubCentersByCenterId = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `/sub-centers/center/${id}`
    });
    setSubCenters(data);
  };

  useEffect(() => {
    (async () => {
      await getEnums();
      await getSubCentersByCenterId();
    })();
  }, []);

  return (
    <Col span={24}>
      <Datatable
        columns={columns}
        resourceName={`users/center/${id}`}
        path="/users"
        populate="default_sub_center"
        extraQuery="archived=false"
        deleteAction={false}
      />
    </Col>
  );
};

export default ShowCenterUsersTable;
