import { useEffect, useState } from 'react';
import { Row, Card, Form, Select, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { List, Item } = Form;

/**
 * @hook
 * @name useFields
 * @description A custom hook to return fields for radiology prescription form
 * @returns {Array} fields
 */
export const useFields = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState({});
  const [displaySelect, setDisplaySelect] = useState(false);

  const getRadiologyEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/prescriptions/radiology_enums'
      });
      setEnums(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getRadiologyEnums();
    })();
  }, []);

  return [
    {
      noLabel: true,
      name: ['radiology_prescriptions'],
      rules: [{ required: true }],
      input: (
        <List name={['radiology_prescriptions']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Card
                  style={{ marginBottom: 8 }}
                  title={
                    <Row justify="space-between">
                      {t('prescription.radiology.form.title', {
                        index: index + 1
                      })}
                      <CloseOutlined onClick={() => remove(field.name)} />
                    </Row>
                  }
                >
                  <Row key={`${field.name}_region`} align="middle">
                    <Item
                      {...field}
                      name={[field.name, 'region']}
                      key={[field.name]}
                    >
                      <Select
                        onChange={(value) =>
                          value
                            ? setDisplaySelect(true)
                            : setDisplaySelect(false)
                        }
                        loading={!enums.regions}
                        allowClear
                        placeholder={t(
                          'prescription.radiology.form.placeholders.region_to_examine'
                        )}
                        options={(enums.regions || [])
                          .map((region) => ({
                            label: t(
                              `prescription.radiology.enums.regions.${region}`
                            ),
                            value: region
                          }))
                          .sort((a, b) => a.label.localeCompare(b.label))}
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      />
                    </Item>
                  </Row>
                  {displaySelect ? (
                    <Row key={`${field.name}_side`} align="middle">
                      <Item
                        {...field}
                        name={[field.name, 'side']}
                        key={[field.name, 'side']}
                      >
                        <Select
                          allowClear
                          placeholder={t(
                            'prescription.radiology.form.placeholders.side'
                          )}
                          options={(enums?.sides || []).map((side) => ({
                            label: t(
                              `prescription.radiology.enums.sides.${side}`
                            ),
                            value: side
                          }))}
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </Item>
                    </Row>
                  ) : null}
                  <Row key={`${field.name}_view`} align="middle">
                    <Item
                      {...field}
                      name={[field.name, 'view']}
                      key={[field.name, 'view']}
                    >
                      <Select
                        loading={!enums.views}
                        allowClear
                        placeholder={t(
                          'prescription.radiology.form.placeholders.views'
                        )}
                        options={(enums.views || [])
                          .map((view) => ({
                            label: t(
                              `prescription.radiology.enums.views.${view}`
                            ),
                            value: view
                          }))
                          .sort((a, b) => a.label.localeCompare(b.label))}
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      />
                    </Item>
                  </Row>
                </Card>
              ))}
              <Button
                onClick={() => add()}
                style={{
                  borderRadius: '15px'
                }}
              >
                {t('consultations.form.add_reason')}
              </Button>
            </>
          )}
        </List>
      )
    }
  ];
};
