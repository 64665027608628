import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListResource, ContentCustom } from '../../components';
import { useColumns } from './columns';
import { subCentersTable } from './sub-centers/SubCentersTable';
import { SubCenter } from '../../models/SubCenter';
import { Center } from '../../models/Center';

/**
 * Renders a list of centers.
 *
 * @component
 * @returns {JSX.Element} The list of centers component.
 */
export const ListCenters = () => {
  const { t } = useTranslation();
  const columns = useColumns();
  const [refresh, setRefresh] = useState(false);

  const archiveAction = {
    subCenters: async (id, archived) => {
      await SubCenter.archivingSubCenter(id, {
        archived: !archived
      });
      setRefresh(!refresh);
    },
    centers: async (record) => {
      await Center.archiveUnarchiveCenter(record._id, {
        archived: !record.archived
      });
      setRefresh(!refresh);
    }
  };

  return (
    <ContentCustom>
      <ListResource
        resourceName="centers"
        columns={columns}
        forceRefresh={refresh}
        resourceModelName="Center"
        populate="manager,sub_centers"
        expandable={{
          expandedRowRender: (record) => {
            const filteredType = record?.sub_centers.flatMap(
              (subCenter) => subCenter.type
            );
            const typeArr = Array.from(new Set(filteredType));
            return subCentersTable(
              t,
              record,
              typeArr,
              archiveAction.subCenters
            );
          },
          rowExpandable: () => true
        }}
        archiveAction={{
          customAction: (record) => archiveAction.centers(record)
        }}
      />
    </ContentCustom>
  );
};
