import { BaseModel } from './BaseModel';

/**
 * Represents a SubCenter model.
 * @class
 * @extends BaseModel
 */
export class SubCenter extends BaseModel {
  constructor(data) {
    super('center', undefined, data);
  }

  /**
   * The name of the model.
   * @type {string}
   */
  static modelName = 'sub-centers';

  /**
   * Archives a SubCenter by sending a PATCH request to the API.
   * @static
   * @async
   * @function
   * @param {string} id - The ID of the SubCenter to be archived.
   * @param {Object} archived - The data to be sent in the PATCH request.
   * @returns {Promise} A promise that resolves when the PATCH request is complete.
   */
  static archivingSubCenter(id, { ...archived }) {
    return BaseModel.fetchAPI('PATCH', `${SubCenter.modelName}/${id}`, {
      ...archived
    });
  }
}
