import { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import LoginForm from './LoginForm';
import DoubleFactorAuthForm from '../doubleFactorAuth/DoubleFactorAuthForm';
import ForgotPwdForm from './ForgotPwdForm';
import ChangePwdForm from './ChangePwdForm';
import CreatePwdForm from './CreatePwdForm';
import Logo from '../../assets/images/logo_toola.svg';
import { ManagerChoiceForm } from './ManagerChoiceForm';

export const Login = () => {
  const location = useLocation();
  const state = location.state || {};
  const { isDoubleFactorAuth } = useAuthContext();
  const { token } = useParams();
  const { t } = useTranslation();
  const { Title } = Typography;
  const [currentForm, setCurrentForm] = useState('login');
  const forms = {
    login: <LoginForm switchForm={(f) => setCurrentForm(f)} />,
    doubleFactorAuth: (
      <DoubleFactorAuthForm switchForm={(f) => setCurrentForm(f)} />
    ),
    forgotPwd: <ForgotPwdForm switchForm={(f) => setCurrentForm(f)} />,
    changePwd: (
      <ChangePwdForm switchForm={(f) => setCurrentForm(f)} token={token} />
    ),
    createPwd: <CreatePwdForm switchForm={(f) => setCurrentForm(f)} />,
    chooseCenter: <ManagerChoiceForm switchForm={(f) => setCurrentForm(f)} />
  };

  useEffect(() => {
    if (token) setCurrentForm('changePwd');
  }, [token]);

  useEffect(() => {
    if (isDoubleFactorAuth) setCurrentForm('doubleFactorAuth');
  }, [isDoubleFactorAuth]);

  useEffect(() => {
    if (state === 'createPwd') setCurrentForm('createPwd');
  }, [state]);

  return (
    <Layout className="login-layout">
      <Row>
        <Col
          xs={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 7, offset: 1 }}
          xxl={{ span: 6, offset: 2 }}
          className="login-form-col"
        >
          <img
            style={{
              width: 240,
              marginBottom: 64
            }}
            alt="Logo"
            src={Logo}
          />
          <Title className="login-title">
            {t(`login.title.${currentForm}`)}
          </Title>
          {forms[currentForm]}
        </Col>
      </Row>
    </Layout>
  );
};
