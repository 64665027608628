import { useContext } from 'react';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ConsultationsContext } from '../ConsultationsContext';

export const usePostConstantsSurvey = (
  handleCloseModal,
  idPatient,
  idConsultation
) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { setForcedRefresh, forcedRefresh } = useContext(ConsultationsContext);

  const createResource = async (body) => {
    try {
      const response = await dispatchAPI('POST', {
        url: 'constants/survey',
        body
      });
      if (response.status === 200) {
        setForcedRefresh(!forcedRefresh);
      }
    } catch (e) {
      message(e);
    }
  };

  const handleSubmit = async (values) => {
    const body = {
      ...values,
      patient: idPatient,
      consultation: idConsultation
    };
    await createResource(body);
    handleCloseModal();
  };

  return { handleSubmit };
};
