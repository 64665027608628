import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * CreateUpdateUser component is a container for creating and updating users.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the component.
 * @returns {ReactElement} The CreateUpdateUser component
 */
export const CreateUpdateUser = ({ purpose }) => {
  const [selectedCenterId, setSelectedCenterId] = useState(null);
  const [fileList, setFileList] = useState([]);

  const { user, setUser } = useAuthContext();
  const { fields, isFieldsLoading } = useFields({
    selectedCenterId,
    setSelectedCenterId,
    fileList,
    setFileList
  });

  const config = {
    onGetResource: {
      setFields: (data) => {
        setSelectedCenterId(data.associated_center);
        return {
          ...data,
          date_of_birth: data.date_of_birth && dayjs(data.date_of_birth)
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        if (data._id === user._id) setUser({ ...user, ...data });

        const formData = new FormData();

        formData.append('avatar', fileList[0]);
        formData.append('values', JSON.stringify(data));

        return formData;
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="users"
      resource="users"
      config={config}
      isPageHeaderCustom
    />
  );
};

CreateUpdateUser.propTypes = {
  purpose: PropTypes.string.isRequired
};
