import { BaseModel } from './BaseModel';

/**
 * Represents a Center model.
 * @class
 * @extends BaseModel
 */
export class Center extends BaseModel {
  static modelName = 'centers';

  /**
   * Creates a new instance of the Center model.
   * @constructor
   * @param {Object} data - The data for the Center model.
   */
  constructor(data) {
    super('center', undefined, data);
  }

  /**
   * Archives a sub center.
   * @static
   * @async
   * @function
   *
   * @param {string} id - The ID of the sub center to be archived.
   * @param {Object} archived - The archived data for the sub center.
   * @returns {Promise} A promise that resolves with the API response.
   */
  static archiveUnarchiveCenter(id, { ...archived }) {
    return BaseModel.fetchAPI('PATCH', `${Center.modelName}/archive/${id}`, {
      ...archived
    });
  }
}
