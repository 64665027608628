import { ProfileFilled } from '@ant-design/icons';
import { Card, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { usePrescriptionContext } from '../../../contexts/PrescriptionContext/PrescriptionContext';

/**
 * Renders a card component with a select dropdown for protocols.
 * @component ProtocolsCard
 *
 * @param {function} props.setCheckedKeys Function to set the checked keys.
 * @returns {JSX.Element} ProtocolsCard component.
 */
export const ProtocolsCard = ({ setCheckedKeys }) => {
  const { t } = useTranslation();
  const { protocols } = usePrescriptionContext();

  return (
    <Card
      title={
        <div>
          <ProfileFilled />
          {t('prescription.cards.protocols.title')}
        </div>
      }
    >
      <Select
        allowClear
        onClear={() => setCheckedKeys([])}
        showSearch
        style={{
          width: '100%'
        }}
        onChange={(value, option) => {
          setCheckedKeys(option?.checkedKeys);
        }}
        placeholder={t('prescription.cards.protocols.placeholder')}
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? '').includes(input)}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '')
            .toLowerCase()
            .localeCompare((optionB?.label ?? '').toLowerCase())
        }
        options={protocols?.map((protocol) => ({
          value: protocol._id,
          label: protocol.protocol_wording,
          checkedKeys: protocol.checked_keys
        }))}
      />
    </Card>
  );
};

ProtocolsCard.propTypes = {
  setCheckedKeys: PropTypes.func
};

ProtocolsCard.defaultProps = {
  setCheckedKeys: () => {}
};
