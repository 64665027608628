import { ProfileFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Card, Input } from 'antd';

const { Search } = Input;

/**
 * @component
 * @description Component that displays the protocols card
 * @returns {JSX.Element} Protocols card
 */
// Work in progress
export const ProtocolesCard = () => {
  const { t } = useTranslation();
  const translationKey = 'prescription.cards.protocols';

  return (
    <Card
      className="protocoles-card"
      title={
        <div>
          <ProfileFilled />
          {t(`${translationKey}.title`)}
        </div>
      }
    >
      <div className="protocoles-content">
        <Search placeholder={t('prescription.cards.placeholder')} />
      </div>
    </Card>
  );
};
