import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Select, Space, Tooltip } from 'antd';
import {
  EditOutlined,
  PrinterOutlined,
  InfoCircleOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { ConsultationsContext } from '../../ConsultationsContext';
import { ModalMedicalConsultation } from './ModalMedicalConsultation';
import { useSetOptions } from './utils/useSetOptions';
import { RowConsultation } from './rowsList/RowConsultation';
import { RowDiagnostic } from './rowsList/RowDiagnostic';
import { RowSpecialistConsultation } from './rowsList/RowSpecialistConsultation';
import { useHandleActions } from './utils/useHandleActions';
import { LoadingModal } from '../../../../components/Modals/LoadingModal';

/**
 * A React component to display the details of a medical consultation.
 *
 * @component
 * @param {Object} props - Props to pass into the component.
 * @param {string} [props.id] - The ID of the medical consultation to display. Default value is `undefined`.
 *
 * @returns {React.ElementType} Returns a rendered card containing details of the medical consultation.
 */

export const ShowMedicalConsultation = ({ id }) => {
  const [diagnostic, setDiagnostic] = useState([]);
  const [consultation, setConsultation] = useState('');
  const [specialistConsultation, setSpecialistConsultation] = useState('');
  const [isDisableEdit, setIsDisableEdit] = useState(true);
  const [isTodayDate, setIsTodayDate] = useState(false);
  const [isModal, setModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState();

  const resource = 'consultations';

  const { t } = useTranslation();
  const { patient } = useContext(ConsultationsContext);
  const [loading, setLoading] = useState(false);

  const todayConsultation = patient?.current_consultation;
  const idConsultation = patient?.current_consultation?._id;
  const dateFormat = 'DD-MM-YYYY';

  const { handlePrintMedicalReport, handleDateChange, handleTodayDate } =
    useHandleActions({
      setConsultation,
      setDiagnostic,
      setSpecialistConsultation,
      setIsDisableEdit,
      idConsultation,
      patient,
      setLoading
    });

  const { options } = useSetOptions({ setSelectedDate, handleDateChange });

  return (
    <Card
      title={t(`${resource}.form.medical_consultation`)}
      extra={
        <Space>
          <Tooltip
            title={t(`consultations.medical_consultation.date_selection`)}
            overlayInnerStyle={{ textAlign: 'center' }}
          >
            <InfoCircleOutlined />
          </Tooltip>
          <Button
            onClick={() => {
              handleTodayDate(todayConsultation);
              setSelectedDate(dayjs(todayConsultation.date).format(dateFormat));
            }}
            disabled={!isTodayDate}
          >
            <Tooltip
              title={t(`consultations.medical_consultation.consultation_date`)}
              overlayInnerStyle={{ textAlign: 'center' }}
            >
              <CalendarOutlined />
            </Tooltip>
          </Button>
          <Select
            options={options}
            value={selectedDate}
            onChange={(value) => {
              setSelectedDate(value);
              if (handleDateChange) {
                handleDateChange(value);
                if (
                  options &&
                  options.length > 0 &&
                  value === options[0].value
                ) {
                  setIsTodayDate(false);
                } else {
                  setIsTodayDate(true);
                }
              }
            }}
          />
          {!isDisableEdit && (
            <Button
              type="Link"
              icon={<EditOutlined />}
              onClick={() => setModal(true)}
            />
          )}
          <Button
            type="Link"
            onClick={handlePrintMedicalReport}
            icon={<PrinterOutlined />}
          />
        </Space>
      }
    >
      <ModalMedicalConsultation
        id={id}
        resource={resource}
        onDateChange={handleDateChange}
        idConsultation={idConsultation}
        isDisableEdit={isDisableEdit}
        isModal={isModal}
        setModal={setModal}
        patient={patient}
        consultation={consultation}
        setConsultation={setConsultation}
      />
      <RowConsultation
        t={t}
        resource={resource}
        consultation={consultation}
        setConsultation={setConsultation}
      />
      <RowDiagnostic resource={resource} t={t} diagnostic={diagnostic} />
      <RowSpecialistConsultation
        resource={resource}
        t={t}
        specialistConsultation={specialistConsultation}
      />
      <LoadingModal
        loading={loading}
        customTitle={t('modals.medical_report')}
        customMessage={t('modals.generate_document')}
      />
    </Card>
  );
};

ShowMedicalConsultation.propTypes = {
  id: PropTypes.string
};
ShowMedicalConsultation.defaultProps = {
  id: undefined
};
