import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { getPhoneNumber } from '../../utils/formatters';

/**
 * Custom hook that returns an array of column configurations for a table.
 *
 * @hook
 * @returns {Array} Array of column configurations
 */
export const useColumns = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    const { data } = await dispatchAPI('GET', { url: '/users?archived=false' });
    setUsers(data);
  };

  useEffect(() => {
    (async () => {
      await getUsers();
    })();
  }, []);

  return [
    {
      title: t('centers.form.name').toUpperCase(),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('centers.form.manager').toUpperCase(),
      key: 'manager',
      dataIndex: ['manager'],
      render: (manager) => `${manager?.last_name} ${manager?.first_name}`,
      sorter: true,
      filters: users?.map((user) => ({
        text: `${user.last_name} ${user.first_name}`,
        value: user._id
      }))
    },
    {
      title: t('centers.form.secretariat_phone_number').toUpperCase(),
      key: 'secretariat_phone_number',
      dataIndex: 'secretariat_phone_number',
      render: (secretariat_phone_number) =>
        getPhoneNumber(secretariat_phone_number)
    },
    {
      title: t('centers.form.manager_email').toUpperCase(),
      key: 'manager_email',
      dataIndex: ['manager'],
      render: (manager) => `${manager?.email}`
    },
    {
      title: t('centers.form.manager_phone').toUpperCase(),
      key: 'manager_phone',
      dataIndex: ['manager'],
      render: (manager) => getPhoneNumber(manager?.phone_number)
    }
  ];
};
