import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { PatientContext } from '../../../../contexts/PatientContext/PatientContext';

/**
 * Custom hook that provides fields and functions related to doctors letter form.
 * @hook
 *
 * @param {Object} options - The options object.
 * @param {Array} options.filesList - The list of files.
 * @param {Function} options.setFilesList - The function to set the list of files.
 * @param {boolean} options.submittable - Flag indicating if the form is submittable.
 * @param {Object} options.form - The form object.
 * @returns {Object} - The doctors letter fields.
 */
export const useFields = ({ filesList, setFilesList, submittable, form }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { id: patientId } = useParams();
  const { message } = useErrorMessage();
  const { getPatient } = useContext(PatientContext);
  const { id } = useParams();

  const submitDoctorsLetter = async (values, file) => {
    try {
      form.resetFields();
      const formData = new FormData();
      const { doctors_letter, ...rest } = values;

      formData.append('doctors_letter', file);
      formData.append('values', JSON.stringify(rest));

      await dispatchAPI('POST', {
        url: `doctors-letters/${patientId}`,
        body: formData
      });
      await getPatient(id);
    } catch (error) {
      message(error);
    }
  };

  const uploadProps = {
    beforeUpload: async (file) => {
      setFilesList([
        ...filesList,
        {
          date: dayjs(),
          visit_reason: form.getFieldValue('visit_reason'),
          general_practitioner: form.getFieldValue('general_practitioner'),
          name: file.name,
          file
        }
      ]);

      const values = form.getFieldsValue();
      await submitDoctorsLetter(values, file);
      return false;
    },
    showUploadList: false
  };

  const doctorsLetterFields = [
    {
      label: 'visit_reasons',
      name: 'visit_reason',
      rules: [{ required: true }]
    },
    {
      label: 'general_practitioner',
      name: 'general_practitioner',
      rules: [{ required: true }]
    },
    {
      noLabel: true,
      name: 'doctors_letter',
      input: (
        <Upload {...uploadProps}>
          <Button
            disabled={!submittable}
            type="primary"
            icon={<UploadOutlined />}
          >
            {t('buttons.add_doctors_letter')}
          </Button>
        </Upload>
      )
    }
  ];

  return { doctorsLetterFields };
};
