import { useTranslation } from 'react-i18next';
import { Row } from 'antd';
import { useColumns } from './ColumnsResultsModal';
import { useConsultationsContext } from '../../ConsultationsContext';
import { Datatable } from '../../../../components';

const resource = 'consultations';

// TODO : WIP
export const ResultsLaboratoriesTable = () => {
  const { columns } = useColumns();
  const { patient: { first_name = '-', last_name = '-' } = {} } =
    useConsultationsContext();
  const { t } = useTranslation();

  return (
    <>
      <Row>
        {t(`${resource}.form.results_laboratories`)} {t(`${resource}.form.of`)}
        &nbsp;
        {first_name}&nbsp;
        {last_name}
      </Row>
      <Datatable
        dataSource={[]}
        columns={columns}
        customActionColumn
        archiveAction={false}
        editAction={false}
        showAction={false}
      />
    </>
  );
};
