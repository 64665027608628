import { Button } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';

export const PrintButton = () => (
  <Button
    style={{
      borderRadius: '15px',
      margin: '2px'
    }}
    icon={<PrinterOutlined />}
  />
);
