import React, { useEffect, useCallback, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Collapse, Typography } from 'antd';
import { StayTable } from '../../../../consultations/PatientOverview/Documents/dataTables/StayTable';
import { RadiologyTable } from '../../../../consultations/PatientOverview/Documents/dataTables/RadiologyTable';
import { ExitTable } from '../../../../consultations/PatientOverview/Documents/dataTables/ExitTable';
import { PhotosTable } from '../../../../consultations/PatientOverview/Documents/dataTables/PhotosTable';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';

/**
 * Renders a component for displaying prescription documents.
 * This component displays various medical prescription tables.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.record - Information about the patient.
 * @param {string} props.record.first_name - The first name of the patient.
 * @param {string} props.record.last_name - The last name of the patient.
 * @param {string} props.record._id - The ID of the patient.
 * @param {string} props.resource - The resource for translation.
 * @returns {JSX.Element} The rendered component for displaying prescription documents.
 */
export const ContentModalPrescriptionDoc = ({ record, resource }) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { first_name = '-', last_name = '-', _id = '-' } = record.patient || {};
  const { dispatchAPI } = useAuthContext();
  const { Title } = Typography;
  const { Panel } = Collapse;

  const [sortedField, setSortedField] = useState('date');
  const [sortedOrder, setSortedOrder] = useState('descend');
  const [prescriptions, setPrescriptions] = useState({});
  const [consultationsWithPhotos, setConsultationsWithPhotos] = useState([]);

  const preprocessData = useCallback(
    (data) =>
      data.map((rec, index, array) => {
        if (index > 0 && array[index - 1].diagnostic[0] === rec.diagnostic[0]) {
          return { ...rec, sameAsPrevious: true };
        }
        let rowSpan = 1;
        let i = index + 1;
        while (
          i < array.length &&
          array[i].diagnostic[0] === rec.diagnostic[0]
        ) {
          i += 1;
          rowSpan += 1;
        }
        return { ...rec, rowSpan };
      }),
    []
  );

  const sortData = useCallback((data, field, order) => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      let comparison = 0;
      if (field === 'diagnostic') {
        const aReasons = Array.isArray(a.diagnostic)
          ? a.diagnostic.join(', ')
          : a.diagnostic;
        const bReasons = Array.isArray(b.diagnostic)
          ? b.diagnostic.join(', ')
          : b.diagnostic;
        comparison = aReasons.localeCompare(bReasons);
      } else if (field === 'date') {
        comparison = dayjs(a.date).unix() - dayjs(b.date).unix();
      }
      if (order === 'descend') {
        comparison *= -1;
      }
      return comparison;
    });
    return sortedData;
  }, []);

  const getConsultationsWithPhotos = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/consultations/photos/patient/${_id}?populate=photo`
      });
      const sortedData = sortData(data, sortedField, sortedOrder);
      const preprocessedData = preprocessData(sortedData);

      setConsultationsWithPhotos(preprocessedData);
    } catch (e) {
      message(e);
    }
  }, [_id, sortData, preprocessData, sortedField, sortedOrder]);

  const getAllPrescriptions = async () => {
    const population = [
      'patient',
      'consultation',
      'consultation.visit_reasons'
    ];
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/prescriptions/all_prescriptions?populate=${population.join(
          ','
        )}&patient=${_id}`
      });

      setPrescriptions(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await Promise.all([getConsultationsWithPhotos(), getAllPrescriptions()]);
    })();
  }, [getConsultationsWithPhotos]);

  const onTableChange = useCallback(
    (pagination, filters, sorter) => {
      setSortedField(sorter.field);
      setSortedOrder(sorter.order);
      getConsultationsWithPhotos();
    },
    [getConsultationsWithPhotos]
  );

  return (
    <>
      <Row>
        <Title>
          {t(`${resource}.documents.document_treatment`)}&nbsp;
          {first_name}&nbsp;
          {last_name}
        </Title>
      </Row>
      <Collapse expandIconPosition="end">
        <Panel header={t(`${resource}.documents.stay_prescription`)} key="1">
          <StayTable
            data={prescriptions?.stayPrescriptions || []}
            onChange={onTableChange}
          />
        </Panel>
      </Collapse>
      <Collapse expandIconPosition="end">
        <Panel header={t(`${resource}.documents.exit_prescription`)} key="2">
          <ExitTable
            data={prescriptions?.exitPrescriptions || []}
            onChange={onTableChange}
          />
        </Panel>
      </Collapse>
      <Collapse expandIconPosition="end">
        <Panel
          header={t(`${resource}.documents.radiology_prescription`)}
          key="3"
        >
          <RadiologyTable
            data={prescriptions?.radiologyPrescription || []}
            onChange={onTableChange}
          />
        </Panel>
      </Collapse>
      <Collapse expandIconPosition="end">
        <Panel header={t(`${resource}.documents.photos_prescription`)} key="3">
          <PhotosTable
            data={consultationsWithPhotos}
            onChange={onTableChange}
          />
        </Panel>
      </Collapse>
    </>
  );
};

ContentModalPrescriptionDoc.propTypes = {
  record: PropTypes.shape({
    patient: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      _id: PropTypes.string
    })
  }).isRequired,
  resource: PropTypes.string.isRequired
};
