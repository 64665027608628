import { useState, useEffect } from 'react';
import { ListResource } from '../../../components';
import { CustomAction } from '../components/CustomAction';
import { useColumns } from './columns';
import { CollapseRessources } from '../components/CollapseResources';
import { GetEnums } from '../../../utils/getEnums';

/**
 * Component for listing visit reasons grouped by categories.
 *
 * @component
 * @returns {JSX.Element} The rendered ListVisitReasons component.
 */
export const ListVisitReasons = () => {
  const { getEnums } = GetEnums();
  const [displayAll, setDisplayAll] = useState(true);
  const [enums, setEnums] = useState([]);

  const { columns } = useColumns(displayAll);
  const resource = 'visit-reasons';

  const actions = (purpose) => ({
    pathname: (record) =>
      record && record._id
        ? `/data-base/${resource}/${purpose}/${record._id}`
        : null
  });

  const getCategories = async () => {
    const { categories } = await getEnums(resource);
    setEnums(categories || []);
  };

  useEffect(() => {
    (async () => {
      await getCategories();
    })();
  }, []);

  return (
    <>
      <CustomAction
        resource={resource}
        withSwitchBtn
        defaultChecked={displayAll}
        setChecked={setDisplayAll}
      />
      {displayAll ? (
        <ListResource
          resource={resource}
          resourceName={resource}
          path={resource}
          columns={columns}
          isPageHeaderCustom={false}
          isOriginalContentCustom={false}
          showAction={false}
          archiveAction
        />
      ) : (
        enums.map((category) => (
          <CollapseRessources
            key={category}
            resource={resource}
            translationKey={`data_bases.${resource}`}
            category={category}
            columns={columns}
            showAction={actions('show')}
            editAction={actions('edit')}
          />
        ))
      )}
    </>
  );
};
