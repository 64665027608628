import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Col } from 'antd';
import { usePrescriptionContext } from '../../contexts/PrescriptionContext/PrescriptionContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { getSynapseToken } from '../../utils/synapse';

/**
 * SynapseAutocompleteWidget
 * @component
 * @summary Autocomplete widget for Synapse
 * @description Autocomplete widget for Synapse that allows the user to search for drugs
 * @param {object} props - See PropTypes
 * @param {boolean} props.fromHeader - If the widget is in the header or in the prescription form
 * @param {boolean} props.fromPersonnalTreatments - If the widget is in the personnal treatments form
 * @param {function} props.setPersonnalTreatmentsEntities - Function to set the personnal treatments entities
 * @param {array} props.personnalTreatmentsEntities - Array of personnal treatments entities
 * @returns {node} jsx markup
 */
export const SynapseAutocompleteWidget = ({
  fromHeader,
  fromPersonnalTreatments,
  setEntitiesProps,
  entitiesProps
}) => {
  const { entities, setEntities, setRefresh } = usePrescriptionContext();
  const [JWT, setJWT] = useState(null);
  const { dispatchAPI } = useAuthContext();
  const componentId = fromHeader
    ? 'autocomplete-container-header'
    : 'autocomplete-container';

  const fetchNewToken = async () => {
    const token = await getSynapseToken(dispatchAPI);
    setJWT(token);
  };

  useEffect(() => {
    if (!JWT) {
      fetchNewToken();
      return;
    }
    const autocompleteWidget =
      new window.Synapse.MedicalResourceAutocompleteWidget(JWT);

    const dataAutocomplete = {
      entityTypes: [
        window.Synapse.MedicalResourceEntityType.BRANDED_DRUGS,
        window.Synapse.MedicalResourceEntityType.PHARMACEUTICAL_INGREDIENTS,
        window.Synapse.MedicalResourceEntityType.CLINICAL_DRUGS
      ]
    };

    autocompleteWidget.init(
      document.getElementById(componentId),
      dataAutocomplete
    );

    autocompleteWidget.update(dataAutocomplete);

    const shouldUpdateEntities = !fromHeader && !fromPersonnalTreatments;
    const shouldUpdateEntitiesProps = fromHeader || fromPersonnalTreatments;

    const callback = (entity) => {
      if (shouldUpdateEntities) {
        setEntities((curEntities) => [...curEntities, entity]);
        setRefresh((prevRefresh) => !prevRefresh);
      }

      if (shouldUpdateEntitiesProps) {
        setEntitiesProps((curEntities) => [...curEntities, entity]);
      }
    };

    // Set callbacks for the widget
    autocompleteWidget.setCallback('onSelectEntity', callback);

    // eslint-disable-next-line no-unused-vars
    autocompleteWidget.setCallback('onTokenExpiration', (err, data) => {
      const newToken = fetchNewToken();
      autocompleteWidget.setToken(newToken);
    });
  }, [entities, entitiesProps, JWT]);

  return <Col className={componentId} id={componentId} />;
};

SynapseAutocompleteWidget.propTypes = {
  fromHeader: PropTypes.bool,
  fromPersonnalTreatments: PropTypes.bool,
  setEntitiesProps: PropTypes.func,
  entitiesProps: PropTypes.arrayOf(PropTypes.shape({}))
};

SynapseAutocompleteWidget.defaultProps = {
  fromHeader: false,
  fromPersonnalTreatments: false,
  setEntitiesProps: () => {},
  entitiesProps: []
};
