import { FileTextOutlined } from '@ant-design/icons';
import { Card, Modal, Row, Col } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ModalAdministaration } from '../modals/ModalAdministration';

/**
 * @component A component that displays an administration card.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.resource - The resource to be used for translation.
 *
 * @returns {JSX.Element} The AdministrationCard component.
 */
export const AdministrationCard = ({ resource }) => {
  const [isModalAdministration, setModalAdministration] = useState(false);
  const { t } = useTranslation();

  return (
    <Col span={12}>
      <Card
        bordered={false}
        style={{ background: '#8AC7FF66' }}
        onClick={() => setModalAdministration(true)}
        hoverable
      >
        <Row justify="center">
          <FileTextOutlined style={{ fontSize: '3em' }} />
        </Row>
        <Row justify="center">
          <span>{t(`${resource}.form.administration`)}</span>
        </Row>
      </Card>
      <Modal
        open={isModalAdministration}
        onCancel={() => setModalAdministration(false)}
        okText={t('buttons.validate')}
        width="50%"
        footer={null}
      >
        <ModalAdministaration />
      </Modal>
    </Col>
  );
};

AdministrationCard.propTypes = {
  resource: PropTypes.string.isRequired
};
