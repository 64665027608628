import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDrag } from 'react-dnd';
import { Card } from 'antd';
import { HolderOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { ConsultingTree } from './ConsultingTree';

const { Grid } = Card;

export const GridItem = ({ _id, title, rooms, refresh, setRefresh }) => {
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'GRID',
    item: { _id, title },
    end: async (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        await dispatchAPI('PATCH', {
          url: `/kanban-columns/${id}`,
          body: {
            targetedColumn: dropResult.target,
            roomId: item._id
          }
        });
        setRefresh((prevRefresh) => !prevRefresh);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  const border = isDragging ? '1px solid #FFB94A' : '1px solid #8AC7FF';
  const backgroundColor = '#fff';
  const cursor = isDragging ? 'grabbing' : 'grab';

  return (
    <div ref={drag}>
      <Grid style={{ width: '100%', border, backgroundColor, cursor }}>
        <HolderOutlined style={{ marginRight: 8 }} />
        {title}
        {rooms.length > 0 && (
          <ConsultingTree
            rooms={rooms}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        )}
      </Grid>
    </div>
  );
};

GridItem.propTypes = {
  _id: PropTypes.string,
  title: PropTypes.string,
  rooms: PropTypes.arrayOf(PropTypes.shape({})),
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func
};

GridItem.defaultProps = {
  _id: '',
  title: '',
  rooms: [],
  refresh: false,
  setRefresh: () => {}
};
