import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Col, Skeleton, Button, Popover, message as AntdMessage } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom } from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { usePrescriptionContext } from '../../../contexts/PrescriptionContext/PrescriptionContext';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { DrugsList } from './DrugsList';
import { QuotesModal } from '../quotesModal/QuotesModal';

// Synapse components
import { SynapseAutocompleteWidget } from '../../../components/Synapse/SynapseAutoCompleteWidget';
import { SynapsePosologyCheckerWidget } from '../../../components/Synapse/SynapsePosologyCheckerWidget';
import { SynapseGroundsWidget } from '../../../components/Synapse/SynapseGroundsWidget';
import { SynapseAdverseEffectWidget } from '../../../components/Synapse/SynapseAdverseEffectWidget';
import { SynapseInteractionWidget } from '../../../components/Synapse/SynapseInteractionWidget';
import { SynapseStartStoppWidget } from '../../../components/Synapse/SynapseStartStoppWidget';
import { SynapsePrescriptionSummaryWidget } from '../../../components/Synapse/SynapsePrescriptionSummaryWidget';

/**
 * @component
 * @name CreateUpdateMedicationsPrescription
 * @description A component to create a medication prescription
 * @returns {JSX.Element} React component
 */
export const CreateUpdateMedicationsPrescription = () => {
  const { id, type } = useParams();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const { printDocument } = useDownloadDocument();
  const { t } = useTranslation();
  const { setPatientId, patientId, patientInfos, posologies, isLoading } =
    usePrescriptionContext();
  const [loading, setLoading] = useState(false);

  const postPrescription = async () => {
    if (patientId && patientInfos && posologies.length > 0) {
      try {
        setLoading(true);

        const body = {
          purpose: 'MEDICATION',
          doctor: user?._id,
          type,
          patient: patientId,
          consultation: patientInfos?.infos?.current_consultation,
          posologies
        };

        const { data } = await dispatchAPI('POST', {
          url: '/prescriptions',
          body
        });

        if (data && data._id) {
          setTimeout(async () => {
            await printDocument(data);
            AntdMessage.success(t('success.message.prescription.create'));
          }, 500);
        } else {
          AntdMessage.error(t('warnings.message.bi-zones_not_supported'));
        }
        navigate(`/consultations/show/${patientId}`);
      } catch (error) {
        message(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setPatientId(id);
  }, [id]);

  return (
    <ContentCustom>
      <QuotesModal visible={loading} />
      <Skeleton loading={isLoading} active>
        {id && (
          <>
            <PageHeaderCustom title={patientInfos.headerTitle} />
            <Col span={24} className="col-padding">
              <SynapseAutocompleteWidget className="ant-collapse-content-box" />
            </Col>
            <Col span={24} className="col-padding">
              <SynapsePrescriptionSummaryWidget />
            </Col>
            <Col span={24} className="col-padding">
              <DrugsList />
            </Col>
            <Col span={24} className="col-padding">
              <SynapsePosologyCheckerWidget />
            </Col>
            <Col span={24} className="col-padding">
              <SynapseGroundsWidget />
            </Col>
            {patientInfos.patientAge >= 65 && (
              <Col span={24} className="col-padding">
                <SynapseStartStoppWidget />
              </Col>
            )}
            <Col span={24} className="col-padding">
              <SynapseAdverseEffectWidget />
            </Col>
            <Col span={24} className="col-padding">
              <SynapseInteractionWidget />
            </Col>
            <Col span={24} className="col-padding">
              <Popover
                content={t('warnings.message.empty_posology')}
                trigger={posologies.length < 1 ? 'hover' : 'none'}
              >
                <Button
                  loading={loading}
                  disabled={posologies.length < 1 || loading}
                  type="primary"
                  onClick={() => postPrescription()}
                >
                  {t('buttons.validate')}
                </Button>
              </Popover>
            </Col>
          </>
        )}
      </Skeleton>
    </ContentCustom>
  );
};
