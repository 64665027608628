/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import dayjs from 'dayjs';
import { Form, Button, Input, Popover, Row, Col, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const dateFormat = 'DD-MM-YYYY';
const styles = {
  container: {
    padding: 120,
    margin: '40px 80px',
    border: '1px solid black'
  },
  row: {
    marginBottom: 80
  },
  reactQuill: {
    width: '100%',
    height: '100%'
  }
};

/**
 * @component PrescriptionForm
 * @param {Object} props - Component properties.
 * @param {function} props.createBody - Function to create the body of the prescription.
 * @param {function} props.onSubmit - Function to handle form submission.
 * @param {Object} props.patientInfos - Patient information.
 * @param {string} props.patientInfos.content - Content of the prescription.
 * @param {function} props.setContent - Function to set the content of the prescription.
 * @param {string} props.title - Title for the page header.
 * @param {boolean} props.showLabelInput - Flag to determine if the label input should be shown.
 * @returns {React.Component} Prescription form.
 */
export const PrescriptionForm = ({
  createBody,
  onSubmit,
  patientInfos,
  content,
  setContent,
  label,
  setLabel,
  title,
  showLabelInput,
  translationKey,
  loading
}) => {
  const { user } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { infos, patientAge, headerTitle } = patientInfos;

  const consultationDate = infos?.current_consultation?.date
    ? `Consultation médicale en date du ${dayjs(
        infos?.current_consultation?.date
      ).format(dateFormat)}`
    : '';
  const firstName = infos?.first_name || '';
  const lastName = infos?.last_name || '';
  const birthDate = infos?.birth_date
    ? `Né(e) le ${dayjs(infos?.birth_date).format(dateFormat)}`
    : '';
  const age = patientAge ? `${patientAge} ans` : '';

  const postPrescription = async () => {
    const prescriptionBody = createBody();

    if (!prescriptionBody) {
      return null;
    }

    try {
      await onSubmit(prescriptionBody);
    } catch (error) {
      return message(error);
    }

    return null;
  };

  /**
   * Check if the content of the prescription is empty.
   *
   * @function
   * @returns {boolean} Returns true if the content is empty, otherwise returns false.
   * @description This function checks if the content of the prescription is empty.
   *              It removes all HTML tags from the content and checks if the length of the content is less than 1.
   *              If the content is empty and the label input is shown (based on the `showLabelInput` prop),
   *              it returns true only if both content and label are empty.
   *              Otherwise, it returns true if only the content is empty.
   *              This function is used to disable the submit button if the content is empty.
   *              It is also used to display a warning message if the content is empty.
   * @example
   * // Returns true
   * const emptyContent = isEmptyContent('<p></p>');
   * @example
   * // Returns false
   * const nonEmptyContent = isEmptyContent('<p>Some content</p>');
   */
  const isEmptyContent = () => {
    const formatContent = content.replace(/<[^>]*>/g, '');
    if (showLabelInput) {
      return formatContent.length < 1 || label.length < 1;
    }
    return formatContent.length < 1;
  };

  return (
    <ContentCustom>
      <Form onFinish={postPrescription}>
        <PageHeaderCustom title={headerTitle || title} />
        {showLabelInput && (
          <Form.Item name="label" nolabel="true" rules={[{ required: true }]}>
            <Input
              placeholder={t(`${translationKey}.free_text_label`)}
              onChange={(e) => setLabel(e.target.value)}
            />
          </Form.Item>
        )}
        <div style={styles.container}>
          <Row style={styles.row} gutter={[16, 16]}>
            <Col span={6}>{'{LOGO}'}</Col>
            <Col span={18}>
              <Row>Soins non programmés</Row>
              <Divider />
              <Row style={{ marginBottom: 24 }}>
                Consultations tous les jours de 8h à 20h
              </Row>
              <Row>Suite de soins sur rendez-vous</Row>
            </Col>
          </Row>

          <Row style={styles.row} justify="end">
            <Col>
              <Row justify="end">
                {showLabelInput
                  ? "Le {date d'impression}"
                  : `Le ${dayjs().format(dateFormat)}`}
              </Row>
              <Row style={{ marginBottom: 24 }} justify="end">
                {showLabelInput
                  ? 'Consultation médicale en date du {date de la consultation}'
                  : consultationDate}
              </Row>
              <Row justify="end">
                {showLabelInput
                  ? 'Nom et prénom du patient'
                  : `${firstName} ${lastName}`}
              </Row>
              <Row justify="end">
                {showLabelInput ? 'Date de naissance du patient' : birthDate}
              </Row>
              <Row justify="end">{showLabelInput ? 'Âge du patient' : age}</Row>
            </Col>
          </Row>

          <Row style={styles.row} justify="center">
            <Col span={24}>
              <Form.Item
                nolabel="true"
                name="content"
                initialValue={content}
                rules={[{ required: true }]}
              >
                <ReactQuill
                  style={styles.reactQuill}
                  theme="snow"
                  modules={{
                    toolbar: {
                      container: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ indent: '-1' }, { indent: '+1' }],
                        ['clean']
                      ]
                    }
                  }}
                  value={content}
                  onChange={(value) => setContent(value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={styles.row} justify="end">
            <Col>
              <Row justify="end">{`Dr. ${user?.first_name || ''} ${
                user?.last_name || ''
              }`}</Row>
              <Row justify="end">{user?.rpps_number || ''}</Row>
            </Col>
          </Row>
        </div>

        <Row justify="center">
          <Popover
            content={
              showLabelInput
                ? t('warnings.message.empty_content_or_label')
                : t('warnings.message.empty_content')
            }
            trigger={isEmptyContent() ? 'hover' : 'none'}
          >
            <Button
              loading={loading}
              disabled={isEmptyContent() || loading}
              type="primary"
              htmlType="submit"
            >
              {t('buttons.validate')}
            </Button>
          </Popover>
        </Row>
      </Form>
    </ContentCustom>
  );
};

PrescriptionForm.propTypes = {
  createBody: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  setContent: PropTypes.func.isRequired,
  label: PropTypes.string,
  setLabel: PropTypes.func,
  patientInfos: PropTypes.shape({
    infos: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      birth_date: PropTypes.string,
      current_consultation: PropTypes.shape({
        date: PropTypes.string
      })
    }),
    patientAge: PropTypes.number,
    headerTitle: PropTypes.string
  }),
  title: PropTypes.string,
  showLabelInput: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  translationKey: PropTypes.string
};

PrescriptionForm.defaultProps = {
  showLabelInput: true,
  patientInfos: {},
  label: '',
  setLabel: () => {},
  translationKey: '',
  title: ''
};
