import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrag } from 'react-dnd';
import { Card, Row, Spin, Col, message as Message } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { getAge } from '../../utils/getAge';
import { Allergies } from './patientIcons/Allergies';
import { PatientWording } from './patientIcons/PatientWording';
import { ActionsIcons } from './patientIcons/ActionsIcons';
import { Pill } from './patientIcons/Pill';
import { Syringe } from './patientIcons/Syringe';
import { DoctorsLetter } from './patientIcons/DoctorsLetter';
import { CareIcon } from './patientIcons/CareIcon';
import { formatName } from './utils/formatName';
import { Badge } from '../consultations/PatientOverview/GeneralInformations/Badge';

/**
 * Represents a patient component.
 *
 * @component
 * @param {string} departureRoomId - The ID of the departure room.
 * @param {function} setRefresh - A function to refresh the component.
 * @param {object} consultation - The consultation object.
 * @param {string} consultation._id - The ID of the consultation.
 * @param {object} consultation.patient - The patient object.
 * @param {string} consultation.patient.first_name - The first name of the patient.
 * @param {string} consultation.patient.last_name - The last name of the patient.
 * @param {string} consultation.patient.gender - The gender of the patient.
 * @param {string} consultation.patient.birth_date - The birth date of the patient.
 * @param {object} consultation.patient.grounds - The grounds object of the patient.
 * @param {string[]} consultation.patient.grounds.allergies - An array of allergies of the patient.
 * @param {object} consultation.patient.current_consultation - The current consultation object of the patient.
 * @param {string} consultation.patient.current_consultation._id - The ID of the current consultation.
 * @param {object[]} consultation.patient.current_consultation.visit_reasons - An array of visit reasons for the current consultation.
 * @param {string} consultation.patient.current_consultation.visit_reasons._id - The ID of the visit reason.
 * @param {string} consultation.patient.current_consultation.visit_reasons.reason_wording - The wording of the visit reason.
 * @param {boolean} consultation.patient.current_consultation.hyperalgesic - Indicates if the patient is hyperalgesic.
 * @returns {JSX.Element} The patient component.
 */

// work in progress
export const Patient = ({ departureRoomId, setRefresh, consultation }) => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [child, setChild] = useState(false);
  const [convertedAge, setConvertedAge] = useState(null);
  const [loading, setLoading] = useState(false);
  const { _id, patient } = consultation;
  const { first_name, last_name, gender, birth_date, grounds } = patient || {};

  useEffect(() => {
    if (birth_date) {
      const { age, isChild } = getAge(birth_date);
      const converted = `${age} ${t('patients.form.years')}`;
      setChild(isChild);
      setConvertedAge(converted);
    }
  }, [birth_date]);

  const handlePatientDrop = async (dropResult) => {
    try {
      setLoading(true);
      await dispatchAPI('PATCH', {
        url: `/rooms/change-patient-room/${departureRoomId}`,
        body: {
          targeted_room_id: dropResult.target,
          user_last_name: user.last_name,
          user_first_name: user.first_name,
          consultation_id: consultation._id
        }
      });
      setRefresh((prevRefresh) => !prevRefresh);
    } catch (error) {
      if (error.response.data.status_code === 413) {
        Message.error(
          t(`errors.message.${error.response.data.description}`, {
            room: error.response.data.message
          }),
          4.5
        );
      } else {
        message(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'PATIENT',
    item: { _id },
    end: async (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        if (departureRoomId !== dropResult.target) {
          await handlePatientDrop(dropResult);
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  const border = isDragging ? '1px solid #FFB94A' : '1px solid #fff';
  const cursor = isDragging ? 'grabbing' : 'grab';

  return (
    <div
      className="kanban-patient-dragger"
      style={{ border, cursor }}
      ref={drag}
    >
      <Card className="kanban-patient">
        <Spin spinning={loading}>
          <Row
            style={{ flexWrap: 'nowrap' }}
            justify="space-between"
            align="middle"
          >
            <div style={{ marginRight: '10px' }}>
              <Badge
                number={consultation.daily_count}
                visitType={consultation.visit_type}
              />
            </div>

            <PatientWording
              patientName={formatName(first_name, last_name)}
              child={child}
              gender={gender}
              convertedAge={convertedAge}
            />
            <Col span={12}>
              <Row
                justify="end"
                style={{ flexWrap: 'nowrap' }}
                className="kanban-patient-actions"
              >
                <Pill />
                <Syringe patient={patient} />
                <Allergies grounds={grounds} />
                <CareIcon patient={patient} />
                <DoctorsLetter />
                <ActionsIcons patient={patient} />
              </Row>
            </Col>
          </Row>
          {consultation?.visit_reasons?.map((reason) => (
            <Row className="kanban-patient-reason" key={reason._id}>
              {reason.reason_wording || ''}
            </Row>
          ))}
          {consultation?.hyperalgesic && (
            <div className="hyperalgesic-corner" />
          )}
        </Spin>
      </Card>
    </div>
  );
};

Patient.propTypes = {
  departureRoomId: PropTypes.string,
  setRefresh: PropTypes.func,
  consultation: PropTypes.shape({
    _id: PropTypes.string,
    patient: PropTypes.shape({
      _id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      gender: PropTypes.string,
      birth_date: PropTypes.string,
      grounds: PropTypes.shape({
        allergies: PropTypes.arrayOf(PropTypes.shape({}))
      })
    }),
    visit_reasons: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        reason_wording: PropTypes.string
      })
    ),
    hyperalgesic: PropTypes.bool,
    daily_count: PropTypes.number,
    visit_type: PropTypes.string
  })
};

Patient.defaultProps = {
  departureRoomId: '',
  setRefresh: () => {},
  consultation: {
    _id: '',
    visit_reasons: [],
    hyperalgesic: false,
    patient: {
      _id: '',
      first_name: '',
      last_name: '',
      gender: '',
      birth_date: '',
      grounds: {
        allergies: []
      }
    }
  }
};
