import { WarningFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

/**
 * Renders an icon representing allergies.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Object} props.grounds - The grounds object containing allergy information.
 * @returns {JSX.Element} The rendered component.
 */
export const Allergies = ({ grounds }) =>
  grounds?.allergies?.length > 1 && (
    <Tooltip
      title={grounds?.allergies?.map((allergy) => allergy.label).join(', ')}
    >
      <WarningFilled style={{ color: 'var(--errorColor)', fontSize: 20 }} />
    </Tooltip>
  );

Allergies.propTypes = {
  grounds: PropTypes.shape({
    allergies: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

Allergies.defaultProps = {
  grounds: {
    allergies: []
  }
};
