import PropTypes from 'prop-types';
import { Row, Card, Col } from 'antd';
import { PrintButton } from './GeneralInformations/PrintButton';
import { EditButton } from './GeneralInformations/EditButton';
import { DMPButton } from './GeneralInformations/DMPButton';
import { useListContentTop } from './GeneralInformations/GeneralInfosListContentTop';
import { useListContentBottom } from './GeneralInformations/GeneralInfosListContentBottom';
import { TitleInfos } from './GeneralInformations/TitleInfos';
import { useConsultationsContext } from '../ConsultationsContext';
import { useAuthContext } from '../../../contexts/AuthContext';
import { DescriptionList } from '../../../components';

export const ShowGeneralInfos = ({ id }) => {
  const { patient } = useConsultationsContext();
  const { current_consultation: { hyperalgesic } = {} } = patient || {};
  const { user } = useAuthContext();
  const listContentTop = useListContentTop(patient);
  const listContentBottom = useListContentBottom(patient);
  const position = user?.position;

  return (
    <Card style={{ position: 'relative' }}>
      <Row justify="space-between" align="middle">
        <TitleInfos />
        <Row justify="end" align="middle">
          <PrintButton />
          <EditButton id={id} position={position} />
          <DMPButton />
        </Row>
      </Row>
      <Row style={{ flexWrap: 'nowrap' }}>
        <Col span={16}>
          <DescriptionList data={listContentTop} layout="vertical" translate />
        </Col>
        <Col
          span={8}
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <DescriptionList
            data={listContentBottom}
            layout="vertical"
            translate
          />
          {hyperalgesic && (
            <svg
              width="40"
              height="40"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M20 0V20H0L20 0Z" fill="#EC3E40" />
            </svg>
          )}
        </Col>
      </Row>
    </Card>
  );
};

ShowGeneralInfos.propTypes = {
  id: PropTypes.string.isRequired
};
