import { CaretUpOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import { Checkbox, Popconfirm, Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Patient } from '../../../models/Patient';
import { usePatientAllergies } from '../../../utils/usePatientAllergies';
import { useHandleChange } from '../utils/handleChange';

/**
 * Renders a Syringe component for a patient.
 * @param {Object} props - The component props.
 * @param {Object} props.patient - The patient object.
 * @returns {JSX.Element} - The Syringe component.
 */

// TODO work in progress
export const Syringe = ({ patient }) => {
  const { t } = useTranslation();
  const { isAllergic } = usePatientAllergies(patient);

  const [transmittedMedications, setTransmittedMedications] = useState([]);

  const { handleChange } = useHandleChange(
    transmittedMedications,
    setTransmittedMedications
  );

  const prescribedMedications = patient?.prescribed_medications.map(
    (medication) =>
      !isAllergic(medication.ingredients) && (
        <Checkbox
          defaultChecked={medication.transmitted}
          onChange={handleChange}
          value={medication._id}
          key={medication._id}
        >
          {medication.label}
        </Checkbox>
      )
  );

  const handleConfirm = async () => {
    await Patient.patchTransmittedMedications(
      patient._id,
      transmittedMedications
    );
  };

  return (
    <Tooltip title="Seringue bleu aiguille en bas à gauche">
      <Popconfirm
        placement="bottomRight"
        title="Médicaments"
        description={
          <Space direction="vertical">{prescribedMedications}</Space>
        }
        okText={t('buttons.validate')}
        cancelText={t('buttons.cancel')}
        icon={<MedicineBoxOutlined />}
        onConfirm={handleConfirm}
      >
        <CaretUpOutlined />
      </Popconfirm>
    </Tooltip>
  );
};

Syringe.propTypes = {
  patient: PropTypes.shape({
    prescribed_medications: PropTypes.arrayOf(PropTypes.shape({})),
    _id: PropTypes.string
  }).isRequired
};
