import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Switch } from 'antd';
import { GendersButtons } from '../components/MainAdmin/GendersButtons';
import { FormatedDatePicker } from '../components/Fields/FormatedDatePicker';

/**
 * Returns an array of main fields for the patients form.
 *
 * @param {boolean} patientIsMan - Indicates whether the patient is a man.
 * @param {Function} handlePregnantChange - Function to handle the change in pregnant status.
 * @param {string} patientGender - Gender of the patient ('MAN' or 'WOMAN').
 * @returns {Array} The array of main fields.
 */
export const mainFields = (
  patientIsMan,
  handlePregnantChange,
  patientGender
) => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedGender, setSelectedGender] = useState();

  useEffect(() => {
    if (patientGender === 'MAN') {
      setIsDisabled(true);
      handlePregnantChange();
    } else if (patientGender === 'WOMAN') {
      setIsDisabled(false);
    }
  }, [patientGender]);

  useEffect(() => {
    if (patientIsMan) {
      setIsDisabled(true);
    }
  }, [patientIsMan]);

  useEffect(() => {
    if (selectedGender === 'MAN') {
      setIsDisabled(true);
      handlePregnantChange();
    } else if (selectedGender === 'WOMAN') {
      setIsDisabled(false);
    }
  }, [selectedGender]);

  const handleGenderChange = (newValue) => {
    setSelectedGender(newValue);
  };

  return [
    {
      noLabel: true,
      name: 'gender',
      input: <GendersButtons onGenderChange={handleGenderChange} />,
      rules: [{ required: true }]
    },
    {
      label: 'last_name',
      name: 'last_name',
      rules: [{ required: true }]
    },
    {
      label: 'birth_name',
      name: 'birth_name'
    },
    {
      label: 'first_name',
      name: 'first_name',
      rules: [{ required: true }]
    },
    {
      label: 'date_of_birth',
      name: 'birth_date',
      rules: [{ type: 'date' }],
      input: <FormatedDatePicker antdKey="main" dbKey="birth_date" />
    },
    {
      label: 'height',
      name: 'height',
      input: <Input addonAfter="cm" />
    },
    {
      label: 'weight',
      name: 'weight',
      input: <Input addonAfter="kg" />
    },
    ...(!isDisabled
      ? [
          {
            label: 'pregnant',
            name: 'pregnant',
            valuePropName: 'checked',
            input: (
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            )
          }
        ]
      : []),
    {
      label: 'ins',
      name: 'ins',
      rules: [
        {
          len: 13,
          message: t(
            `patients.form.messages.invalid_social_security_number_length`
          )
        },
        { required: true },
        {
          validator: (_, value) => {
            if (
              !value ||
              value
                .split('')
                .every(
                  (char) => char.charCodeAt(0) >= 48 && char.charCodeAt(0) <= 57
                )
            ) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error(
                t(`patients.form.messages.invalid_social_security_number`)
              )
            );
          }
        }
      ]
    }
  ];
};
