import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import { Button, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { patchConsultation } from '../../Components/patchConsultation';
import { ConsultationsContext } from '../../ConsultationsContext';

const { TextArea } = Input;
const { Item } = Form;

export const MedicalComments = ({ resource, fields }) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const { user } = useAuthContext();
  const { patient } = useContext(ConsultationsContext);
  const author = `${user.first_name} ${user.last_name}`;

  const [form] = Form.useForm();
  const placeholder = t(`${resource}.form.add_comment`);
  const idConsultation = patient?.current_consultation?._id;
  const { handleSubmit } = patchConsultation(idConsultation);

  const handleChange = (e) => {
    setComment(e.target.value);
  };
  useEffect(() => {
    form.setFieldsValue({
      written_by: `${author}`
    });
  }, [user, patient]);
  return (
    <Form
      form={form}
      onFinish={(values) => handleSubmit(values)}
      layout="vertical"
    >
      {fields?.map((field) => (
        <Item
          key={field.name}
          label={
            field.label !== undefined ? t(`patients.form.${field.label}`) : null
          }
          name={field.name}
          rules={field.rules}
        >
          {(() => {
            switch (field.name) {
              case 'written_by':
                return <Input disabled />;
              case 'description':
                return (
                  <TextArea
                    value={comment}
                    onChange={handleChange}
                    placeholder={placeholder}
                    bordered={false}
                    style={{ resize: 'none' }}
                  />
                );
              default:
                return null;
            }
          })()}
        </Item>
      ))}
      <Row justify="end">
        <Button
          type="add"
          htmlType="submit"
          style={{
            borderRadius: '15px',
            margin: '2px'
          }}
        >
          {t('buttons.validate')}
        </Button>
      </Row>
    </Form>
  );
};

MedicalComments.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  resource: PropTypes.string.isRequired
};
