import { useState } from 'react';
import { Row, Table, Collapse, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useConsultationsContext } from '../../../ConsultationsContext';
import { CardsDisplay } from '../../../../patients/components/CardsHistory/CardsDisplay';
import { SorterDateReason } from '../../../Components/SorterDateReason';
import { useColumnsDoctorMails } from '../columns/columnsDoctorMails';
import { useColumnsDateReasons } from '../columns/columnsDateReasons';
import useFields from '../../../fields';
import { useColumns } from '../../../../patients/components/TreatmentPrescriptions/columns';

/**
 * A component that displays a modal for administration.
 *
 * @returns {JSX.Element} The ModalAdministration component.
 */
export const ModalAdministaration = () => {
  const [isSwitched, setIsSwitched] = useState(false);

  const { patient } = useConsultationsContext();
  const { first_name = '-', last_name = '-' } = patient || {};
  const { t } = useTranslation();
  const { identityCardFields, insuranceCardFields } = useFields();
  const [form] = Form.useForm();
  const columnsDoctorMails = useColumnsDoctorMails(isSwitched);
  const { columns } = useColumns({ fromConsultation: true });
  const columnsDateReasons = useColumnsDateReasons();

  form.setFieldsValue(patient);

  const resource = 'consultations';

  const handleSwitchChange = (enabled) => {
    setIsSwitched(enabled);
  };

  return (
    <>
      <Row>
        <h2 style={{ color: 'var(--primaryColor)', fontWeight: 'bold' }}>
          {t(`${resource}.documents.administration`)}&nbsp;
          {first_name}&nbsp;
          {last_name}
        </h2>
      </Row>
      <Form form={form}>
        <Row align-item="stretch" justify-content="space-betweenx">
          <CardsDisplay
            fields={identityCardFields}
            resource={resource}
            translationKey="identity_card"
          />
          <CardsDisplay
            fields={insuranceCardFields}
            resource={resource}
            translationKey="health_mutual_card"
          />
        </Row>
      </Form>
      <Collapse expandIconPosition="end">
        <Collapse.Panel
          header={t(`${resource}.documents.referring_physician_mails`)}
          key="1"
        >
          <SorterDateReason handleSwitchChange={handleSwitchChange} />
          <Table
            columns={columnsDoctorMails}
            dataSource={patient?.doctors_letters}
            className="ant-table-wrapper"
          />
        </Collapse.Panel>
      </Collapse>

      <Collapse expandIconPosition="end">
        <Collapse.Panel
          header={t(`${resource}.documents.treatment_prescriptions`)}
          key="2"
        >
          <Table
            dataSource={patient?.treatment_prescriptions}
            columns={columns}
            className="ant-table-wrapper"
          />
        </Collapse.Panel>
      </Collapse>

      <Collapse expandIconPosition="end">
        <Collapse.Panel
          header={t(`${resource}.documents.visit_certificates`)}
          key="3"
        >
          <Table columns={columnsDateReasons} className="ant-table-wrapper" />
        </Collapse.Panel>
      </Collapse>

      {/* En attente du client */}
      {/* <Collapse expandIconPosition="end">
        <Collapse.Panel
          header={t(`${resource}.documents.administrative_documents`)}
          key="4"
        >
          <Table columns={columnsDateReasons} />
        </Collapse.Panel>
      </Collapse> */}
    </>
  );
};
