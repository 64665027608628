import { useContext } from 'react';
import { TreeSelect, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { ConsultationsContext } from '../../ConsultationsContext';

/**
 * @component
 * @description A component that displays a tree select with all the visit reasons
 * @returns {JSX.Element} A tree select with all the visit reasons
 */
export const ReasonsSelect = () => {
  const { treeData } = useContext(ConsultationsContext);
  const { t } = useTranslation();

  return (
    <Form.Item name="visit_reasons" rules={[{ required: true }]}>
      <TreeSelect
        showSearch
        style={{
          width: '100%'
        }}
        dropdownStyle={{
          maxHeight: 400,
          overflow: 'auto'
        }}
        placeholder={t('consultations.form.visit_reasons_placeholder')}
        allowClear
        multiple
        treeDefaultExpandAll
        treeData={treeData}
      />
    </Form.Item>
  );
};
