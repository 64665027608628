import { ListResource } from '../../../components';
import { CustomAction } from '../components/CustomAction';
import { useColumns } from './columns';

/**
 * React component for rendering a list of protocols with custom actions.
 *
 * @returns {JSX.Element} - The rendered React element.
 */
export const ListProtocol = () => {
  const { columns } = useColumns();

  return (
    <>
      <CustomAction resource="protocols" />
      <ListResource
        isPageHeaderCustom={false}
        isOriginalContentCustom={false}
        showAction={false}
        populate="visit_reasons"
        resourceName="protocols"
        path="protocols"
        columns={columns}
        archiveAction
      />
    </>
  );
};
