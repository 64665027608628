import { useContext } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ConsultationsContext } from '../ConsultationsContext';

export const patchConsultation = (
  idConsultation,
  handleCloseModal = () => {}
) => {
  const resource = 'consultations';
  const _id = idConsultation;
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { setForcedRefresh, forcedRefresh } = useContext(ConsultationsContext);

  const updateResource = async (body) => {
    try {
      const response = await dispatchAPI('PATCH', {
        url: `${resource}/${_id}`,
        body: {
          ...body,
          written_by: `${user.first_name} ${user.last_name}`
        }
      });
      if (response.status === 200) {
        setForcedRefresh(!forcedRefresh);
      }
    } catch (e) {
      message(e);
    }
  };

  const handleSubmit = async (values) => {
    await updateResource(values);
    handleCloseModal();
  };

  return { handleSubmit };
};
