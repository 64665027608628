import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, List, Button, Spin } from 'antd';
import { HeartFilled, FileOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { usePrescriptionContext } from '../../../contexts/PrescriptionContext/PrescriptionContext';

/**
 * @component FavoritesCard
 * @returns {React.Component} Card to display the list of favorite prescriptions
 * @description This component is used in the exit prescription
 */
export const FavoritesCard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { type } = useParams();
  const { dispatchAPI, user } = useAuthContext();
  const { setPosologies, setContent, setEntities, patientId } =
    usePrescriptionContext();
  const { message } = useErrorMessage();
  const [favorites, setFavorites] = useState([]);
  const translationKey = 'prescription.cards.favorites';

  const getFavoritesPrescriptions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/prescriptions/favorites?author=${user._id}&authorized=${user._id}&type=${type}&archived=false`
      });
      setFavorites(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getFavoritesPrescriptions();
    })();
  }, []);

  /**
   * Handle the selection of a favorite prescription.
   *
   * @function
   * @param {Object} favorite - The favorite prescription selected.
   * @property {string} favorite.purpose - The purpose of the prescription.
   * @property {string} favorite.content - The content of the prescription.
   * @property {Array} favorite.posologies - The posologies associated with the prescription.
   * @returns {void}
   */
  const handleSelectFavorite = (favorite) => {
    if (!favorite) {
      return;
    }

    const { purpose, content, posologies } = favorite;

    const handleFreeTextSelection = () => {
      const formattedContent = content
        ?.replace(/<body>/g, '')
        .replace(/<\/body>/g, '');

      setPosologies([]);
      setEntities([]);
      setContent(formattedContent);
      navigate(`/prescription/create/free-text/${type}/${patientId}`);
    };

    const handleMedicationSelection = () => {
      setContent('');
      setPosologies(posologies);
      const entities = posologies?.map(({ entity }) => ({ ...entity }));
      setEntities(entities);
      navigate(`/prescription/create/medications/${type}/${patientId}`);
    };

    if (purpose === 'FREE-TEXT') {
      handleFreeTextSelection();
    } else {
      handleMedicationSelection();
    }
  };

  return (
    <Card
      className="ant-card "
      title={
        <>
          <HeartFilled /> {t(`${translationKey}.title`)}
        </>
      }
    >
      <Spin spinning={!favorites.length}>
        <List
          dataSource={favorites}
          renderItem={(favorite) => (
            <List.Item>
              <List.Item.Meta
                description={
                  <Button
                    type="link"
                    onClick={() => handleSelectFavorite(favorite)}
                  >
                    <FileOutlined /> {favorite?.label}
                  </Button>
                }
              />
            </List.Item>
          )}
        />
      </Spin>
    </Card>
  );
};
