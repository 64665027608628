// Main nav routes
export const routes = {
  HOME: '/',
  ADMINISTRATIVE: '/administrative',
  LABORATORY: '/laboratory',
  PATIENTS: '/patients',
  USERS: '/users',
  CONSULTATIONS: '/consultations',
  DIRECTORY: '/directory',
  CENTERS: '/sub-centers',
  DATA_BASE: '/data-bases'
};

// Main nav subMenuItems
export const subRoutes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  FAVORITES: '/favorites',
  CONSULTATION: '/consultation',
  PRESCRIPTION: '/prescription'
};

// url search parameters
export const pathSearches = {};
