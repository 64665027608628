// Main nav menuItems
// View is not displayed following the customer's request
export const doctorRoutes = {
  HOME: '/',
  // VIEW: '/view',
  ADMINISTRATIVE: '/administrative',
  LABORATORY: '/laboratory',
  PATIENTS: '/patients',
  CONSULTATIONS: '/consultations'
};
export const secretaryRoutes = {
  HOME: '/',
  ROOMS: '/rooms',
  // VIEW: '/view',
  DIRECTORY: '/directory',
  PATIENTS: '/patients',
  CONSULTATIONS: '/consultations'
};
export const userRoutes = {
  HOME: '/',
  ROOMS: '/rooms',
  PATIENTS: '/patients',
  DIRECTORY: '/directory',
  CONSULTATIONS: '/consultations'
};

// Main nav subMenuItems
export const subRoutes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  FAVORITES: '/favorites',
  PRESCRIPTION: '/prescription'
};

// url search parameters
export const pathSearches = {};
