import React, { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Divider, Radio, Row, Button, Spin } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useGenerateFormItem } from '../../utils/generateFormItem';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';
import {
  formItemLayout,
  tailFormItemLayout,
  noLabelLayout
} from '../../utils/constants/formLayout';
import useFields from './fields';

const resource = 'centers';

/**
 * Component for creating or updating a center.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {JSX.Element} The CreateUpdateCenter component.
 */
export const CreateUpdateCenter = ({ purpose }) => {
  const navigate = useNavigate();
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const [address, setAddress] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [radioValue, setRadioValue] = useState('create');
  const { centerFields, userFields, userSelect } = useFields(
    radioValue,
    address,
    setAddress
  );

  const onChange = (e) => {
    setRadioValue(e.target.value);
  };

  const getResource = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${resource}/${id}?populate=manager`
      });

      if (data.address) setAddress(data.address);

      form.setFieldsValue({
        center: data,
        user: data.manager
      });

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      message(e);
    }
  }, [purpose, id]);

  const createResource = async (body) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('POST', {
        url: `${resource}`,
        body: {
          ...body,
          address
        }
      });
      navigate(-1);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const updateResource = async (body) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('PATCH', {
        url: `${resource}/${id}`,
        body
      });
      navigate(-1);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const handleSubmit = async (values) => {
    if (purpose === 'edit') await updateResource(values);
    if (purpose === 'create') await createResource(values);
  };

  useEffect(() => {
    if (purpose === 'edit' && id) {
      setIsLoading(true);
      (async () => {
        await getResource();
      })();
    }
  }, [getResource]);

  return (
    <>
      <PageHeaderCustom title={t(`${resource}.form.title.${purpose}`)} />
      <ContentCustom>
        <Spin spinning={isLoading}>
          <Form {...formItemLayout} form={form} onFinish={handleSubmit}>
            <Divider orientation="left">
              {t(`${resource}.form.title.company`)}
            </Divider>
            {centerFields.map((field) => generateFields(resource, field))}
            <Divider orientation="left">
              {t(`${resource}.form.title.manager`)}
            </Divider>
            <Form.Item {...noLabelLayout}>
              <Radio.Group
                style={{ marginBottom: '12px' }}
                value={radioValue}
                onChange={onChange}
              >
                <Radio value="create">
                  {t(`${resource}.form.create_manager`)}
                </Radio>
                <Radio value="select">
                  {t(`${resource}.form.add_existing_manager`)}
                </Radio>
              </Radio.Group>
            </Form.Item>
            {radioValue === 'create'
              ? userFields.map((field) => generateFields(resource, field))
              : userSelect.map((field) => generateFields(resource, field))}
            <Form.Item {...tailFormItemLayout}>
              <Row justify="end">
                <Button
                  style={{ margin: '0 10px' }}
                  type="link"
                  danger
                  onClick={() => navigate(-1)}
                >
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button type="add" htmlType="submit" loading={isSubmitting}>
                  {`${t('buttons.save')} `}
                  <CheckOutlined />
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </ContentCustom>
    </>
  );
};

CreateUpdateCenter.propTypes = {
  purpose: PropTypes.string.isRequired
};
