import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { ConsultationsContext } from '../../../ConsultationsContext';
import { UploadPhotoModal } from '../modals/UploadPhotoModal';

/**
 * A component that displays an upload photo button.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.fields - The fields to be uploaded.
 *
 * @returns {JSX.Element} The UploadPhotoButton component.
 */
export const UploadPhotoButton = ({ fields }) => {
  const resource = 'consultations';
  const [isModal, setIsModal] = useState(false);
  const { patient } = useContext(ConsultationsContext);
  const idConsultation = patient?.current_consultation?._id;
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t(`buttons.photo`)}>
        <Button type="Link" onClick={() => setIsModal(true)}>
          <CameraOutlined />
        </Button>
      </Tooltip>
      <UploadPhotoModal
        isModal={isModal}
        setIsModal={setIsModal}
        fields={fields}
        idConsultation={idConsultation}
        resource={resource}
      />
    </>
  );
};
UploadPhotoButton.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({}))
};
UploadPhotoButton.defaultProps = {
  fields: undefined
};
