import PropTypes from 'prop-types';
import { useState } from 'react';
import { Form, Select } from 'antd';
import axios from 'axios';
import { useErrorMessage } from '../../../../utils/errorMessage';

const { Item } = Form;

/**
 * React component for rendering a SelectDiagnostic component.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {string} [props.dbKey] - The key used in the Form.Item name (optional).
 * @returns {JSX.Element} The rendered React element.
 */
export const SelectDiagnostic = ({ dbKey }) => {
  const { message } = useErrorMessage();

  const [value, setValue] = useState('');
  const [concepts, setConcept] = useState([]);

  /**
   * Function to search for concepts based on the provided text.
   *
   * @async
   * @function
   * @param {string} newValue - The text to search for concepts.
   * @returns {Promise<void>} A Promise that resolves when the search is complete.
   */
  const handleSearchConcept = async (newValue) => {
    if (newValue.length >= 3) {
      await axios
        .post(
          `https://smt.esante.gouv.fr/api/concepts/search?searchedText=${newValue}&pagination=1&size=20&terminologyFilters=terminologie-cim-10&isBrowserSnomedLicence=false`
        )
        .then((data) => {
          setConcept(data?.data?.concepts);
        })
        .catch((e) => {
          message(e);
        });
    }
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <Item name={[`${dbKey}`]}>
      <Select
        showSearch
        mode="multiple"
        onSearch={handleSearchConcept}
        onChange={handleChange}
        style={{ width: 200 }}
        value={value}
        defaultActiveFirstOption={false}
        suffixIcon={null}
        filterOption={false}
        notFoundContent={null}
        options={concepts?.map((item) => ({
          value: item.prefLabel,
          label: item.prefLabel
        }))}
      />
    </Item>
  );
};
SelectDiagnostic.propTypes = {
  dbKey: PropTypes.string
};
SelectDiagnostic.defaultProps = {
  dbKey: undefined
};
