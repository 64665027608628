import PropTypes from 'prop-types';
import { Button, Card, Collapse, Form, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { WaitingRoomsTable } from '../WaitingRooms/WaitingRoomsTable';
import { useFields } from './useFields';

const { Panel } = Collapse;
const { Option } = Select;

/**
 * Renders the WaitingRooms component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.rooms - The array of rooms.
 * @param {Function} props.refresh - The refresh function.
 * @param {Function} props.setRefresh - The setRefresh function.
 * @param {string} props.id - The ID of the waiting room.
 * @param {Function} props.postRoom - The postRoom function.
 * @returns {JSX.Element} The rendered WaitingRooms component.
 */
export const WaitingRooms = ({ rooms, refresh, setRefresh, id, postRoom }) => {
  const { t } = useTranslation();
  const { fields } = useFields();

  return (
    <Card style={{ marginBottom: 24 }}>
      <Collapse expandIconPosition="end" defaultActiveKey={['3']} ghost>
        <Panel header={t('sub_centers.settings.waiting_rooms')} key="3">
          {fields.map((type) => (
            <Form.Item key={type.name} name={[type.name]} label={t(type.label)}>
              <Select>
                {rooms.map((room) => (
                  <Option key={room._id} value={room._id}>
                    {room.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ))}
          <WaitingRoomsTable
            refresh={refresh}
            setRefresh={setRefresh}
            id={id}
          />
          <Button
            style={{ width: '100%' }}
            type="dashed"
            onClick={() => postRoom('WAITING_ROOM')}
          >
            <PlusOutlined />
            {t('buttons.add_room')}
          </Button>
        </Panel>
      </Collapse>
    </Card>
  );
};

WaitingRooms.propTypes = {
  rooms: PropTypes.arrayOf(PropTypes.shape({})),
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  id: PropTypes.string,
  postRoom: PropTypes.func
};

WaitingRooms.defaultProps = {
  rooms: [],
  refresh: false,
  setRefresh: () => {},
  id: '',
  postRoom: () => {}
};
