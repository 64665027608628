import propTypes from 'prop-types';
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Button, Modal, Col } from 'antd';
import { LineChartOutlined } from '@ant-design/icons';
import { ConsultationsContext } from '../../ConsultationsContext';
import { ChartConstant } from './ChartConstant';
import { TableSurvey } from './TableSurvey';

export const ModalChart = ({ chartType, title }) => {
  const { t } = useTranslation();
  const [isModal, setModal] = useState(false);
  const {
    patient: { first_name = '-', last_name = '-', constants_survey } = {},
    enums
  } = useContext(ConsultationsContext);
  const data = constants_survey?.[title];
  const units = enums?.enumsUnits?.find((unit) => unit[chartType])?.[chartType];

  return (
    <Row>
      <Button icon={<LineChartOutlined />} onClick={() => setModal(true)} />
      <Modal
        open={isModal}
        onCancel={() => setModal(false)}
        okText={t('buttons.validate')}
        width="90%"
        footer={null}
      >
        <h2>
          {t(`constants_surveys.form.${chartType}`)} - {first_name} {last_name}{' '}
        </h2>
        <Row justify="center">
          <Col span={8}>
            <Row>{t(`constants_surveys.survey_history`)}</Row>
            <Row justify="center">
              <TableSurvey
                chartType={chartType}
                title={title}
                data={data}
                enums={enums}
              />
            </Row>
          </Col>
          <Col span={16}>
            {' '}
            <Row justify="center">
              <Row>
                <span
                  style={{
                    transform: 'rotate(-90deg)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start'
                  }}
                >
                  {t(`constants_surveys.form.${chartType}`)} ({units})
                </span>
              </Row>
              <Col span={12}>
                <Row>
                  <ChartConstant
                    chartType={chartType}
                    title={title}
                    data={data}
                    enums={enums}
                  />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </Row>
  );
};

ModalChart.propTypes = {
  chartType: propTypes.string.isRequired,
  title: propTypes.string.isRequired
};
