import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * LoginForm component for user login.
 *
 * @component
 * @param {Function} switchForm - Function to switch between login and forgot password forms.
 * @returns {JSX.Element} The rendered component.
 */
const LoginForm = ({ switchForm }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tokenFromUrl = searchParams.get('token');
  const refresh_token = searchParams.get('refresh_token');
  const user_id = searchParams.get('user_id');
  const {
    isValid,
    dispatchAPI,
    remember,
    setUser,
    setSession,
    token,
    chooseCenter
  } = useAuthContext();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const { from } = location?.state || { from: { pathname: '/' } };

  const loginUser = async (email, password, rememberMe) => {
    setLoading(true);
    try {
      await dispatchAPI('LOGIN', {
        email,
        password,
        rememberMe
      });
    } catch (e) {
      if (e.response) {
        if (e.response.data && e.response.data.error) {
          message.error(e.response.data.error.description);
        } else {
          message.error(t('login.wrongDetails'));
        }
      } else if (e.request) {
        message.error(t('login.networkError'));
      } else {
        message.error(t('login.error'));
      }
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    await loginUser(
      values.username.toLowerCase(),
      values.password,
      values.remember
    );
  };

  const redirectToPSC = async () => {
    try {
      const response = await dispatchAPI('POST', {
        url: '/psc/getPSCStateAndRedirect',
        body: {
          currentUrl: window.location.href
        }
      });
      if (response.status === 200) {
        window.location.href = response.data.redirectPath;
      }
    } catch (error) {
      message.error(t('login.PSC_error'));
    }
  };

  const getUser = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/user/${id}`
      });
      setUser(data);
    } catch (error) {
      message.error(t('login.error'));
    }
  };

  useEffect(() => {
    (() => {
      setTimeout(() => {
        if (tokenFromUrl && refresh_token && user_id) {
          setSession(tokenFromUrl);
        }
      }, 1000);
      if (token === tokenFromUrl && user_id) {
        getUser(user_id);
      }
    })();
  }, [token]);

  useEffect(() => {
    (() => {
      if (tokenFromUrl && refresh_token && user_id) {
        setSession(tokenFromUrl);
      }
    })();
  }, [tokenFromUrl, refresh_token, user_id]);

  useEffect(() => {
    if (chooseCenter) switchForm('chooseCenter');
  }, [chooseCenter]);

  return (
    <Form onFinish={handleSubmit} initialValues={{ remember }}>
      {isValid && <Navigate to={from} />}
      <Form.Item
        name="username"
        rules={[{ required: true, message: t('login.usernameMissing') }]}
      >
        <Input
          prefix={
            <UserOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
          placeholder={t('login.username')}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: t('login.pwdMissing') }]}
      >
        <Input.Password
          prefix={
            <LockOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
          placeholder={t('login.password')}
        />
      </Form.Item>
      <Form.Item style={{ textAlign: 'left' }}>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>{t('login.rememberMe')}</Checkbox>
        </Form.Item>
        <Button
          type="link"
          style={{ float: 'right', padding: 0, height: 'auto' }}
          onClick={() => switchForm('forgotPwd')}
        >
          {t('login.forgotPwd')}
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%' }}
          loading={isLoading}
        >
          {t('login.connect')}
        </Button>
      </Form.Item>
      <Button onClick={() => redirectToPSC()} type="link">
        {t('login.CPS_auth')}
      </Button>
    </Form>
  );
};

LoginForm.propTypes = {
  switchForm: PropTypes.func.isRequired
};

export default LoginForm;
