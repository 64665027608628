// Main nav menuItems
export const routes = {
  DASHBOARD: '/',
  USERS: '/users',
  CENTERS: '/centers',
  DATA_BASE: '/data-base',
  PATIENTS: '/patients'
};

// Main nav subMenuItems
export const subRoutes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  LOGIN_2FA: '/login-2fa',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  SUBCENTERS: '/sub-centers',
  PRESCRIPTION: '/prescription',
  FAVORITES: '/favorites'
};

// url search parameters
export const pathSearches = {};
