import PropTypes from 'prop-types';
import useSpecialistFields from './fields';
import { CreateUpdateContainer } from '../../components';

/**
 * Component for creating or updating a specialist.
 * This component renders a CreateUpdateContainer component configured with specialist fields.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.purpose - The purpose of the specialist creation/update.
 * @returns {JSX.Element} CreateUpdateSpecialist component.
 */
export const CreateUpdateSpecialist = ({ purpose }) => {
  const { fields, isFieldsLoading } = useSpecialistFields();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="specialists"
      resource="specialists"
      config={config}
    />
  );
};

CreateUpdateSpecialist.propTypes = {
  purpose: PropTypes.string.isRequired
};
