import { Link } from 'react-router-dom';
import { Avatar, Image, Tag } from 'antd';
import { userRoles } from '../../utils/constants/tagColors';
import { getPhoneNumber } from '../../utils/formatters';

/**
 * Generates the list content for the profile page.
 *
 * @hook
 * @param {Object} props - The profile data.
 * @param {string} props.first_name - The first name of the user.
 * @param {string} props.last_name - The last name of the user.
 * @param {string} props.role - The role of the user.
 * @param {string} props.phone_number - The phone number of the user.
 * @param {string} props.email - The email of the user.
 * @param {string} props.photo - The photo of the user.
 * @param {Object} props.associated_center - The associated center of the user.
 * @param {boolean} props.double_factor_authentication - Indicates if double factor authentication is enabled for the user.
 * @param {string} props.position - The position of the user.
 * @param {string} props.specialization - The specialization of the user.
 * @param {string} props.academic_carreer - The academic career of the user.
 * @param {Object} props.signature - The signature of the user.
 * @param {Function} t - The translation function.
 * @param {string} token - The token of the user.
 * @returns {Array} - The list content for the profile page.
 */
const listContent = (
  {
    first_name,
    last_name,
    role,
    phone_number,
    email,
    avatar,
    associated_center,
    double_factor_authentication,
    position,
    specialization,
    academic_carreer,
    signature
  },
  t,
  token
) => [
  {
    label: '',
    content: avatar && (
      <Avatar
        src={`${process.env.REACT_APP_API_URL}/users/get-url/${avatar}/${token}`}
        size={120}
      />
    ),
    span: 3
  },
  {
    label: 'profile.personalInfo.first_name',
    content: first_name,
    span: 1
  },
  {
    label: 'profile.personalInfo.last_name',
    content: last_name,
    span: 1
  },
  {
    label: 'profile.personalInfo.role',
    content: role && (
      <Tag color={userRoles[role.split(':')[1]]}>
        {t(`users.tags.${role.split(':')[1]}`)}
      </Tag>
    ),
    span: 1
  },
  {
    label: 'profile.form.phone_number',
    span: 1,
    content: getPhoneNumber(phone_number)
  },
  {
    label: 'profile.personalInfo.email',
    content: email,
    span: 1
  },
  {
    label: 'profile.form.associated_center',
    content: associated_center ? (
      <Link to={`/centers/show/${associated_center._id}`}>
        {associated_center.name}
      </Link>
    ) : (
      '-'
    ),
    span: 1
  },
  ...(position === 'DOCTOR'
    ? [
        {
          label: 'profile.form.specialization',
          content: specialization || '-',
          span: 1
        },
        {
          label: 'profile.form.academic_carreer',
          content: academic_carreer || '-',
          span: 1
        },
        {
          label: 'profile.form.signature',
          content: signature ? (
            <Image preview={false} src={signature.image} size={120} />
          ) : (
            '-'
          ),
          span: 1
        }
      ]
    : []),
  {
    label: 'profile.form.double_factor_authentication',
    content: double_factor_authentication ? t('yes') : t('no')
  }
];

export default listContent;
