/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useConsultationsContext } from './ConsultationsContext';
import { ShowGeneralInfos } from './PatientOverview/ShowGeneralInfos';
import { ShowMedicalBackground } from './PatientOverview/ShowMedicalBackground';
import { ShowResultsLaboratories } from './PatientOverview/ShowResultsLaboratories';
import { Prescriptions } from './PatientOverview/Prescriptions/Prescription';
import { ShowDocuments } from './PatientOverview/Documents/ShowDocuments';
import { ShowStayHistory } from './PatientOverview/ShowStayHistory';
import { ShowPricing } from './PatientOverview/ShowPricing';
import { ShowTreatments } from './PatientOverview/ShowTreatments';
import { ShowMedicalConsultation } from './PatientOverview/MedicalConsultation/ShowMedicalConsultation';
import { ShowSurveyOfConstants } from './PatientOverview/ShowSurveyOfConstants';
import { ShowMedicalComments } from './PatientOverview/ShowMedicalComments';
import { ShowEntryNotifications } from './PatientOverview/ShowEntryNotifications';
import { ShowParamedicalsTransmissions } from './PatientOverview/ShowParamedicalsTransmissions';

import useFields from './fields';

/**
 * A component that displays a consultation.
 *
 * @component
 *
 * @returns {JSX.Element} The ShowConsultation component.
 */
export const ShowConsultation = () => {
  const { id } = useParams();
  const { getPatient, isLoading, setIsLoading, forcedRefresh, patient } =
    useConsultationsContext();
  const { first_name = '', last_name = '' } = patient || {};
  const patientName = `${first_name} ${last_name}`;

  const {
    medicalBackgroundFields,
    resultsLaboratiesFields,
    medicalCommentsFields
  } = useFields();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getPatient(id);
      setIsLoading(false);
    })();
  }, [getPatient, forcedRefresh]);

  const colItemStyle = {
    marginBottom: '8px'
  };

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[16, 16]}>
        <Row
          gutter={[16, 16]}
          className="show-consultation-row"
          align="stretch"
        >
          <Col span={12} className="show-consultation-col">
            <ShowGeneralInfos id={id} />
          </Col>
          <Col span={5} className="show-consultation-col">
            <ShowDocuments />
          </Col>
          <Col span={7} className="show-consultation-col">
            <ShowPricing />
          </Col>
        </Row>
        <Row
          gutter={[16, 16]}
          style={{ width: '100%' }}
          className="show-consultation-row"
        >
          <Col span={6} className="show-consultation-col">
            <ShowMedicalBackground
              id={id}
              fields={medicalBackgroundFields}
              style={colItemStyle}
            />
            <ShowResultsLaboratories
              id={id}
              fields={resultsLaboratiesFields}
              style={colItemStyle}
            />
            <ShowStayHistory id={id} style={colItemStyle} />
            <ShowEntryNotifications id={id} style={colItemStyle} />
          </Col>
          <Col span={12} className="show-consultation-col">
            <ShowMedicalConsultation id={id} />
            <Prescriptions />
            <ShowParamedicalsTransmissions />
          </Col>
          <Col span={6} className="show-consultation-col">
            <ShowTreatments id={id} />
            <ShowSurveyOfConstants id={id} />
            <ShowMedicalComments id={id} fields={medicalCommentsFields} />
          </Col>
        </Row>
      </Row>
    </Spin>
  );
};
