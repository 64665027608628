import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CreateUpdateSpecialist } from './CreateUpdateSpecialist';
import { ListSpecialist } from './ListSpecialist';
import { Exception } from '../../components';

/**
 * Router component for managing specialist-related routes.
 *
 * @component
 *
 * @returns {JSX.Element} The SpecialistRouter component.
 */
export const SpecialistRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdateSpecialist purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdateSpecialist purpose="edit" />}
    />
    <Route path="/" element={<ListSpecialist />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
