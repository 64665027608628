import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Divider, Row } from 'antd';
import { usePrescriptionContext } from '../../../contexts/PrescriptionContext/PrescriptionContext';

// Synapse components
import { SynapsePosologyFormWidget } from '../../../components/Synapse/SynapsePosologyWidget';
import { SynapseMonographWidget } from '../../../components/Synapse/SynapseMonographWidget';

/**
 * This component renders the Drugs List.
 * @component DrugsList
 * @returns {JSX.Element} Drugs List
 */
export const DrugsList = () => {
  const { t } = useTranslation();
  const { entities, setEntities, posologies, setPosologies } =
    usePrescriptionContext();
  const [modalStates, setModalStates] = useState({}); // This will hold the open state for each modal

  const customLabel = (label) => {
    const parts = label.split(',');
    return (
      <span>
        <strong>{parts[0]}</strong>
        {`, ${parts[1]}`}
      </span>
    );
  };

  const removeEntity = (index) => {
    const newEntities = [...entities]; // Create a new array to avoid direct mutation
    const newPosologies = posologies.filter(
      (posology) => posology.entity.drug_id !== newEntities[index].drug_id
    );
    newEntities.splice(index, 1);
    setPosologies(newPosologies);
    setEntities(newEntities);
  };

  const showModal = (target, entity) => {
    setModalStates((prev) => ({
      ...prev,
      [entity.drug_id]: { isOpen: true, purpose: target }
    }));
  };

  const handleOk = (drug_id) => {
    setModalStates((prev) => ({
      ...prev,
      [drug_id]: { ...prev[drug_id], isOpen: false }
    }));
  };

  const renderModalContent = (entity) => {
    const modalState = modalStates[entity.drug_id];
    if (!modalState) return null;

    switch (modalState.purpose) {
      case 'posology_form':
        return (
          <SynapsePosologyFormWidget
            entity={entity}
            onOk={() => handleOk(entity.drug_id)}
          />
        );
      case 'monograph':
        return (
          <SynapseMonographWidget
            entity={entity}
            onOk={() => handleOk(entity.drug_id)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {entities.map((entity) => (
        <Modal
          key={entity.drug_id}
          title={entity && customLabel(entity.label)}
          open={modalStates[entity.drug_id]?.isOpen || false}
          onOk={() => handleOk(entity.drug_id)}
          onCancel={() => handleOk(entity.drug_id)}
          footer={null}
        >
          {renderModalContent(entity)}
        </Modal>
      ))}
      <Row justify="center" style={{ flexDirection: 'column', gap: 16 }}>
        {entities.map((entity, index) => (
          <Row key={entity?.drug_id}>
            {customLabel(entity.label)}
            <Button onClick={() => showModal('posology_form', entity)}>
              {t('prescription.posology')}
            </Button>
            <Divider type="vertical" />
            <Button onClick={() => showModal('monograph', entity)}>
              {t('prescription.monograph')}
            </Button>
            <Divider type="vertical" />
            <Button danger onClick={() => removeEntity(index)}>
              {t('prescription.remove_drug')}
            </Button>
          </Row>
        ))}
      </Row>
    </>
  );
};
