import PropTypes from 'prop-types';
import useDirectoryFields from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Renders a component for creating or updating directory entries.
 * This component utilizes a CreateUpdateContainer for managing directory data.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the directory operation (e.g., 'create' or 'update').
 * @returns {JSX.Element} The rendered component for creating or updating directory entries.
 */
export const CreateUpdateDirectory = ({ purpose }) => {
  const { fields, isFieldsLoading } = useDirectoryFields();
  const {
    user: { associated_center = '' }
  } = useAuthContext();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        center: associated_center
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="directories"
      resource="directories"
      config={config}
    />
  );
};

CreateUpdateDirectory.propTypes = {
  purpose: PropTypes.string.isRequired
};
