import { Form, Table } from 'antd';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFields } from './fields';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { useColumns } from './columns';

/**
 * Renders the DoctorsLetterForm component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.filesList - The list of files.
 * @param {Function} props.setFilesList - The function to set the list of files.
 * @returns {JSX.Element} The rendered DoctorsLetterForm component.
 */
export const DoctorsLetterForm = ({ filesList = [], setFilesList }) => {
  const [submittable, setSubmittable] = useState(false);
  const [doctorsLetterFile, setDoctorsLetterFile] = useState({});

  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();
  const { columns } = useColumns();
  const { doctorsLetterFields } = useFields({
    filesList,
    setFilesList,
    doctorsLetterFile,
    setDoctorsLetterFile,
    submittable,
    form
  });

  const values = Form.useWatch([], form);
  useEffect(() => {
    form
      .validateFields({
        validateOnly: true
      })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  return (
    <>
      <Table dataSource={filesList} columns={columns} />
      <Form form={form}>
        {doctorsLetterFields.map((field) => generateFields('patients', field))}
      </Form>
    </>
  );
};

DoctorsLetterForm.propTypes = {
  filesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFilesList: PropTypes.func.isRequired
};
