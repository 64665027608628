import {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import translateTreeData from '../../utils/translateTreeData';
import { GetEnums } from '../../utils/getEnums';
import { Prescription } from '../../models/Prescription';

export const ConsultationsContext = createContext();

/**
 * Provides the context for consultations data.
 *
 * @component
 * @param {Object} props - The props of the component.
 * @param {Object} props.children - The children of the component.
 * @returns {JSX.Element} The ConsultationsContextProvider component.
 */
export const ConsultationsContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { getEnums } = GetEnums();
  const [enums, setEnums] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [patient, setPatient] = useState({});
  const [forcedRefresh, setForcedRefresh] = useState(false);
  const [subCenterRooms, setSubCenterRooms] = useState(null);
  const [selectedVisitValue, setSelectedVisitValue] = useState('');
  const [position, setPosition] = useState('');
  const [biologyPrescriptions, setBiologyPrescriptions] = useState([]);

  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();

  const idSubCenter = user?.selected_sub_center || user?.default_sub_center;

  const fetchAndTranslateTreeData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/visit-reasons/tree-data`
      });

      const translatedTreeData = translateTreeData(
        data,
        'data_bases.visit-reasons.categories',
        t
      );

      setTreeData(translatedTreeData);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPatient = useCallback(async (id) => {
    setIsLoading(true);

    const population = [
      'documentsIds',
      'previous_consultations',
      'previous_consultations.visit_reasons',
      'current_consultation',
      'current_consultation.visit_reasons',
      'constants_survey',
      'paramedicals_transmissions.nurse',
      'doctors_letters.doctors_letter_document',
      'treatment_prescriptions.treatment_prescriptions_document'
    ];

    try {
      const { data } = await dispatchAPI('GET', {
        url: `/patients/${id}?populate=${population.join(',')}`
      });
      setPatient(data);
    } catch (e) {
      message(e);
    }
  }, []);

  const fetchSubCenter = async () => {
    const population = [
      'first_visit_waiting_room',
      'follow_up_waiting_room',
      'return_visit_waiting_room'
    ];

    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sub-centers/${idSubCenter}?populate=${population.join(',')}`
      });
      return data;
    } catch (e) {
      if (e.response) message(e.response.status);
      return null;
    }
  };
  const fetchEnumsAndRooms = async () => {
    setIsLoading(true);
    try {
      const [enumsPatients, enumsConsultations, enumsConstants, rooms] =
        await Promise.all([
          getEnums('patients'),
          getEnums('consultations'),
          getEnums('constants'),
          fetchSubCenter()
        ]);
      const allEnums = {
        ...enumsPatients,
        ...enumsConsultations,
        ...enumsConstants
      };
      setEnums(allEnums);
      setSubCenterRooms(rooms);
      setIsLoading(false);
      return null;
    } catch (e) {
      if (e.response) message(e.response.status);
      return null;
    }
  };

  const getBiologiesPrescriptions = async () => {
    const response = await Prescription.getBiologiesPrescriptions({
      category: 'BIOLOGY',
      populate: 'prescription_document'
    });
    setBiologyPrescriptions(response);
  };

  useEffect(() => {
    (async () => {
      await Promise.all([
        fetchEnumsAndRooms(),
        fetchAndTranslateTreeData(),
        getBiologiesPrescriptions()
      ]);
    })();
    if (user) {
      setPosition(user?.position);
    }
  }, []);

  return (
    <ConsultationsContext.Provider
      value={{
        enums,
        treeData,
        isLoading,
        setIsLoading,
        patient,
        getPatient,
        forcedRefresh,
        setForcedRefresh,
        subCenterRooms,
        setSubCenterRooms,
        selectedVisitValue,
        setSelectedVisitValue,
        position,
        setPosition,
        biologyPrescriptions
      }}
    >
      {children}
    </ConsultationsContext.Provider>
  );
};

export const useConsultationsContext = () => useContext(ConsultationsContext);
