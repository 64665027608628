import { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { routes, outOfNavRoutes } from '../utils/constants/super-userRoutes';
import { Login } from '../routes/login';
import { HomeDoctorKanban } from '../routes/home/HomeDoctorKanban';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { SuperUserLayout } from '../components/layouts/SuperUserLayout';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';
import { UserTheme } from '../assets/styles/Theme/UserTheme';
import { PatientRouter } from '../routes/patients/PatientRouter';
import { SecretaryDoctorView } from '../routes/patients/SecretaryDoctorView';
import { ConsultationsRouter } from '../routes/consultations/ConsultationsRouter';
import { PrescriptionsRouter } from '../routes/prescription/PrescriptionsRouter';
import { FavoritesRouter } from '../routes/favorites/FavoritesRouter';
import { DirectoryRouter } from '../routes/directory/DirectoryRouter';
import { UserRouter } from '../routes/users/UserRouter';
import { SubCenterRouter } from '../routes/subCenters/SubCenterRouter';
import { ManagerDataBaseRouter } from '../routes/dataBase/managerConfigurations/ManagerDataBaseRouter';
import { LaboratoryRouter } from '../routes/laboratory/LaboratoryRouter';

const RequireAuth = ({ isValid }) => {
  const location = useLocation();

  if (!isValid) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }

  return <SuperUserLayout />;
};

RequireAuth.propTypes = {
  isValid: PropTypes.bool.isRequired
};

export const SuperUserRouter = () => {
  const { isValid } = useAuthContext();

  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'user';
    }
  }, []);
  return (
    <BrowserRouter>
      <UserTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth isValid={isValid} />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route
            path={`${outOfNavRoutes.FAVORITES}/*`}
            element={<FavoritesRouter />}
          />
          <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
          <Route path={`${routes.HOME}/*`} element={<HomeDoctorKanban />} />
          <Route path={`${routes.VIEW}/*`} element={<SecretaryDoctorView />} />
          <Route
            path={`${routes.ADMINISTRATIVE}/*`}
            element={<PatientRouter />}
          />
          <Route path={`${routes.USERS}/*`} element={<UserRouter />} />
          <Route path={`${routes.PATIENTS}/*`} element={<PatientRouter />} />
          <Route path={`${routes.CENTERS}/*`} element={<SubCenterRouter />} />
          <Route
            path={`${routes.CONSULTATIONS}/*`}
            element={<ConsultationsRouter />}
          />
          <Route
            path={`${outOfNavRoutes.PRESCRIPTION}/*`}
            element={<PrescriptionsRouter />}
          />
          <Route path={`${routes.DIRECTORY}/*`} element={<DirectoryRouter />} />
          <Route
            path={`${routes.DATA_BASE}/*`}
            element={<ManagerDataBaseRouter />}
          />
          <Route
            path={`${routes.LABORATORY}/*`}
            element={<LaboratoryRouter />}
          />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
