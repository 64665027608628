import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin, Form } from 'antd';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import { PageHeaderCustom, ContentCustom } from '../../components';
import DefaultCenter from './DefaultCenter/DefaultCenter';
import Appearance from './DefaultCenter/Appearance';
import { formItemLayout } from '../../utils/constants/formLayout';
import Authorization from './DefaultCenter/PrescriptionAuthorization';
import DoubleAuth from './DefaultCenter/DoubleAuth';

/**
 * React component for managing user settings.
 * This component includes options for updating user profile information, such as default center, appearance, authorization, and double authentication.
 * @component
 * @example
 * // Usage in a parent component:
 * <Settings />
 * @returns {JSX.Element} Returns the JSX element for the user settings component.
 */
export const Settings = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { dispatchAPI, user, setUser } = useAuthContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();

  const fetchUser = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/users/${user?._id}?populate=associated_center,associated_center.sub_centers`
      });
      setUser(data);
      form.setFieldsValue({
        ...data
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const updateProfile = async (body) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('values', JSON.stringify(body));

      await dispatchAPI('PATCH', {
        url: `/users/${user?._id}`,
        body: formData
      });
      await fetchUser();
    } catch (e) {
      if (e.response) message(e.response.status);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    await updateProfile(values);
  };

  useEffect(() => {
    (async () => {
      await fetchUser();
    })();
  }, [fetchUser]);

  return (
    <ContentCustom>
      <PageHeaderCustom title={t('settings.title')} />
      <Spin spinning={isLoading}>
        <DoubleAuth />
        <Form form={form} onValuesChange={handleSubmit} {...formItemLayout}>
          <DefaultCenter />
          <Appearance />
          <Authorization />
        </Form>
      </Spin>
    </ContentCustom>
  );
};
