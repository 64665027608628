import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Modal, Button, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { ConstantsAndOtherSurveyModal } from './ConstantsAndOtherSurveyModal';
import { ConsultationsContext } from '../../ConsultationsContext';
import { ModalChart } from './ModalChart';

export const ConstantsAndOtherSurvey = ({
  resource,
  fields,
  title,
  idPatient
}) => {
  const { patient: { constants_survey } = {}, enums } =
    useContext(ConsultationsContext);
  const { t } = useTranslation();
  const [isModal, setModal] = useState(false);
  const handleClick = () => {
    setModal(true);
  };
  const handleCloseModal = () => {
    setModal(false);
  };
  return (
    <>
      <Row justify="space-between">
        <h4>{t(`consultations.form.${title}`)}</h4>
        <Button icon={<EditOutlined />} onClick={handleClick} />
      </Row>
      <ul>
        {constants_survey?.[title] && constants_survey[title].length > 0
          ? (() => {
              const objToDisplay = [...constants_survey[title]]
                .reverse()
                .find((obj) => Object.keys(obj).length >= 2);
              return objToDisplay
                ? Object.entries(objToDisplay).map(([key, value]) =>
                    key === 'created_at' ? null : (
                      <Row key={key + value} justify="space-between">
                        <li key={key + value} style={{ width: '80%' }}>
                          <Row justify="space-between">
                            <Col span={12}>
                              {`${t(`constants_surveys.form.${key}`)}:`}
                            </Col>
                            <Col span={12}>
                              {value}{' '}
                              {`${
                                enums?.enumsUnits?.find((unit) => unit[key])?.[
                                  key
                                ] || ''
                              }`}
                            </Col>
                          </Row>
                        </li>
                        <ModalChart chartType={key} title={title} />
                      </Row>
                    )
                  )
                : null;
            })()
          : null}
      </ul>

      <Modal
        open={isModal}
        onCancel={() => setModal(false)}
        okText={t('buttons.validate')}
        width="50%"
        footer={null}
      >
        <ConstantsAndOtherSurveyModal
          fields={fields}
          resource={resource}
          title={title}
          handleCloseModal={handleCloseModal}
          idPatient={idPatient}
        />
      </Modal>
    </>
  );
};

ConstantsAndOtherSurvey.propTypes = {
  resource: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string.isRequired,
  idPatient: PropTypes.string.isRequired
};
ConstantsAndOtherSurvey.defaultProps = {
  fields: []
};
