import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { MedicalBackgroundForm } from './MedicalBackgroundForm';
import useFields from '../../fields';

export const TitleMedicalBackground = ({ id }) => {
  const resource = 'consultations';
  const [isModal, setModal] = useState(false);
  const { medicalBackgroundFields } = useFields();

  const { t } = useTranslation();

  const handleClick = () => {
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  return (
    <Row justify="space-between">
      <h1>{t(`${resource}.form.medical_history_breakdown`)}</h1>
      <Button type="Link" icon={<EditOutlined />} onClick={handleClick} />
      <Modal open={isModal} onCancel={handleCloseModal} width="8" footer={null}>
        <MedicalBackgroundForm
          fields={medicalBackgroundFields}
          id={id}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
    </Row>
  );
};

TitleMedicalBackground.propTypes = {
  id: PropTypes.string.isRequired
};
