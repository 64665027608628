import { useTranslation } from 'react-i18next';
import { Card, Input } from 'antd';
import { MedicineBoxFilled } from '@ant-design/icons';

const { Search } = Input;

/**
 * @component
 * @description Component that displays the cares card
 * @returns {JSX.Element} Cares card
 */
// Work in progress
export const CaresCard = () => {
  const { t } = useTranslation();
  const translationKey = 'prescription.cards.cares';

  return (
    <Card
      style={{ width: '100%' }}
      title={
        <div>
          <MedicineBoxFilled style={{ marginRight: '8px' }} />
          {t(`${translationKey}.title`)}
        </div>
      }
    >
      <Search placeholder={t('prescription.cards.placeholder')} />
    </Card>
  );
};
