import { useContext } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ConsultationsContext } from '../ConsultationsContext';

/**
 * A function to make a PATCH request for updating a patient's data in the 'patients' resource.
 *
 * @function
 * @param {string|number} id - The ID of the patient to be updated.
 * @param {Function} handleCloseModal - A callback function to close the modal after updating the patient's data.
 * @returns {{
 *   handleSubmit: Function
 * }}
 * An object containing the handleSubmit function which initiates the PATCH request with given values.
 */

export const patchPatientApi = (id, handleCloseModal) => {
  const resource = 'patients';
  const { setForcedRefresh, forcedRefresh } = useContext(ConsultationsContext);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const updateResource = async (body) => {
    try {
      const {
        consultations: {
          medicalBackground: {
            allergies,
            lifestyle,
            medical_history,
            surgical_history
          }
        }
      } = body;

      const updatedBody = {
        allergies,
        lifestyle,
        medical_history,
        surgical_history
      };

      await dispatchAPI('PATCH', {
        url: `${resource}/consultation/${id}`,
        body: updatedBody
      });
      setForcedRefresh(!forcedRefresh);
    } catch (e) {
      message(e);
    }
  };

  const handleSubmit = async (values) => {
    await updateResource(values);
    handleCloseModal();
  };

  return { handleSubmit };
};
