import { Button, Form, Spin, Typography, message as antdMessage } from 'antd';
import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { formItemLayout } from '../../utils/constants/formLayout';

const { Title, Text } = Typography;
/**
 * LoginForm component for user login.
 *
 * @component
 * @param {Function} switchForm - Function to switch between login and forgot password forms.
 * @returns {JSX.Element} The rendered component.
 */
export const ManagerChoiceForm = ({ switchForm }) => {
  const {
    user,
    dispatchAPI,
    isValid,
    chooseCenter,
    setChooseCenter,
    setSession,
    redirectToAccount,
    setRedirectToAccount,
    token
  } = useAuthContext();
  const location = useLocation();
  const { associated_center, first_name = '' } = user || {};
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const [form] = Form.useForm();

  const { from } = location?.state || { from: { pathname: '/' } };

  const updateProfile = async (body) => {
    try {
      setIsLoading(true);
      await dispatchAPI('PATCH', {
        url: `/users/${user?._id}/choose-center/${token}`,
        body
      });
      setRedirectToAccount(true);
      setChooseCenter(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    await updateProfile(values);
  };

  const onProfileMenuClick = async () => {
    await dispatchAPI('LOGOUT');
    setChooseCenter(false);
    antdMessage.success(t('login.logoutSuccess'));
  };

  useEffect(() => {
    if (!chooseCenter) switchForm('login');
  }, [chooseCenter]);

  useEffect(() => {
    if (redirectToAccount) {
      setSession(token);
    }
  }, [token, redirectToAccount]);

  return (
    <>
      {isValid && !chooseCenter && redirectToAccount && <Navigate to={from} />}
      <Title className="login-title">Bonjour {first_name}</Title>
      <Text>{t('login.choose_center')}</Text>
      <Spin spinning={isLoading}>
        <Form form={form} {...formItemLayout}>
          <Form.Item name="selectedCenter">
            <Button.Group>
              {(associated_center?.sub_centers || [])?.map(
                (sub_center, index, array) => (
                  <Button
                    key={sub_center?._id}
                    onClick={() =>
                      handleSubmit({ selected_sub_center: sub_center._id })
                    }
                    type="primary"
                    htmlType="submit"
                    style={{
                      border: '1px solid #1890ff',
                      borderRadius: 4,
                      padding: '5px 10px',
                      marginRight: index !== array.length - 1 ? 8 : 0
                    }}
                  >
                    {sub_center?.name}
                  </Button>
                )
              )}
            </Button.Group>
          </Form.Item>
        </Form>
      </Spin>
      <Button onClick={onProfileMenuClick}>{t('buttons.back')}</Button>
    </>
  );
};

ManagerChoiceForm.propTypes = {
  switchForm: PropTypes.func.isRequired
};
