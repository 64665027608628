import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { MedicalBackgroundSelect } from './PatientOverview/MedicalBackground/MedicalBackgroundSelect';
import { CardPicture } from '../patients/components/Fields/CardPicture';
import { SelectDiagnostic } from './PatientOverview/MedicalConsultation/SelectDiagnostic';
import { SelectConsultationType } from './Components/Fields/SelectConsultationType';
import { ReasonsSelect } from './Components/Fields/ReasonsSelect';
import { HyperalgesicRadio } from './Components/Fields/HyperalgesicRadio';
import { SelectRoom } from './Components/Fields/SelectRoom';
import { ConsultationsContext } from './ConsultationsContext';

const { TextArea } = Input;

/**
 * Custom hook that returns field configurations for different form sections.
 *
 * This hook provides a structured way of defining field properties for various
 * sections in a form. Each section (e.g., general information, medical background, etc.)
 * gets its own field configuration, allowing for easy assembly of complex forms.
 *
 * @function
 * @returns {{
 *   generalInfosFields: Object[],
 *   medicalBackgroundFields: Object[],
 *   resultsLaboratiesFields: Object[],
 *   identityCardFields: Object[],
 *   insuranceCardFields: Object[],
 *   medicalConsultationFields: Object[],
 *   newConsultationFields: Object[],
 *   photoFields: Object[],
 *   medicalCommentsFields: Object[]
 * }}
 * An object containing arrays of field configurations for different form sections.
 */
const useFields = () => {
  const { t } = useTranslation();
  const placeholder = t(`consultations.form.add_comment`);
  const { patient } = useContext(ConsultationsContext);

  const generalInfosFields = [
    {
      noLabel: true,
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      noLabel: true,
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      noLabel: true,
      name: ['gender']
    },
    {
      noLabel: true,
      name: ['birth_date'],
      rules: [{ required: true }, { type: 'date' }]
    }
  ];

  const medicalBackgroundFields = [
    {
      label: 'surgical_history',
      name: ['surgical_history'],
      input: (
        <MedicalBackgroundSelect
          dbKey="surgical_history"
          defaultValue={patient?.surgical_history}
        />
      )
    },
    {
      label: 'medical_history',
      name: ['medical_history'],
      input: (
        <MedicalBackgroundSelect
          dbKey="medical_history"
          defaultValue={patient?.medical_history}
        />
      )
    },
    {
      label: 'allergies',
      name: ['allergies'],
      input: (
        <MedicalBackgroundSelect
          dbKey="allergies"
          defaultValue={patient?.allergies}
        />
      )
    },
    {
      label: 'lifestyle',
      name: ['lifestyle']
    }
  ];

  const resultsLaboratiesFields = [
    {
      noLabel: true,
      name: ['consultations', 'results_laboratories', 'date'],
      rules: [{ required: true }, { type: 'date' }]
    }
  ];

  const identityCardFields = [
    {
      noLabel: true,
      name: ['consultations', 'card', 'identity_card_picture'],
      rules: [{ type: 'file' }],
      input: <CardPicture />
    }
  ];
  const insuranceCardFields = [
    {
      noLabel: true,
      name: ['consultations', 'card', 'health_mutual_card_picture'],
      rules: [{ type: 'file' }],
      input: <CardPicture />
    }
  ];

  const medicalConsultationFields = [
    {
      Label: 'consultation',
      name: ['consultation'],
      rules: [{ required: true }],
      input: (
        <TextArea
          autoSize={{
            maxRows: 20,
            minRows: 10
          }}
        />
      )
    },
    {
      Label: 'diagnostic',
      name: ['diagnostic'],
      input: <SelectDiagnostic dbKey="diagnostic" />
    },
    {
      Label: 'specialist_consultation',
      name: ['specialist_consultation'],
      input: (
        <TextArea
          placeholder={placeholder}
          rows={2}
          bordered={false}
          style={{ resize: 'none' }}
        />
      )
    }
  ];

  const newConsultationFields = [
    {
      noLabel: true,
      name: ['visit_type'],
      rules: [{ type: String, required: true }],
      input: <SelectConsultationType />
    },
    {
      Label: 'visit_reasons',
      name: ['visit_reasons'],
      rules: [{ required: true }],
      input: <ReasonsSelect />
    },
    {
      Label: 'description',
      name: ['description'],
      rules: [{ type: String }]
    },
    {
      Label: 'hyperalgesic',
      name: ['hyperalgesic'],
      rules: [{ type: Boolean, required: true }],
      input: <HyperalgesicRadio />
    },
    {
      Label: 'waiting_room',
      name: ['waiting_room'],
      rules: [{ type: String, required: true }],
      input: <SelectRoom />
    }
  ];

  const medicalCommentsFields = [
    {
      noLabel: true,
      name: 'written_by'
    },
    {
      label: 'description',
      name: 'description'
    }
  ];

  return {
    generalInfosFields,
    medicalBackgroundFields,
    resultsLaboratiesFields,
    identityCardFields,
    insuranceCardFields,
    medicalConsultationFields,
    newConsultationFields,
    medicalCommentsFields
  };
};

export default useFields;
